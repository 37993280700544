export default class Recipe {
  constructor(data = {}) {
    return {
      id: data.id || "",
      name: data.name || "",
      noofperson: data.noofperson || "",
      materials: data.materials || "",
    };
  }
}
