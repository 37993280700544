import DateFormat from "@/libs/DateFormat";

export default class Event {
  constructor(data = {}) {
    return {
      id: data.id || "",
      customername: data.customername || "",
      eventname: data.eventname || "",
      eventtype: data.eventtype || "",
      status: data.status || "",
      hasRecipe: data.hasRecipe || "",
      items: data.items || [],
      eventdate: DateFormat.formatDateTime(data.eventdate) || "",
      createddate: DateFormat.formatDate(data.createddate) || "",
    };
  }
}
