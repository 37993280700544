import { createWebHistory, createRouter } from "vue-router";
import Store from "@/store/index";
import MainLayout from "@/views/main-layout.vue";

import Login from "@/views/login.vue";
import Dashboard from "@/views/Dashboard/dashboard.vue";
import Customer from "@/views/Customer/customer.vue";
import CustomerCreate from "@/views/Customer/customer-form.vue";
import Recipe from "@/views/Recipe/recipe.vue";
import RecipeCreate from "@/views/Recipe/recipe-form.vue";
import RecipeCopy from "@/views/Recipe/recipe-copy-from.vue";
import Employee from "@/views/Employee/employee.vue";
import EmployeeCreate from "@/views/Employee/employee-form.vue";
import Attendance from "@/views/Attendance/attendance.vue";
import AttendanceCreate from "@/views/Attendance/attendance-form.vue";
import Invoice from "@/views/Invoice/invoice.vue";
import InvoiceCreate from "@/views/Invoice/invoice-form.vue";
import RowMaterial from "@/views/RowMaterial/row.vue";
import RowMaterialCreate from "@/views/RowMaterial/row-form.vue";
import Vendor from "@/views/Vendor/vendor.vue";
import VendorCreate from "@/views/Vendor/vendor-form.vue";
import Company from "@/views/Company/company-list.vue";
import CompanyCreate from "@/views/Company/company-form.vue";
import ProfileSetting from "@/views/ProfileSetting/profile-setting.vue";
import Payment from "@/views/Payment/payment.vue";
import paymentCreate from "@/views/Payment/payment-form.vue";
import User from "@/views/User/user.vue";
import UserCreate from "@/views/User/user-form.vue";
import TemplateSetting from "@/views/TemplateSetting/templateSetting.vue";
import AboutUs from "@/views/AboutUsPage/about-us.vue";
import TermsAndConditions from "@/views/TermsAndConditionsPage/terms-and-conditions.vue";

import Menu from "@/views/Menu/menu.vue";
import MenuCreate from "@/views/Menu/menu-form.vue";
import SubMenu from "@/views/SubMenu/submenu.vue";
import SubMenuCreate from "@/views/SubMenu/submenu-form.vue";
import Eve from "@/views/Eve/eve.vue";
import EveCreate from "@/views/Eve/eve-form.vue";
import EveCopy from "@/views/Eve/eve-copy.vue";
import Cart from "@/views/Cart/cart.vue";
import EventReport from "@/views/Reports/event-report.vue";
import InvoiceReport from "@/views/Reports/invoice-report.vue";

import Category from "@/views/mainCategory/mainCategory.vue";
import CategoryCreate from "@/views/mainCategory/mainCategory-form.vue";

import EventType from "@/views/EventType/eventtype.vue";
import EventTypeCreate from "@/views/EventType/eventtype-from.vue";

import EmployeeAssign from "@/views/EmployeeAssign/employeeassign.vue";
import settingPage from "@/views/setting/setting-page.vue";
const match = process.env.VUE_APP_USERNAME;

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Login",
    },
  },
  {
    component: MainLayout,
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          requiresAuth: true,
          module: "Dashboard",
          title: "Dashboard",
        },
      },
      {
        path: "/customer",
        name: "Customer",
        component: Customer,
        meta: {
          requiresAuth: true,
          module: "Customer",
          title: "Customers",
        },
      },
      {
        path: "/customer/create",
        name: "CustomerCreate",
        component: CustomerCreate,
        meta: {
          requiresAuth: true,
          module: "Customer",
          title: "Customers > Add",
        },
      },
      {
        path: "/customer/edit/:id",
        name: "CustomerEdit",
        component: CustomerCreate,
        meta: {
          requiresAuth: true,
          module: "Customer",
          title: "Customers > Edit",
        },
      },
      {
        path: "/vendor",
        name: "Vendor",
        component: Vendor,
        meta: {
          requiresAuth: true,
          module: "Vendor",
          title: "Vendors",
        },
      },
      {
        path: "/vendor/create",
        name: "VendorCreate",
        component: VendorCreate,
        meta: {
          requiresAuth: true,
          module: "Vendor",
          title: "Vendors > Add",
        },
      },
      {
        path: "/vendor/edit/:id",
        name: "VendorEdit",
        component: VendorCreate,
        meta: {
          requiresAuth: true,
          module: "Vendor",
          title: "Vendors > Edit",
        },
      },
      {
        path: "/main-category",
        name: "Category",
        component: Category,
        meta: {
          requiresAuth: true,
          module: "Category",
          title: "Main Category",
        },
      },
      {
        path: "/main-category/create",
        name: "CategoryCreate",
        component: CategoryCreate,
        meta: {
          requiresAuth: true,
          module: "Category",
          title: "Main Category > Add",
        },
      },
      {
        path: "/main-category/edit/:id",
        name: "CategoryEdit",
        component: CategoryCreate,
        meta: {
          requiresAuth: true,
          module: "Category",
          title: "Main Category > Edit",
        },
      },
      {
        path: "/rawmaterial-ingredient",
        name: "RawMaterialIngredient",
        component: RowMaterial,
        meta: {
          requiresAuth: true,
          module: "RawMaterialIngredient",
          title: "Raw Materials / Ingredients",
        },
      },
      {
        path: "/rawmaterial-ingredient/create",
        name: "RowMaterialCreate",
        component: RowMaterialCreate,
        meta: {
          requiresAuth: true,
          module: "RawMaterialIngredient",
          title: "Raw Materials / Ingredients > Add",
        },
      },
      {
        path: "/rawmaterial-ingredient/edit/:id",
        name: "RowMaterialEdit",
        component: RowMaterialCreate,
        meta: {
          requiresAuth: true,
          module: "RawMaterialIngredient",
          title: "Raw Materials / Ingredients > Edit",
        },
      },
      {
        path: "/recipe-item",
        name: "RecipeItem",
        component: Recipe,
        meta: {
          requiresAuth: true,
          module: "RecipeItem",
          title: "Recipes / Items",
        },
      },
      {
        path: "/recipe-item/create",
        name: "RecipeCreate",
        component: RecipeCreate,
        meta: {
          requiresAuth: true,
          module: "RecipeItem",
          title: "Recipes / Items > Add",
        },
      },
      {
        path: "/recipe-item/edit/:id",
        name: "RecipeEdit",
        component: RecipeCreate,
        meta: {
          requiresAuth: true,
          module: "RecipeItem",
          title: "Recipes / Items > Edit",
        },
      },
      {
        path: "/recipe-item/copy/:id",
        name: "RecipeCopy",
        component: RecipeCopy,
        meta: {
          requiresAuth: true,
          module: "RecipeItem",
          title: "Recipes / Items > Copy",
        },
      },
      {
        path: "/menu",
        name: "Menu",
        component: Menu,
        meta: {
          requiresAuth: true,
          module: "Menu",
          title: "Menu",
        },
      },
      {
        path: "/menu/create",
        name: "MenuCreate",
        component: MenuCreate,
        meta: {
          requiresAuth: true,
          module: "Menu",
          title: "Menu > Add",
        },
      },
      {
        path: "/menu/edit/:id",
        name: "MenuEdit",
        component: MenuCreate,
        meta: {
          requiresAuth: true,
          module: "Menu",
          title: "Menu > Edit",
        },
      },
      {
        path: "/submenu",
        name: "SubMenu",
        component: SubMenu,
        meta: {
          requiresAuth: true,
          module: "SubMenu",
          title: "SubMenu",
        },
      },
      {
        path: "/submenu/crete",
        name: "SubMenuCreate",
        component: SubMenuCreate,
        meta: {
          requiresAuth: true,
          module: "SubMenu",
          title: "SubMenu > Add",
        },
      },
      {
        path: "/submenu/edit/:id",
        name: "SubmenuEdit",
        component: SubMenuCreate,
        meta: {
          requiresAuth: true,
          module: "SubMenu",
          title: "SubMenu > Edit",
        },
      },
      {
        path: "/events",
        name: "Event",
        component: Eve,
        meta: {
          requiresAuth: true,
          module: "Event",
          title: "Events",
        },
      },
      {
        path: "/events/create",
        name: "EveCreate",
        component: EveCreate,
        meta: {
          requiresAuth: true,
          module: "Event",
          title: "Events > Create",
        },
      },
      {
        path: "/events/edit/:id",
        name: "EveEdit",
        component: EveCreate,
        meta: {
          requiresAuth: true,
          module: "Event",
          title: "Events > Edit",
        },
      },
      {
        path: "/events/copy/:id",
        name: "EveCopy",
        component: EveCopy,
        meta: {
          requiresAuth: true,
          module: "Event",
          title: "Events > Copy",
        },
      },
      {
        path: "/template",
        name: "Template",
        component: TemplateSetting,
        meta: {
          requiresAuth: true,
          module: "Template",
          title: "Template",
        },
      },
      {
        path: "/about-us",
        name: "AboutUs",
        component: AboutUs,
        meta: {
          requiresAuth: true,
          module: "AboutUs",
          title: "About Us",
        },
      },
      {
        path: "/terms-conditions",
        name: "TermsAndConditions",
        component: TermsAndConditions,
        meta: {
          requiresAuth: true,
          module: "TermsAndConditions",
          title: "Terms and conditions",
        },
      },
      {
        path: "/employee",
        name: "Employee",
        component: Employee,
        meta: {
          requiresAuth: true,
          module: "Employee",
          title: "Employee",
        },
      },
      {
        path: "/employee/create",
        name: "EmployeeCreate",
        component: EmployeeCreate,
        meta: {
          requiresAuth: true,
          module: "Employee",
          title: "Employee > Add",
        },
      },
      {
        path: "/employee/edit/:id",
        name: "EmployeeEdit",
        component: EmployeeCreate,
        meta: {
          requiresAuth: true,
          module: "Employee",
          title: "Employee > Edit",
        },
      },
      {
        path: "/employeeassign/:id",
        name: "EmployeeAssign",
        component: EmployeeAssign,
        meta: {
          requiresAuth: true,
          module: "Event",
          title: "Employee > Assign",
        },
      },
      {
        path: "/setting",
        name: "Setting",
        component: settingPage,
        meta: {
          requiresAuth: true,
          module: "Setting",
          title: "Setting",
        },
      },
      {
        path: "/attendance",
        name: "Attendance",
        component: Attendance,
        meta: {
          requiresAuth: true,
          module: "Attendance",
          title: "Attendance",
        },
      },
      {
        path: "/attendance/create",
        name: "AttendanceCreate",
        component: AttendanceCreate,
        meta: {
          requiresAuth: true,
          module: "Attendance",
          title: "Attendance > Add",
        },
      },
      {
        path: "/invoice",
        name: "Invoice",
        component: Invoice,
        meta: {
          requiresAuth: true,
          module: "Invoice",
          title: "Invoice",
        },
      },
      {
        path: "/invoice/create",
        name: "InvoiceCreate",
        component: InvoiceCreate,
        meta: {
          requiresAuth: true,
          module: "Invoice",
          title: "Invoice > Add",
        },
      },
      {
        path: "/invoice/edit/:id",
        name: "InvoiceEdit",
        component: InvoiceCreate,
        meta: {
          requiresAuth: true,
          module: "Invoice",
          title: "Invoice > Edit",
        },
      },
      {
        path: "/payment",
        name: "Payment",
        component: Payment,
        meta: {
          requiresAuth: true,
          module: "Payment",
          title: "Payment",
        },
      },
      {
        path: "/payment/create",
        name: "PaymentCreate",
        component: paymentCreate,
        meta: {
          requiresAuth: true,
          module: "Payment",
          title: "Payment > Add",
        },
      },
      {
        path: "/payment/edit/:id",
        name: "PaymentEdit",
        component: paymentCreate,
        meta: {
          requiresAuth: true,
          module: "Payment",
          title: "Payment > Edit",
        },
      },
      {
        path: "/user",
        name: "User",
        component: User,
        meta: {
          requiresAuth: true,
          module: "User",
          title: "User",
        },
      },
      {
        path: "/user/create",
        name: "UserCreate",
        component: UserCreate,
        meta: {
          requiresAuth: true,
          module: "User",
          title: "User > Add",
        },
      },
      {
        path: "/user/edit/:id",
        name: "UserEdit",
        component: UserCreate,
        meta: {
          requiresAuth: true,
          module: "User",
          title: "User > Edit",
        },
      },
      {
        path: "/company",
        name: "Company",
        component: Company,
        meta: {
          requiresAuth: true,
          module: "Company",
          title: "Company",
        },
      },
      {
        path: "/company/create",
        name: "CompanyCreate",
        component: CompanyCreate,
        meta: {
          requiresAuth: true,
          module: "Company",
          title: "Company > Add",
        },
      },
      {
        path: "/company/edit/:id",
        name: "CompanyEdit",
        component: CompanyCreate,
        meta: {
          requiresAuth: true,
          module: "Company",
          title: "Company > Edit",
        },
      },
      {
        path: "/eventtype",
        name: "EventType",
        component: EventType,
        meta: {
          requiresAuth: true,
          module: "EventType",
          title: "Event Type",
        },
      },
      {
        path: "/eventtype/create",
        name: "EventTypeCreate",
        component: EventTypeCreate,
        meta: {
          requiresAuth: true,
          module: "EventType",
          title: "Event Type > Add",
        },
      },
      {
        path: "/eventtype/edit/:id",
        name: "EventTypeEdit",
        component: EventTypeCreate,
        meta: {
          requiresAuth: true,
          module: "EventType",
          title: "Event Type > Edit",
        },
      },
      {
        path: "/cart/:id",
        name: "Cart",
        component: Cart,
        meta: {
          requiresAuth: true,
          module: "Cart",
          title: "Cart",
        },
      },
      {
        path: "/profile-setting",
        name: "ProfileSetting",
        component: ProfileSetting,
        meta: {
          requiresAuth: true,
          module: "ProfileSetting",
          title: "Profile Setting",
        },
      },
      {
        path: "/events-report",
        name: "EventReport",
        component: EventReport,
        meta: {
          requiresAuth: true,
          module: "EventReport",
          title: "Events Report",
        },
      },
      {
        path: "/invoice-report",
        name: "InvoiceReport",
        component: InvoiceReport,
        meta: {
          requiresAuth: true,
          module: "InvoiceReport",
          title: "Invoice Report",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
// router.beforeEach((to) => {
//   Store.commit("setActiveRoute", to.meta.module);
// });
router.beforeEach((to, from, next) => {
  const title = "Catering";
  if (
    Store.state.timeout == null ||
    (Store.state.timeout != null && Store.state.timeout > new Date())
  ) {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (Store.state.hasLoggedIn) {
        if (
          (to.meta.module == "Company" &&
            match == Store.state.userInfo.email) ||
          (to.meta.module == "EventType" && match == Store.state.userInfo.email)
        ) {
          Store.commit("setUserTimeout");
          Store.commit("setActiveRoute", to.meta.module);
          window.document.title =
            to.meta && to.meta.title ? title + " - " + to.meta.title : title;
          next();
          return;
        } else if (
          (to.meta.module == "Company" &&
            match !== Store.state.userInfo.email) ||
          (to.meta.module == "EventType" &&
            match !== Store.state.userInfo.email)
        ) {
          router.go(-1);
          return;
        } else if (
          (to.meta.module !== "Company" &&
            match == Store.state.userInfo.email) ||
          (to.meta.module == "EventType" &&
            match !== Store.state.userInfo.email)
        ) {
          router.go(-1);
          return;
        } else {
          Store.commit("setUserTimeout");
          Store.commit("setActiveRoute", to.meta.module);
          window.document.title =
            to.meta && to.meta.title ? title + " - " + to.meta.title : title;
          next();
          return;
        }
      }
      Store.dispatch("logout");
      next("/login");
      return;
    } else if (to.name === undefined) {
      if (Store.state.hasLoggedIn) {
        Store.commit("setActiveRoute", to.meta.module);
        window.document.title =
          to.meta && to.meta.title ? title + " - " + to.meta.title : title;
        next();
        return;
      }
      Store.dispatch("logout");
      next("/login");
      return;
    } else {
      Store.commit("setUserTimeout");
      if (to.name == "Login" && Store.state.hasLoggedIn) {
        if (match == Store.state.userInfo.email) {
          Store.commit("setActiveRoute", "Dashboard");
          next("/company");
          return;
        } else {
          Store.commit("setActiveRoute", "Dashboard");
          next("/dashboard");
          return;
        }
      } else {
        window.document.title =
          to.meta && to.meta.title ? title + " - " + to.meta.title : title;
        next();
        return;
      }
    }
  } else {
    Store.dispatch("logout");
    next("/login");
    return;
  }
});
export default router;
