import DateFormat from "@/libs/DateFormat";

export default class Vendor {
  constructor(data = {}) {
    return {
      id: data.id || "",
      name: data.name || "",
      contactno: data.contactno || "",
      createddate: DateFormat.formatDateTime(data.createddate) || "",
    };
  }
}
