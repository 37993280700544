<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="list-margin">
    <DataTable
      :value="users"
      class="p-datatable-lg p-datatable-customers"
      dataKey="id"
      :rowHover="true"
      :resizableColumns="true"
      @sort="onSort($event)"
    >
      <template #header>
        <div class="grid m-0">
          <div class="lg:col-6 col-12 lg:text-left text-center list-header">
            Users
          </div>
          <div class="lg:col-6 col-12 lg:text-right text-center">
            <PrimeButton
              label=" ADD "
              class="add-button mr-3"
              icon="plus-icon"
              @click="gotoCreateUser"
            ></PrimeButton>
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch" />
              <InputText
                v-model="search"
                placeholder="Search"
                class="search-input"
              />
            </div>
            <PrimeButton
              class="download-button ml-3"
              icon="pi pi-download"
              @click="downloadCSV"
              :disabled="true"
            ></PrimeButton>
          </div>
        </div>
      </template>

      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <Column field="companyname" header="Name" sortable></Column>
      <Column field="contactno" header="contact number" sortable> </Column>
      <Column field="isactive" header="Status" class="status-column">
        <template #body="data">
          <div class="flex justify-content-center">
            <PrimeButton
              label="Inactive"
              class="add-button mr-3"
              @click="updateStatus(data.data.id, false)"
              v-if="data.data.isactive"
            ></PrimeButton>
            <PrimeButton
              label="Active"
              class="add-button mr-3"
              @click="updateStatus(data.data.id, true)"
              v-else
            ></PrimeButton>
          </div>
        </template>
      </Column>
      <Column field="actions" header="Actions">
        <template #body="data">
          <div class="flex">
            <i
              class="pi pi-pencil cursor-pointer edit-icon"
              style="color: gray"
              @click="gotoUserEdit(data.data.id)"
            ></i>
            <i
              class="pi pi-trash cursor-pointer delete-icon ml-1"
              style="color: #dc3545"
              @click="showDeletePopup(data.data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <Paginator
      v-model:first="currentPage"
      :rows="50"
      :totalRecords="totalRecords"
      :rowsPerPageOptions="[50, 100, 150, 200]"
      template="RowsPerPageDropdown CurrentPageReport PageLinks"
      currentPageReportTemplate="{first} - {last} of {totalRecords} items"
      @page="onPage($event)"
      :class="{
        loading: loading,
      }"
    >
      <template #start> Shown per page : </template>
    </Paginator>
    <ConfirmPopup
      :show-popup="showPopup"
      @confirmYes="deleteUser"
      @confirmNo="showPopup = false"
    ></ConfirmPopup>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import UserFactory from "@/factories/user";
export default {
  data() {
    return {
      loading: false,
      users: [],
      currentPage: 0,
      itemsPerPage: 50,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      showPopup: false,
      userIdForDelete: "",
    };
  },
  mounted() {
    this.getUserList();
  },
  methods: {
    downloadCSV() {
      console.log("this is downloadcsv");
    },
    gotoCreateUser() {
      this.$router.push({ name: "UserCreate" });
    },
    gotoUserEdit(userId) {
      this.$router.push({
        name: "UserEdit",
        params: { id: userId },
      });
    },
    showDeletePopup(userId) {
      this.userIdForDelete = userId;
      this.showPopup = true;
    },
    updateStatus(userId, status) {
      this.$root.$globalState.loader = true;
      let payload = {
        isactive: status,
      };
      APIService.put("/user", payload, userId)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "User" });
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.getUserList();
          this.$root.$globalState.loader = false;
        });
    },
    getUserList() {
      this.$root.$globalState.loader = true;
      this.users = [];
      APIService.get(
        `/user/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${this.search}`
      )
        .then((response) => {
          if (response && response.data) {
            this.totalRecords = response.data.totalrecord;
            this.users = UserFactory.createFromJsonArray(response.data.data);
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    deleteUser() {
      this.$root.$globalState.loader = true;
      APIService.delete(`/user/delete/${this.userIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getUserList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.showPopup = false;
        });
    },
    onPage(pageData) {
      this.itemsPerPage = pageData.rows;
      this.currentPage = pageData.first;
      this.getUserList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getUserList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getUserList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
:deep(
    .p-datatable.p-datatable-customers .p-datatable-thead tr th.status-column
  ) {
  width: 80px;
}
</style>
