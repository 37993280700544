<template>
  <div class="ml-5 mr-5 set-margin-top mb-5">
    <div class="grid">
      <div class="grid">
        <div class="order-form-title w-full">Employee Management</div>
        <div class="order-form-sub-title">{{ subtitle }}</div>
      </div>
      <div class="order-card w-full">
        <div class="grid align-items-center">
          <div class="sm:col-1 col-3">
            <label for="span" class="form-label">Avatar</label>
            <span id="span">
              <img
                v-if="emppic == ''"
                src="../../assets/icons/avatar.png"
                alt=""
                class="avatar-img mt-1"
              />
              <img v-else :src="vmodel.emppic" alt="" class="avatar-img mt-1" />
            </span>
          </div>
          <FileUpload
            ref="photo"
            name="photo"
            accept=".png, .jpg, .jpeg"
            @select="handleFileUpload"
            v-show="false"
            :aria-describedby="`photo-help`"
          />
          <div class="md:col-3 col-12 md:ml-5 flex">
            <PrimeButton
              label="Upload"
              class="upload-button"
              @click="openFileUpload"
            />
            <PrimeButton
              v-if="emppic !== ''"
              label="Remove"
              class="remove-button ml-3"
              @click="onRemove"
            />
          </div>
        </div>
        <!-- </div> -->
        <div class="grid">
          <div class="md:col-6 col-12">
            <InputTextRequire
              type="text"
              v-model="vmodel.firstname"
              :validation="v$.vmodel.firstname"
              class="w-full"
              id="firstname"
              label="First Name"
              placeholder="Enter First Name"
              maxlength="30"
            />
          </div>
          <div class="md:col-6 col-12">
            <InputTextRequire
              type="text"
              v-model="vmodel.lastname"
              :validation="v$.vmodel.lastname"
              id="lastname"
              class="w-full"
              label="Last Name"
              placeholder="Enter Last Name"
              maxlength="30"
            />
          </div>
        </div>
        <div class="grid">
          <div class="md:col-6 col-12">
            <InputDate
              v-model="vmodel.birthdate"
              :validation="v$.vmodel.birthdate"
              :manualInput="false"
              class="w-full"
              id="birthdate"
              label="Birthdate"
              :maxDate="birthdayMaxDate"
              placeholder="YYYY-MM-DD"
            />
          </div>
          <div class="md:col-6 col-12">
            <InputDate
              v-model="vmodel.dateofjoining"
              :validation="v$.vmodel.dateofjoining"
              :manualInput="false"
              id="dateofjoining"
              label="Date of Joining"
              class="w-full"
              placeholder="YYYY-MM-DD"
            />
          </div>
        </div>
        <div class="grid">
          <div class="md:col-6 col-12">
            <DropDownRequire
              v-model="vmodel.role"
              :validation="v$.vmodel.role"
              :options="roleOptions"
              :filter="true"
              id="role"
              optionLabel="name"
              optionValue="id"
              label="Role"
              placeholder="Select a Role"
              class="w-full mt-3"
            />
          </div>
        </div>
        <div class="grid mx-0 mt-4">
          <div class="col-12 flex set-button">
            <PrimeButton
              label="Add"
              class="order-form-button"
              v-if="!isEditMode"
              @click="createMember"
            >
            </PrimeButton>
            <PrimeButton
              label="Edit"
              class="order-form-button"
              v-else
              @click="createMember"
            ></PrimeButton>
            <PrimeButton
              label="Cancel"
              class="form-cancel-button sm:ml-2"
              @click="onCancel"
            ></PrimeButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
import APIService from "@/services/api-service.js";
import DateFormat from "@/libs/DateFormat";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      photo: "",
      submitted: false,
      isEditMode: false,
      subtitle: "Add Employee",
      emppic: "",
      birthdayMaxDate: new Date(),
      roleOptions: [],
      vmodel: {
        firstname: "",
        lastname: "",
        birthdate: "",
        dateofjoining: "",
        role: "",
        emppic: "",
      },
    };
  },
  validations() {
    return {
      vmodel: {
        firstname: { required, max: maxLength(30) },
        lastname: { required, max: maxLength(30) },
        birthdate: { required },
        dateofjoining: { required },
        role: { required },
      },
    };
  },
  created() {
    let today = new Date();
    this.birthdayMaxDate.setDate(today.getDate() - 1);
  },
  mounted() {
    if (this.$route.params.id) {
      this.subtitle = "Edit Employee";
      this.isEditMode = true;
      this.getEmployeeById();
    } else {
      this.vmodel.userid = Math.floor(
        10000000 + Math.random() * 90000000
      ).toString();
      this.$root.$globalState.loader = false;
    }
    this.getRole();
  },
  methods: {
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    onlyAlphaNumeric($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode < 65 || keyCode > 90) &&
        (keyCode < 97 || keyCode > 122)
      ) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    onRemove() {
      this.emppic = "";
      this.vmodel.emppic = "";
    },
    openFileUpload() {
      this.$refs.photo.choose();
    },
    getRole() {
      this.$root.$globalState.loader = true;
      APIService.get(`/role/dropdown`)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.roleOptions = response.data.data;
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    async createMember() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        this.$root.$globalState.loader = true;
        let payload = {
          pic: this.emppic,
          firstname: this.vmodel.firstname,
          lastname: this.vmodel.lastname,
          dob: this.vmodel.birthdate,
          doj: this.vmodel.dateofjoining,
          role: this.vmodel.role,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put(`/employee`, payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Employee" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
              this.$root.$globalState.loader = false;
            });
        } else {
          APIService.post(`/employee`, payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Employee" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
              this.$root.$globalState.loader = false;
            });
        }
      }
    },
    onCancel() {
      this.$router.push({ name: "Employee" });
    },
    async getEmployeeById() {
      this.$root.$globalState.loader = true;
      APIService.getById("/employee", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.firstname = response.data.data.firstname;
            this.vmodel.lastname = response.data.data.lastname;
            this.vmodel.birthdate = DateFormat.formatDate(
              response.data.data.dob
            );
            this.vmodel.dateofjoining = DateFormat.formatDate(
              response.data.data.doj
            );
            this.vmodel.role = response.data.data.role;
            this.vmodel.emppic = response.data.data.picpath;
            this.emppic = response.data.data.pic;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "Employee" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
          this.$root.$globalState.loader = false;
        });
    },
    handleFileUpload(fileObj) {
      var file = this.$refs.photo.files[0];
      // const file = event.target.files[0];
      if (
        !fileObj ||
        (fileObj && !fileObj.files) ||
        (fileObj && fileObj.files && fileObj.files.length === 0)
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select valid avatar",
          life: 3000,
        });
        this.$refs.photo.clear();
        // this.$refs.file.value = null;
      } else if (fileObj && fileObj.files && fileObj.files[0].size > 1000000) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Avatar size should be less then 1 MB",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.vmodel.emppic = reader.result;
          this.emppic = reader.result;
        };
        this.$refs.photo.clear();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar-img {
  border: 1px solid #d4d7d8;
  border-radius: 80px;
}

::v-deep(.disable-text-field) {
  .p-inputtext {
    background: #e0e1e2;
    border: 1px solid #d4d7d8;
  }
}

::v-deep(.change-label) {
  .p-button-label {
    margin-left: -5px;
  }
}
.avatar-img {
  border: 1px solid #d4d7d8;
  border-radius: 80px;
  height: 80px;
  width: 80px;
}
</style>
