<template>
  <div :class="sectionClass">
    <label :for="id" :class="labelClass">{{ label }}</label>
    <!-- <div class="p-inputgroup"> -->
    <InputText
      :id="id"
      :type="type"
      :placeholder="placeholder"
      :value="modelValue"
      :class="{
        'p-invalid':
          (validated && customfield && validation) ||
          (validation && validation.$error),
      }"
      :aria-describedby="`${id}-help`"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <!-- </div> -->
    <span
      v-if="
        (validated && customfield && validation) ||
        (validation &&
          validation.$error &&
          validation.required.$invalid &&
          validation.required)
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{
        validated && customfield && validation
          ? validation.$message.replace("Value", this.label)
          : validation.required.$message.replace("Value", this.label)
      }}
    </span>
    <!--
    <span
      v-else-if="
        (validated && customfield && validation) ||
        (validation &&
          validation.$error &&
          validation.minValue.$invalid &&
          validation.minValue)
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.minValue.$message }}
    </span>
    <span
      v-else-if="
        (validated && customfield && validation) ||
        (validation &&
          validation.$error &&
          validation.maxValue.$invalid &&
          validation.maxValue)
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.maxValue.$message }}
    </span> -->
  </div>
</template>

<script>
export default {
  props: {
    labelClass: {
      type: String,
      default: "form-label",
    },
    sectionClass: {
      type: String,
      default: "field",
    },
    id: {
      type: String,
      required: true,
      default: "",
    },
    label: {
      type: String,
      required: true,
      default: "",
    },
    validation: {
      type: Object,
      required: false,
    },
    minlength: {
      type: String,
      default: "",
    },
    maxlength: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    groupLabel: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    customfield: {
      type: Boolean,
      default: false,
    },
    validated: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
