<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="dashboard-margin">
    <div class="main-card w-full">
      <div class="col-12">
        <div class="grid" style="margin-top: 82px">
          <!-- <cardWidget></cardWidget> -->
        </div>
        <div class="grid" style="margin-bottom: 25px">
          <infoWidget
            :numofcustomer="count.numofcustomer"
            :numofevent="count.numofevent"
            :numofrecipe="count.numofrecipe"
            :revenue="count.revenue"
          ></infoWidget>
        </div>
        <div class="grid">
          <div class="lg:col-7 col-12" style="margin: 0 0 25px">
            <calenderWidget
              @loaddata="loadingdata()"
              @notloaddata="notloading()"
            ></calenderWidget>
          </div>
          <div class="lg:col-5 col-12" style="margin: 0 0 25px">
            <productListWidget
              :remainingPayment="count.remainingPayment"
            ></productListWidget>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import infoWidget from "@/views/Dashboard/components/info-widget.vue";
import calenderWidget from "@/views/Dashboard/components/calander-widget.vue";
import productListWidget from "@/views/Dashboard/components/productlist-widget.vue";
import APIService from "@/services/api-service.js";
export default {
  components: {
    infoWidget,
    calenderWidget,
    productListWidget,
  },
  data() {
    return {
      count: {
        numofcustomer: 0,
        numofrecipe: 0,
        numofevent: 0,
        revenue: 0,
        remainingPayment: 0,
      },
    };
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    this.getCount();
  },
  methods: {
    loadingdata() {
      this.$root.$globalState.loader = true;
    },
    notloading() {
      this.$root.$globalState.loader = false;
    },
    getCount() {
      this.$root.$globalState.loader = true;
      APIService.get("/company/dashboardcount")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.count.numofcustomer = response.data.data.numofcustomer;
            this.count.numofevent = response.data.data.numofevent;
            this.count.numofrecipe = response.data.data.numofrecipe;
            this.count.revenue = response.data.data.revenue;
            this.count.remainingPayment = response.data.data.remainingPayment;
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep(.calendar-card.p-card .p-card-body) {
  display: flex;
  flex-direction: column;
  height: 100% !important;
}
::v-deep(.calendar-card.p-card .p-card-content) {
  display: flex;
  flex-direction: column;
  height: 100% !important;
}
</style>
