<template>
  <div class="lg:col-3 sm:col-6 col-12">
    <PrimeCard class="dashboardcard customer-card" @click="customer">
      <template #content>
        <div class="flex justify-content-between">
          <div class="flex flex-column">
            <span class="card-title1">{{ numofcustomer }}</span>
            <span class="card-title2">Customers</span>
          </div>
          <span
            class="flex align-items-center justify-content-center"
            style="margin-left: 20px"
          >
            <i class="pi pi-user card-img"></i>
            <!-- <i class="bi-person card-img"></i? -->
          </span>
        </div>
      </template>
    </PrimeCard>
  </div>
  <div class="lg:col-3 sm:col-6 col-12" @click="recipe">
    <PrimeCard class="dashboardcard supplier-card">
      <template #content>
        <div class="flex justify-content-between">
          <div class="flex flex-column">
            <span class="card-title1">{{ numofrecipe }}</span>
            <span class="card-title2">Recipes / Items</span>
          </div>
          <span
            class="card-img flex align-items-center justify-content-center"
            style="margin-left: 20px"
          >
            <i class="pi pi-id-card card-img"></i>
          </span>
        </div>
      </template>
    </PrimeCard>
  </div>
  <div class="lg:col-3 sm:col-6 col-12" @click="event">
    <PrimeCard class="dashboardcard purchase-card">
      <template #content>
        <div class="flex justify-content-between">
          <div class="flex flex-column">
            <span class="card-title1">{{ numofevent }}</span>
            <span class="card-title2">Events</span>
          </div>
          <span
            class="flex align-items-center justify-content-center"
            style="margin-left: 20px"
          >
            <i class="pi pi-user-plus card-img"></i>
          </span>
        </div>
      </template>
    </PrimeCard>
  </div>
  <div class="lg:col-3 sm:col-6 col-12" @click="invoice">
    <PrimeCard class="dashboardcard sales-card">
      <template #content>
        <div class="flex justify-content-between">
          <div class="flex flex-column">
            <span class="card-title1">Rs. {{ revenue.toFixed(2) }}</span>
            <span class="card-title2">Total Revenue</span>
          </div>
          <span
            class="card-img flex align-items-center justify-content-center"
            style="margin-left: 20px"
          >
            <i class="pi pi-money-bill card-img"></i>
          </span>
        </div>
      </template>
    </PrimeCard>
  </div>
</template>

<script>
export default {
  props: {
    numofcustomer: {
      type: Number,
      required: true,
      default: 0,
    },
    numofrecipe: {
      type: Number,
      required: true,
      default: 0,
    },
    numofevent: {
      type: Number,
      required: true,
      default: 0,
    },
    revenue: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  methods: {
    customer() {
      this.$router.push({ name: "Customer" });
    },
    invoice() {
      this.$router.push({ name: "Invoice" });
    },
    recipe() {
      this.$router.push({ name: "RecipeItem" });
    },
    event() {
      this.$router.push({ name: "Event" });
    },
  },
};
</script>

<style lang="scss" scoped>
.p-card-header img {
  width: 60px;
  height: 60px;
}
::v-deep(.p-card .p-card-body) {
  padding: 20px;
}
::v-deep(.p-card .p-card-content) {
  padding: 0;
}
.card-img {
  font-size: 35px;
  color: #ffffff;
}
.card-title1 {
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 5px;
}
.card-title2 {
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
}
.dashboardcard i {
  transition: all 0.5s ease;
  transform: scale(1);
}
.dashboardcard:hover i {
  transform: scale(1.25);
}
</style>
