<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="list-margin">
    <DataTable
      :value="payment"
      class="p-datatable-lg p-datatable-customers"
      dataKey="id"
      :rowHover="true"
      :resizableColumns="false"
      @sort="onSort($event)"
    >
      <template #header>
        <div class="grid m-0">
          <div class="col-12 lg:col-4 lg:text-left text-center list-header">
            Payments
          </div>
          <div class="col-12 lg:col-8 lg:text-right text-center">
            <PrimeButton
              label=" ADD "
              class="add-button lg:col-2"
              icon="plus-icon"
              @click="gotoCreatePayment"
            ></PrimeButton>
            <Dropdown
              v-model="status"
              :options="statusoption"
              optionLabel="name"
              placeholder="Select a status"
              optionValue="name"
              label="Status"
              class="statusdropdown lg:col-2"
              @change="getData"
            />
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch" />
              <InputText
                v-model="search"
                placeholder="Search"
                class="search-input"
              />
            </div>
            <PrimeButton
              class="download-button ml-3"
              icon="pi pi-download"
              @click="downloadCSV"
              :disabled="payment.length == 0"
            ></PrimeButton>
          </div>
        </div>
      </template>

      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <Column
        field="paymentid"
        header="Payment Receipt Number"
        sortable
        class="setdatewidth"
      ></Column>
      <Column
        field="invoiceid"
        header="Invoice Number"
        sortable
        class="setdatewidth"
      ></Column>
      <Column
        field="paymentdate"
        header="Payment Date"
        sortable
        class="setdatewidth"
      ></Column>
      <Column field="paymenttype" header="Payment Type" sortable>
        <template #body="data">
          <Chip
            v-if="data.data.paymenttype == 'Cash'"
            :label="data.data.paymenttype"
            class="chip-style-for-order-confirm"
          />
          <Chip
            v-if="data.data.paymenttype == 'Online'"
            :label="data.data.paymenttype"
            class="chip-style-for-order-inquiry"
          />
        </template>
      </Column>
      <Column field="eventname" header="Event Name" sortable>
        <template #body="row">
          <div class="flex">
            <span class="margin-username">{{ row.data.eventname }}</span>
          </div>
        </template>
      </Column>
      <Column field="referenceno" header="Reference Number" sortable></Column>
      <Column field="receiveby" header="Receive By" sortable></Column>
      <Column field="amount" header="Amount" sortable></Column>
      <Column field="actions" header="Actions">
        <template #body="data">
          <div class="flex">
            <i
              class="pi pi-eye cursor-pointer edit-icon"
              style="color: gray"
              @click="gotoPaymentEdit(data.data.id)"
            ></i>
            <i
              class="pi pi-trash cursor-pointer delete-icon ml-1"
              style="color: #dc3545"
              @click="showDeletePopup(data.data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <Paginator
      v-model:first="currentPage"
      :rows="50"
      :totalRecords="totalRecords"
      :rowsPerPageOptions="[50, 100, 150, 200]"
      template="RowsPerPageDropdown CurrentPageReport PageLinks"
      currentPageReportTemplate="{first} - {last} of {totalRecords} items"
      @page="onPage($event)"
      :class="{
        loading: loading,
      }"
    >
      <template #start> Shown per page : </template>
    </Paginator>
    <ConfirmPopup
      :show-popup="showPopup"
      :message="message"
      :header="header"
      @confirmYes="deletePayment"
      @confirmNo="showPopup = false"
    ></ConfirmPopup>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import PaymentFactory from "@/factories/payment";
export default {
  data() {
    return {
      loading: false,
      statusoption: [{ name: "All" }, { name: "Cash" }, { name: "Online" }],
      status: "All",
      payment: [],
      currentPage: 0,
      itemsPerPage: 50,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      showPopup: false,
      paymentIdForDelete: "",
      message: "Are you sure you want to delete all details from this payment?",
      header: "Delete Confirmation",
    };
  },
  mounted() {
    this.getPaymentList();
  },
  methods: {
    downloadCSV() {
      this.$root.$globalState.loader = true;
      APIService.get(`/payment/downloadcsv`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data.file);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    gotoCreatePayment() {
      this.$router.push({ name: "PaymentCreate" });
    },
    gotoPaymentEdit(paymentId) {
      this.$router.push({
        name: "PaymentEdit",
        params: { id: paymentId },
      });
    },
    showDeletePopup(paymentId) {
      this.paymentIdForDelete = paymentId;
      this.showPopup = true;
    },
    getPaymentList() {
      this.$root.$globalState.loader = true;
      this.payment = [];
      APIService.get(
        `/payment/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${this.status}/${this.search}`
      )
        .then((response) => {
          if (response && response.data) {
            this.totalRecords = response.data.totalrecord;
            this.payment = PaymentFactory.createFromJsonArray(
              response.data.data
            );
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    deletePayment() {
      this.$root.$globalState.loader = true;
      APIService.delete(`/payment/delete/${this.paymentIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getPaymentList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.showPopup = false;
        });
    },
    onPage(pageData) {
      this.itemsPerPage = pageData.rows;
      this.currentPage = pageData.first;
      this.getPaymentList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getPaymentList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getPaymentList();
    },
    getData() {
      this.currentPage = 0;
      this.getPaymentList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
@media (min-width: 991.98px) AND (max-width: 1315.98px) {
  .template-view.expand .p-input-icon-right {
    margin-top: 12px;
  }
}
@media (min-width: 991.98px) AND (max-width: 1035.98px) {
  .template-view.not-expand .p-input-icon-right {
    margin-top: 12px;
  }
}
// @media (max-width: 445.98px) {
//   .add-button {
//     margin-right: 0 !important;
//   }
// }
@media (max-width: 991.98px) {
  .add-button {
    margin-right: 12px;
  }
}
@media (max-width: 413.98px) {
  .btn-filter {
    margin-top: 12px;
  }
}
@media (max-width: 363.98px) {
  .btn-filter {
    margin-top: 12px;
    margin-right: 0 !important;
  }
  :deep(.statusdropdown.p-dropdown) {
    margin-top: 12px;
    margin-right: 12px !important;
  }
}
@media (max-width: 495.98px) {
  ::v-deep(.p-datatable.p-datatable-customers .add-button) {
    margin-bottom: 0px;
  }
}
@media (max-width: 626.98px) {
  .p-input-icon-right {
    margin-top: 12px;
  }
}
.btn-filter {
  margin-right: 12px;
  width: 120px;
  justify-content: center;
  align-items: center;
  padding: 12px 24px 12px 16px;
  height: 48px;
  background: #ff9f43;
  border-radius: 8px;
  border-color: #ff9f43;

  .btn-filter-label {
    height: 24px;

    font-family: OpenSans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    letter-spacing: -0.02em;
    color: #ffffff;
    flex: none;
  }
}
.btn-filter:hover {
  background: #ff9f43 !important;
  border-radius: 8px;
  border-color: #ff9f43 !important;
}
:deep(.statusdropdown.p-dropdown) {
  margin-right: 12px;
  width: 120px;
}
.add-button {
  width: 120px;
  margin-right: 1rem;
}
.margin-username {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
:deep(
    .p-datatable.p-datatable-customers .p-datatable-thead tr th.setdatewidth
  ) {
  min-width: 200px;
}
</style>
