import InvoiceReport from "@/models/invoiceReport";

export default class InvoiceReportFactory {
  static createFromJson(json) {
    return new InvoiceReport(json);
  }
  static createFromJsonArray(jsonArray) {
    const jsonData = [];
    jsonArray.forEach((item) => {
      jsonData.push(InvoiceReportFactory.createFromJson(item));
    });

    return jsonData;
  }
}
