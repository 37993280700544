export default class User {
  constructor(data = {}) {
    return {
      id: data.id || "",
      companyname: data.companyname || "",
      email: data.email || "",
      contactno: data.contactno || "",
      isactive: data.isactive,
    };
  }
}
