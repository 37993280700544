import Invoice from '@/models/invoice'

export default class InvoiceFactory {
    static createFromJson(json) {
        return new Invoice(json)
    }
    static createFromJsonArray(jsonArray) {
        const jsonData = []
        jsonArray.forEach(item => {
            jsonData.push(InvoiceFactory.createFromJson(item))
        })

        return jsonData
    }
}
