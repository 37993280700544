<template>
  <div class="ml-5 mr-5 set-margin-top mb-5">
    <div class="grid">
      <div class="grid">
        <div class="order-form-title w-full">
          Employee Attendance Management
        </div>
        <div class="order-form-sub-title">{{ subtitle }}</div>
      </div>
      <div class="order-card w-full">
        <div class="grid">
          <div class="md:col-6 col-12">
            <InputTextRequire
              type="text"
              v-model="value"
              class="w-full"
              label="Employee Name"
              placeholder="Enter Employee Name"
            />
          </div>
          <div class="md:col-6 col-12">
            <InputTextRequire
              type="text"
              v-model="value"
              class="w-full"
              label="Program Name"
              placeholder="Enter Program Name"
            />
          </div>
        </div>

        <div class="grid">
          <div class="md:col-6 col-12">
            <InputDate
              v-model="eventdate"
              type="text"
              class="w-full"
              label="Start Date"
              placeholder="Enter Event Date"
            />
          </div>
        </div>
        <div class="grid mx-0 mt-4">
          <div class="col-12 flex set-button">
            <PrimeButton
              label="Add"
              class="order-form-button"
              v-if="!isEditMode"
              @click="createMember"
            >
            </PrimeButton>
            <PrimeButton
              label="Edit"
              class="order-form-button"
              v-else
              @click="createMember"
            ></PrimeButton>
            <PrimeButton
              label="Cancel"
              class="form-cancel-button sm:ml-2"
              @click="onCancel"
            ></PrimeButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      subtitle: "Add Employee Attendance",
    };
  },
};
</script>

<style>
.p-calendar-w-btn .p-datepicker-trigger {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #ff9f43 !important;
  border-color: #ff9f43 !important;
}
</style>
