<template>
  <PrimeCard
    class="calendar-card"
    style="height: 100%; display: flex; flex-direction: column"
  >
    <template #content>
      <FullCalendar @select="closeModal" :options="calendarOptions" />
    </template>
  </PrimeCard>
  <div>
    <Dialog
      header="Add Event"
      modal
      v-model:visible="showPopup"
      :breakpoints="{ '960px': '75vw', '640px': '80vw' }"
      :style="{ width: '36vw' }"
      class="popup"
      :class="{
        blur: this.$root.$globalState.loader,
      }"
      @hide="closeModal"
      :closable="!loading"
      :draggable="false"
    >
      <div class="grid">
        <div class="md:col-6 col-12" v-if="checked">
          <DropDownRequire
            v-model="vmodel.customerid"
            :validation="v$.vmodel.customerid"
            :options="customerOption"
            id="customer"
            :filter="true"
            class="w-full mt-2"
            placeholder="Select Customer"
            label="Customer"
            optionLabel="name"
            optionValue="id"
            :disabled="checked"
          />
        </div>
        <div class="md:col-6 col-12" v-else>
          <DropDownRequire
            v-model="vmodel.customerid"
            :validation="v$.vmodel.customerid"
            :options="customerOption"
            id="customer"
            :filter="true"
            class="w-full mt-2"
            placeholder="Select Customer"
            label="Customer"
            optionLabel="name"
            optionValue="id"
          />
        </div>
        <div
          class="md:col-6 col-12 flex align-items-center md:mt-3 md:mb-0 mb-3"
        >
          <Checkbox
            v-model="checked"
            :binary="true"
            inputId="listed"
            @change="changedchecked"
          /><label for="listed" class="ml-2"> Not Listed </label>
        </div>
      </div>

      <div class="grid" v-if="checked">
        <div class="md:col-6 col-12">
          <InputTextRequire
            v-model="vmodel.name"
            :validation="v$.vmodel.name"
            id="name"
            type="text"
            class="w-full"
            placeholder="Enter Customer Name"
            label="Customer Name"
            labelnotshow="false"
          />
        </div>
        <div class="md:col-6 col-12">
          <InputTextRequire
            :validation="v$.vmodel.email"
            v-model="vmodel.email"
            id="email"
            type="text"
            class="w-full"
            label="Email"
            showIcon
            placeholder="Enter Email"
            labelnotshow="false"
            maxLength="75"
          />
        </div>

        <div class="md:col-6 col-12">
          <InputTextRequire
            :validation="v$.vmodel.contactno"
            v-model="vmodel.contactno"
            id="contactno"
            type="text"
            class="w-full"
            placeholder="Enter Contact Number"
            label="Contact Number"
            maxlength="10"
            minlength="10"
            labelnotshow="false"
            @keypress="onlyNumber"
          />
        </div>
        <div class="md:col-6 col-12">
          <InputTextRequire
            :validation="v$.vmodel.address1"
            v-model="vmodel.address1"
            id="address1"
            type="text"
            class="w-full"
            placeholder="Enter Address"
            label="Address"
            labelnotshow="false"
          />
        </div>
      </div>
      <div class="grid">
        <div class="md:col-5 col-12">
          <InputTextRequire
            :validation="v$.vmodel.eventname"
            v-model="vmodel.eventname"
            id="eventname"
            type="text"
            class="w-full"
            placeholder="Enter Event Name"
            label="Event Name"
            labelnotshow="false"
          />
        </div>
        <div class="md:col-5 col-9">
          <label for="date" class="form-label"> Date and Time </label>
          <Calendar
            v-model="vmodel.date"
            id="date"
            showTime
            hourFormat="24"
            class="w-full mt-2"
            label="Date"
            showIcon
            placeholder="Enter Date"
            dateFormat="yy-mm-dd"
          />
        </div>
        <div class="col-2">
          <InputNumberRequire
            :validation="v$.vmodel.people"
            v-model="vmodel.people"
            id="people"
            label="Person"
            :max="999999"
            @keydown="onlyDigit"
          />
        </div>
        <div class="grid col-12 ml-1">
          <div
            class="grid col-4"
            v-for="(eventtype, index) in eventtypeOption"
            :id="index"
            :key="index"
          >
            <RadioButton
              :validation="v$.vmodel.eventtype"
              v-model="vmodel.eventtype"
              :inputId="`${eventtype}-option`"
              name="eventType"
              :value="`${eventtype}`"
            />
            <label class="ml-2 form-label" :for="`${eventtype}-option`">{{
              eventtype
            }}</label>
          </div>
          <span
            v-if="
              v$.vmodel.eventtype.$error &&
              v$.vmodel.eventtype.required.$invalid
            "
            id="eventtype-help"
            class="p-error text-left"
          >
            Event Type is required
          </span>
        </div>

        <div class="md:col-6 col-12">
          <DropDownRequire
            v-model="vmodel.status"
            :validation="v$.vmodel.status"
            :options="statusoption"
            id="status"
            :filter="true"
            optionLabel="name"
            placeholder="Select Status"
            optionValue="name"
            class="w-full"
            label="Status"
          />
        </div>
        <div class="md:col-6 col-12">
          <InputTextRequire
            v-model="vmodel.venue"
            :validation="v$.vmodel.venue"
            id="venue"
            type="text"
            class="w-full"
            placeholder="Enter Venue"
            label="Venue"
            labelnotshow="false"
          />
        </div>
      </div>

      <div class="grid mt-2">
        <div class="col-12">
          <PrimeButton
            label="Add"
            @click="addEvent"
            v-if="checked"
            :disabled="loading"
          ></PrimeButton>
          <PrimeButton
            label="Add"
            @click="addEvent"
            v-else
            :disabled="loading"
          ></PrimeButton>

          <PrimeButton
            @click="closeModal"
            label="Close"
            class="ml-2"
            :disabled="loading"
          ></PrimeButton>
        </div>
      </div>
    </Dialog>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
// import { INITIAL_EVENTS } from "../event-utils";
import Dialog from "primevue/dialog";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  requiredIf,
  maxLength,
  email,
  numeric,
  minValue,
  maxValue,
  minLength,
} from "@vuelidate/validators";

import DateFormat from "@/libs/DateFormat";
export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    Dialog,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  emits: ["loaddata", "notloaddata"],
  data() {
    return {
      submitted: false,
      datetime24h: null,
      // count:"",
      statusoption: [
        { name: "Inquiry" },
        { name: "Confirm" },
        { name: "Cancel" },
      ],
      checked: false,
      today: new Date(),
      customerOption: [],
      eventtypeOption: [],
      vmodel: {
        customerid: "",
        name: "",
        email: "",
        address1: "",
        contactno: "",
        eventname: "",
        eventtype: "",
        people: 1,
        date: "",
        venue: "",
        status: "",
      },
      showPopup: false,
      showEdit: false,
      loading: false,
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        // selectAllow: function (selectInfo) {
        //   let date = new Date(
        //     new Date().getFullYear(),
        //     new Date().getMonth(),
        //     new Date().getDate(),
        //     0,
        //     0
        //   );
        //   return new Date(selectInfo.start) >= date;
        // },
        initialView: "dayGridMonth",
        eventSources: [],
        // initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        // eventDidMount: this.handleRender,
        datesSet: this.handleMonthChange.bind(this),
        displayEventTime: false,
        showNonCurrentDates: false,
        fixedWeekCount: false,
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
      },
      currentEvents: [],
    };
  },
  validations() {
    if (this.checked) {
      return {
        vmodel: {
          customerid: {},
          name: {
            requiredIf: requiredIf((this.checked = true)),
            max: maxLength(30),
          },
          email: { email, max: maxLength(75) },
          address1: {
            requiredIf: requiredIf((this.checked = true)),
            max: maxLength(35),
          },
          contactno: {
            requiredIf: requiredIf((this.checked = true)),
            min: minLength(10),
            max: maxLength(10),
          },
          venue: { required, max: maxLength(90) },
          status: { required },
          eventtype: { required },
          eventname: { required, max: maxLength(40) },
          people: {
            required,
            numeric,
            min: minValue(1),
            max: maxValue(999999),
          },
        },
      };
    } else {
      return {
        vmodel: {
          customerid: { required },
          venue: { required, max: maxLength(90) },
          status: { required },
          eventname: { required, max: maxLength(40) },
          eventtype: { required },
          people: {
            required,
            numeric,
            min: minValue(1),
            max: maxValue(999999),
          },
        },
      };
    }
  },
  watch: {
    "$root.$globalState.loader": {
      handler(newValue) {
        this.loading = newValue;
      },
      immediate: true,
    },
  },
  async mounted() {
    this.getCustomerDrop();
    await this.getEventType();
  },
  methods: {
    async getEventType() {
      this.$root.$globalState.loader = true;
      await APIService.get("/company/eventtype")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.eventtypeOption = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    onlyDigit($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        (this.vmodel.people >= 999999 &&
          keyCode > 47 &&
          keyCode < 58 &&
          keyCode !== 8 &&
          keyCode !== 32) ||
        (this.vmodel.people >= 999999 &&
          keyCode !== 229 &&
          keyCode !== 8 &&
          keyCode !== 32)
      ) {
        $event.preventDefault();
      }
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || (keyCode > 57 && keyCode !== 229)) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    changedchecked() {
      this.vmodel.customerid = "";
    },
    getCustomerDrop() {
      this.$root.$globalState.loader = true;
      APIService.get("/customer/customer")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.customerOption = response.data.data;
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },

    async addEvent() {
      this.submitted = true;
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        this.$root.$globalState.loader = true;
        this.$emit("loaddata");
        let payload;

        if (!this.checked) {
          payload = {
            customerid: this.vmodel.customerid,
            eventname: this.vmodel.eventname,
            people: this.vmodel.people,
            date: this.vmodel.date,
            time: DateFormat.setTime(this.vmodel.date),
            venue: this.vmodel.venue,
            status: this.vmodel.status,
            eventtype: this.vmodel.eventtype,
          };
        } else {
          payload = {
            eventname: this.vmodel.eventname,
            name: this.vmodel.name,
            address1: this.vmodel.address1,
            email: this.vmodel.email,
            contactno: this.vmodel.contactno,
            date: this.vmodel.date,
            time: DateFormat.setTime(this.vmodel.date),
            people: this.vmodel.people,
            venue: this.vmodel.venue,
            status: this.vmodel.status,
            eventtype: this.vmodel.eventtype,
          };
        }

        APIService.post("/customerevent", payload)
          .then((response) => {
            if (response && response.data && response.data.success) {
              let fromdate = new Date(
                new Date(this.vmodel.date).getFullYear(),
                new Date(this.vmodel.date).getMonth(),
                1
              );
              let todate = new Date(
                new Date(
                  new Date(this.vmodel.date).getFullYear(),
                  new Date(this.vmodel.date).getMonth() + 1,
                  0
                )
              );
              this.getEventDetails(fromdate, todate);
              this.closeModal();
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
            this.$root.$globalState.loader = false;
            this.$emit("notloaddata");
          });
      }
    },
    async getEventDetails(fromdate, todate) {
      let payload = {
        fromDate: DateFormat.formatDate(fromdate),
        toDate: DateFormat.formatDate(todate),
      };
      this.$root.$globalState.loader = true;
      APIService.post("/event/eventByDate", payload)
        .then((response) => {
          if (response && response.data && response.data.success) {
            for (let event of response.data.data) {
              if (event.status == "Confirm") {
                event.backgroundColor = "#33b681";
              }
              if (event.status == "Inquiry") {
                event.backgroundColor = "#ebb336";
              }
              if (event.status == "Cancel") {
                event.backgroundColor = "#fe4141";
              }
            }
            this.calendarOptions.eventSources = [
              { events: response.data.data },
            ];
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    async handleMonthChange(res) {
      let fromdate = new Date(res.view.currentStart);
      let todate = new Date(res.view.currentEnd);
      this.getEventDetails(fromdate, todate);
    },
    async handleRender(event) {
      if (event.event._def.extendedProps.status == "Confirm") {
        // event.setAllDay(false, {maintainDuration: true});
        event.backgroundColor = "#000";
        event.textColor = "#fff";
      }
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    handleDateSelect(clickInfo) {
      let date = new Date();
      let setDate = new Date(
        clickInfo.start.getFullYear(),
        clickInfo.start.getMonth(),
        clickInfo.start.getDate(),
        date.getHours(),
        date.getMinutes()
      );
      this.showPopup = true;
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      // this.vmodel.date = clickInfo.start;
      this.vmodel.date = setDate;
    },
    handleEventClick(clickInfo) {
      let eventid = clickInfo.event._def.publicId;
      this.$router.push({
        name: "Cart",
        params: { id: eventid },
      });
    },
    handleEvents(events) {
      this.currentEvents = events;
    },
    closeModal() {
      this.vmodel.customerid = "";
      this.vmodel.name = "";
      this.vmodel.address1 = "";
      this.vmodel.contactno = "";
      this.vmodel.customerid = "";
      this.vmodel.email = "";
      this.vmodel.eventname = "";
      this.vmodel.people = 1;
      this.vmodel.status = "";
      this.vmodel.venue = "";
      this.vmodel.eventtype = "";
      this.showPopup = false;
      this.checked = false;
      this.v$.$reset();
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b {
  /* used for event dates/times */
  margin-right: 3px;
}
.fc {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background: #6c757d !important;
  border: 1px solid #6c757d;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background: #6c757d !important;
  border: 1px solid #6c757d;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
  background: #6c757d !important;
  color: #ffffff;
  border-color: #6c757d !important;
}

:deep(.p-button) {
  color: #ffffff;
  background: #ff9f43 !important;
  border: 1px solid #ff9f43;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.p-button:enabled:hover {
  color: #ffffff;
  background: #ff9f43 !important;
  border: 1px solid #ff9f43;
}

.p-dialog .p-dialog-header {
  border-bottom: 1px solid #ff9f43 !important;
  background: #ff9f43 !important;
  color: #ffffff !important;
  padding: 1rem;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

:deep(.p-inputnumber-input) {
  flex: 1 1 auto;
  width: 100%;
}
:deep(.p-dropdown) {
  min-width: 200px;
}
</style>
