<template>
  <PrimeCard style="height: 100%; display: flex; flex-direction: column">
    <template #content>
      <div class="col-12">
        <div class="grid justify-content-between align-items-center">
          <div class="card-title">
            Remaining Payment -
            <span class="set-payment"
              >Rs. {{ remainingPayment.toFixed(2) }}</span
            >
          </div>
        </div>
        <div class="grid">
          <div class="col-12 p-0 m-0">
            <DataTable
              :value="paymentData"
              class="p-datatable-lg p-datatable-customers"
              :rows="10"
              dataKey="id"
              :rowHover="true"
              :resizableColumns="true"
            >
              <template #loading> Loading records, please wait... </template>
              <template #empty> No records found. </template>
              <Column
                field="customerinvoice"
                header="Invoice Number - Customer Name"
                class="setdatewidth"
              ></Column>
              <Column
                field="remainingAmount"
                header="Remaining Payment"
                class="setdatewidth"
              >
                <template #body="row">
                  <div class="flex">
                    <span>{{ row.data.remainingAmount.toFixed(2) }}</span>
                  </div>
                </template>
              </Column>
              <Column
                field="invoicedate"
                header="Invoice Date"
                class="setdatewidth"
              >
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </template>
  </PrimeCard>
</template>
<script>
import APIService from "@/services/api-service.js";
import RemainingPaymentFactory from "@/factories/remainingPayment";
export default {
  data() {
    return {
      visibleDDMenu: false,
      paymentData: [],
    };
  },
  props: {
    remainingPayment: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$root.$globalState.loader = true;
      APIService.get("/invoice/getRemainingInvoice")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.paymentData = RemainingPaymentFactory.createFromJsonArray(
              response.data.data
            );
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
// @import "@/assets/scss/datatable.scss";
/* @import "@/assets/css/login.css" */

::v-deep(.p-datatable.p-datatable-customers .p-datatable-wrapper) {
  padding: 0px;
}

::v-deep(.p-datatable) {
  margin-top: 0px;
}

::v-deep(.p-card) {
  display: flex !important;
  height: 100% !important;
}

.margin-username {
  margin-top: 10px;
  margin-left: 10px;
}

.card-title {
  font-size: 18px;
  font-weight: 700;
  color: #212b36;
  margin: 0 0 20px;
}
:deep(
    .p-datatable.p-datatable-customers .p-datatable-thead tr th.setdatewidth
  ) {
  max-width: 140px;
  white-space: normal !important;
}
:deep(.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td) {
  white-space: normal !important;
  max-width: 140px;
  padding: 1rem 1rem !important;
}
@media (max-width: 384.98px) {
  .card-title {
    display: flex;
    flex-direction: column;
  }
  .set-payment {
    width: 100%;
  }
}
</style>
