import Event from '@/models/event'

export default class EventFactory {
    static createFromJson(json) {
        return new Event(json)
    }

    static createFromJsonArray(jsonArray) {
        const jsonData = []
        jsonArray.forEach(item => {
            jsonData.push(EventFactory.createFromJson(item))
        });

        return jsonData
    }
}
