import DateFormat from "@/libs/DateFormat"
// import RowMaterialFactory from "@/factories/rowmaterial"

export default class Invoice {
    constructor(data = {}) {
        return {
            id: data.id || '',
            customer: data.customer || '',
            event: data.event || '',
            invoicenumber: data.invoicenumber || '',
            invoiceamount: data.invoiceamount || 0.00,
            invoicedate: DateFormat.formatDate(data.invoicedate) || '',
            duedate: DateFormat.formatDate(data.duedate) || '',
            totalamount: data.totalamount || 0.00,
        }
    }
}