<template>
  <div
    id="app"
    :class="{
      invalid: validation.$error,
    }"
  >
    <div ref="editor" style="height: 340px"></div>
    <span
      v-if="validation?.$error && validation.max && validation.max.$invalid"
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.max.$message }}
    </span>
  </div>
</template>

<script>
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import Quill from "quill";

export default {
  name: "App",
  components: {},
  data() {
    let self = this;
    return {
      editor: null,
      toolbar_settings: {
        container: [["bold", "italic", "underline", "strike"]],
        handlers: {
          redo() {
            self.editor.history.redo();
          },
          undo() {
            self.editor.history.undo();
          },
        },
      },
    };
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    validation: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    var _this = this;
    let options = {
      debug: this.debug,
      modules: {
        history: {
          delay: 1000,
          maxStack: 100,
          userOnly: false,
        },
        toolbar: this.toolbar_settings,
        // this.toolbar,
      },
      theme: "snow",
      formats: ["bold", "italic", "underline", "strike"],
    };

    this.editor = new Quill(this.$refs.editor, options);

    this.editor.root.innerHTML = this.modelValue;
    this.editor.on("text-change", function () {
      return _this.update();
    });
  },
  methods: {
    update: function update() {
      this.$emit(
        "update:modelValue",
        this.editor.getText() ? this.editor.root.innerHTML : ""
      );
    },
  },
};
</script>
