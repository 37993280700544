<template>
  <!-- <div class="ov?erlay"> -->
  <Dialog
    header="Session Expired"
    modal
    v-model:visible="showPopup"
    :breakpoints="{ '960px': '75vw', '640px': '80vw' }"
    :style="{ width: '36vw' }"
    class="popup"
    :draggable="false"
  >
    <div class="overlay">
      <div class="modal">
        <div class="p-3">
          <p>You have left this browser idle for 10 minutes.</p>
          <p>{{ second }} secound left</p>
        </div>
      </div>
    </div>
  </Dialog>
  <!-- </div> -->
</template>

<script>
import Dialog from "primevue/dialog";

export default {
  components: {
    Dialog,
  },

  data() {
    return {
      time: 10000,
      showPopup: true,
    };
  },
  computed: {
   
     second() {
       return this.time / 1000;
    },
  },
  created() {
    let timerId = setInterval(() => {
      this.time -= 1000;
      if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);
  
      if (this.time < 1) {
        clearInterval(timerId);
        // Your logout function should be over here
        this.$store.dispatch("logout");
        this.$router.push({ name: "Login" });
      }
    }, 1000);
  },
};
</script>