import DateFormat from "@/libs/DateFormat";

export default class RowMaterial {
  constructor(data = {}) {
    return {
      id: data.id || "",
      name: data.name || "",
      type: data.type || "",
      qty: data.qty || "",
      qtytype: data.qtytype || "",
      purchasefrom: data.purchasefrom || "",
      price: data.price || "",
      categoryid: data.categoryid || "",
      purchasedate: DateFormat.formatDateTime(data.purchasedate) || "",
      expiredate: DateFormat.formatDateTime(data.expiredate) || "",
    };
  }
}
