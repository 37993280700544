<template>
  <Dialog
    header="Select Category"
    modal
    v-model:visible="display"
    :breakpoints="{ '960px': '75vw', '640px': '80vw' }"
    :style="{ width: '50vw' }"
    class="popup"
    :class="{
      blur: this.$root.$globalState.loader,
    }"
    :draggable="false"
    :dismissableMask="true"
    @hide="closePopup"
  >
    <div class="grid">
      <div class="md:col-12 col-12">
        <DropDownRequire
          :options="categoryOption"
          v-model="vmodel.category"
          :validation="v$.vmodel.category"
          id="category"
          :filter="true"
          placeholder="Select Category"
          optionLabel="name"
          optionValue="id"
          label="Category"
        />
      </div>
    </div>
    <div class="grid mx-0">
      <div class="col-12 flex justify-content-end set-button">
        <PrimeButton
          label="Select"
          class="order-form-button"
          @click="createCategory"
        >
        </PrimeButton>
      </div>
    </div>
  </Dialog>
</template>
<script>
import Dialog from "primevue/dialog";
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  emits: ["displayNo", "displayYes"],
  components: {
    Dialog,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      display: false,
      submitted: false,
      categoryOption: [],
      vmodel: {
        category: "",
      },
    };
  },
  validations() {
    if (this.display) {
      return {
        vmodel: {
          category: { required },
        },
      };
    } else {
      return {
        vmodel: {
          category: {},
        },
      };
    }
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  watch: {
    showPopup: {
      async handler(newValue) {
        if (newValue === true) {
          await this.v$.$reset();
          await this.openPopup();
          await this.getCategory();
          document.querySelector("body").style.overflow = "hidden";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async getCategory() {
      this.$root.$globalState.loader = true;
      await APIService.get("/category/dropdown")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.categoryOption = response.data.data;
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    async createCategory() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        this.submitted = true;
        this.$emit("displayYes", this.vmodel.category);
        this.display = false;
      }
    },
    openPopup() {
      this.display = true;
    },
    async closePopup() {
      this.display = false;
      await this.$emit("displayNo");
      await this.v$.$reset();
      this.vmodel.category = "";
      document.querySelector("body").style.overflow = "auto";
    },
  },
};
</script>
