<template>
  <div class="ml-5 mr-5 set-margin-top mb-5">
    <div class="grid">
      <div class="grid">
        <div class="order-form-title w-full">Company Management</div>
        <div class="order-form-sub-title">{{ subtitle }}</div>
      </div>
      <div class="order-card w-full">
        <div class="grid">
          <!-- <div :class="isEditMode ? 'col-6' : 'col-4'"> -->
          <div class="md:col-4 col-12">
            <InputTextRequire
              :validation="v$.vmodel.companyname"
              v-model="vmodel.companyname"
              id="companyname"
              type="text"
              class="w-full"
              placeholder="Enter Name"
              label="Name"
            />
          </div>
          <div class="md:col-4 col-12">
            <!-- <div :class="isEditMode ? 'col-6' : 'col-4'"> -->
            <InputTextRequire
              v-model="vmodel.email"
              :validation="v$.vmodel.email"
              id="email"
              type="text"
              class="w-full"
              label="Email"
              placeholder="Enter Email"
              maxLength="75"
            />
          </div>
          <div class="md:col-4 col-12">
            <InputTextRequire
              v-model="vmodel.password"
              :validation="v$.vmodel.password"
              id="password"
              type="text"
              class="w-full"
              label="Password"
              placeholder="Enter Password"
            />
          </div>
        </div>

        <div class="grid">
          <div class="md:col-4 col-12">
            <InputTextRequire
              v-model="vmodel.contactno"
              :validation="v$.vmodel.contactno"
              type="text"
              id="contactno"
              class="w-full"
              placeholder="Enter Contact Number"
              label="Contact Number"
              @keypress="onlyNumber"
            />
          </div>
          <div class="md:col-4 col-12">
            <InputTextRequire
              v-model="vmodel.contactperson"
              :validation="v$.vmodel.contactperson"
              id="contactperson"
              type="text"
              class="w-full"
              placeholder="Enter Contact Person"
              label="Contact Person"
            />
          </div>
          <div class="md:col-4 col-12">
            <InputTextRequire
              v-model="vmodel.domainname"
              :validation="v$.vmodel.domainname"
              id="domainname"
              type="text"
              class="w-full"
              placeholder="Enter Domain Name"
              label="Domain Name"
            />
          </div>
        </div>
        <div class="grid">
          <div class="md:col-4 col-12">
            <InputNumberRequire
              v-model="vmodel.numofUser"
              :validation="v$.vmodel.numofUser"
              id="user"
              class="w-full"
              :min="1"
              label="Number of User"
              placeholder="Enter Number of User"
            />
          </div>
          <div class="md:col-4 col-12">
            <InputNumberRequire
              v-model="vmodel.numofRecipe"
              :validation="v$.vmodel.numofRecipe"
              id="recipe"
              class="w-full"
              :min="1"
              label="Number of Recipe"
              placeholder="Enter Number of Recipe"
            />
          </div>
          <div class="md:col-4 col-12">
            <InputNumberRequire
              v-model="vmodel.numofCustomer"
              :validation="v$.vmodel.numofCustomer"
              id="customer"
              class="w-full"
              :min="1"
              label="Number of Customer"
              placeholder="Enter Number of Customer"
            />
          </div>
        </div>
        <div class="grid">
          <div class="md:col-6 col-12">
            <label for="startdate" class="form-label"> Start Date </label>
            <Calendar
              v-model="vmodel.startdate"
              id="startdate"
              type="text"
              class="w-full mt-2 mb-2"
              label="Start Date"
              placeholder="Select Start Date"
              :minDate="minEventDate"
              dateFormat="yy-mm-dd"
              showIcon
              :class="{
                'p-invalid': v$.vmodel.startdate.$error,
              }"
              :aria-describedby="`startdate-help`"
              v-if="!isEditMode"
            />
            <Calendar
              v-model="vmodel.startdate"
              id="startdate"
              type="text"
              class="w-full mt-2 mb-2"
              label="Start Date"
              placeholder="Select Start Date"
              dateFormat="yy-mm-dd"
              showIcon
              :class="{
                'p-invalid': v$.vmodel.startdate.$error,
              }"
              :aria-describedby="`startdate-help`"
              v-else
            />
            <span
              v-if="
                v$.vmodel.startdate.$error &&
                v$.vmodel.startdate.required.$invalid
              "
              id="startdate-help"
              class="p-error text-left mt-4"
            >
              Start Date is required
            </span>
          </div>
          <div class="md:col-6 col-12">
            <label for="enddate" class="form-label"> End Date </label>
            <Calendar
              v-model="vmodel.enddate"
              id="enddate"
              type="text"
              class="w-full mt-2 mb-2"
              label="End Date"
              placeholder="Select End Date"
              :minDate="minEventDate"
              dateFormat="yy-mm-dd"
              showIcon
              :class="{
                'p-invalid': v$.vmodel.enddate.$error,
              }"
              :aria-describedby="`enddate-help`"
              v-if="!isEditMode"
            />
            <Calendar
              v-model="vmodel.enddate"
              id="enddate"
              type="text"
              class="w-full mt-2 mb-2"
              label="End Date"
              placeholder="Select End Date"
              dateFormat="yy-mm-dd"
              showIcon
              :class="{
                'p-invalid': v$.vmodel.enddate.$error,
              }"
              :aria-describedby="`enddate-help`"
              v-else
            />
            <span
              v-if="
                v$.vmodel.enddate.$error && v$.vmodel.enddate.required.$invalid
              "
              id="enddate-help"
              class="p-error text-left mt-4"
            >
              End Date is required
            </span>
          </div>
        </div>
        <div class="grid">
          <div class="md:col-4 col-12">
            <InputTextRequire
              v-model="vmodel.address"
              :validation="v$.vmodel.address"
              type="text"
              id="address"
              class="w-full"
              placeholder="Enter Address"
              label="Address"
            />
          </div>
          <div class="md:col-4 col-12">
            <InputTextRequire
              v-model="vmodel.website"
              :validation="v$.vmodel.website"
              id="website"
              type="text"
              class="w-full"
              placeholder="Enter Website"
              label="Website"
            />
          </div>
          <div class="md:col-4 col-12">
            <InputTextRequire
              v-model="vmodel.companyemail"
              :validation="v$.vmodel.companyemail"
              id="companyemail"
              type="text"
              class="w-full"
              placeholder="business@gmail.com"
              label="Info email"
            />
          </div>
        </div>
        <div class="grid col-12">
          <div class="col-4">
            <Checkbox
              :binary="true"
              inputId="showoneimage"
              v-model="vmodel.docsetting.showoneimage"
            />
            <label for="showoneimage" class="ml-2 form-label"
              >Show Only Company Logo (Center)</label
            >
          </div>
        </div>
        <div class="grid mx-0 flex flex-column mt-2">
          <label for="photo" class="form-label mb-2 ml-2">Company Logo</label>
          <div
            class="photo-section cursor-pointer mx-1"
            @click="openFileUpload"
          >
            <img
              v-if="pic !== ''"
              :src="pic"
              alt=""
              class="upload-img"
              height="110"
              width="120"
              label="Image"
            />
            <span class="upload-icon" v-if="pic == ''"></span>
            <div class="upload-text" v-if="pic == ''">Upload Image Here</div>
          </div>

          <FileUpload
            ref="photo"
            name="photo"
            accept=".png , .jpg , .jpeg , .svg"
            @select="handleFileUpload($event)"
            v-show="false"
            :aria-describedby="`photo-help`"
          />
        </div>
        <div
          class="grid mx-0 flex flex-column mt-4"
          v-if="!vmodel.docsetting.showoneimage"
        >
          <label for="photo" class="form-label mb-2 ml-2"
            >Show Company Logo in PDF</label
          >
        </div>
        <div class="grid col-12" v-if="!vmodel.docsetting.showoneimage">
          <div class="col-4">
            <RadioButton
              :validation="v$.vmodel.docsetting.showlogojustify"
              v-model="vmodel.docsetting.showlogojustify"
              inputId="showlogojustifyleft"
              name="showlogojustify"
              :value="'left'"
            />
            <label for="showlogojustifyleft" class="ml-2 form-label"
              >Left</label
            >
          </div>

          <div class="col-4">
            <RadioButton
              :validation="v$.vmodel.docsetting.showlogojustify"
              v-model="vmodel.docsetting.showlogojustify"
              inputId="showlogojustifycenter"
              name="showlogojustify"
              :value="'center'"
            />
            <label for="showlogojustifycenter" class="ml-2 form-label"
              >Center</label
            >
          </div>

          <div class="col-4">
            <RadioButton
              :validation="v$.vmodel.docsetting.showlogojustify"
              v-model="vmodel.docsetting.showlogojustify"
              inputId="showlogojustifyright"
              name="showlogojustify"
              :value="'right'"
            />
            <label for="showlogojustifyright" class="ml-2 form-label"
              >Right</label
            >
          </div>

          <span
            v-if="
              v$.vmodel.docsetting.showlogojustify.$error &&
              v$.vmodel.docsetting.showlogojustify.required.$invalid
            "
            id="eventtype-help"
            class="p-error text-left"
          >
            Show Company Logo in PDF is required
          </span>
        </div>
        <div
          class="grid mx-0 flex flex-column mt-2"
          v-if="!vmodel.docsetting.showoneimage"
        >
          <label for="photo" class="form-label mb-2 ml-2">Other Logo</label>
          <div
            class="photo-section cursor-pointer mx-1"
            @click="openotherpicUpload"
          >
            <img
              v-if="otherpic !== ''"
              :src="otherpic"
              alt=""
              class="upload-img"
              height="110"
              width="120"
              label="Image"
            />

            <span class="upload-icon" v-if="otherpic == ''"></span>
            <div class="upload-text" v-if="otherpic == ''">
              Upload Image Here
            </div>
          </div>

          <FileUpload
            ref="otherpic"
            name="otherpic"
            accept=".png , .jpg , .jpeg , .svg"
            @select="handleotherpicUpload($event)"
            v-show="false"
            :aria-describedby="`otherpic-help`"
          />
        </div>
        <div
          class="grid mx-0 flex flex-column mt-4"
          v-if="!vmodel.docsetting.showoneimage"
        >
          <label for="otherpic" class="form-label mb-2 ml-2"
            >Show Other Logo in PDF</label
          >
        </div>
        <div class="grid col-12" v-if="!vmodel.docsetting.showoneimage">
          <div class="col-4">
            <RadioButton
              :validation="v$.vmodel.docsetting.showotherpicjustify"
              v-model="vmodel.docsetting.showotherpicjustify"
              inputId="showotherpicjustifyleft"
              name="showotherpicjustify"
              :value="'left'"
            />
            <label for="showotherpicjustifyleft" class="ml-2 form-label"
              >Left</label
            >
          </div>

          <div class="col-4">
            <RadioButton
              :validation="v$.vmodel.docsetting.showotherpicjustify"
              v-model="vmodel.docsetting.showotherpicjustify"
              inputId="showotherpicjustifycenter"
              name="showotherpicjustify"
              :value="'center'"
            />
            <label for="showotherpicjustifycenter" class="ml-2 form-label"
              >Center</label
            >
          </div>

          <div class="col-4">
            <RadioButton
              :validation="v$.vmodel.docsetting.showotherpicjustify"
              v-model="vmodel.docsetting.showotherpicjustify"
              inputId="showotherpicjustifyright"
              name="showotherpicjustify"
              :value="'right'"
            />
            <label for="showotherpicjustifyright" class="ml-2 form-label"
              >Right</label
            >
          </div>

          <span
            v-if="
              v$.vmodel.docsetting.showotherpicjustify.$error &&
              v$.vmodel.docsetting.showotherpicjustify.required.$invalid
            "
            id="eventtype-help"
            class="p-error text-left"
          >
            Show Other Logo in PDF is required
          </span>
        </div>
        <div class="grid mx-0 flex flex-column mt-4">
          <label for="photo" class="form-label mb-2 ml-2"
            >Templates For PDFs</label
          >
          <div
            class="photo-section align-items-start justify-content-start h-auto mx-1"
          >
            <div>
              <Checkbox
                :binary="true"
                inputId="main_menu"
                v-model="vmodel.template.main_menu"
              />
              <label for="main_menu" class="ml-2"> Main Menu - Recipe </label>
            </div>
            <div>
              <Checkbox
                :binary="true"
                inputId="ing_list"
                v-model="vmodel.template.ing_list"
              />
              <label for="ing_list" class="ml-2">
                Ingredient List with Menu
              </label>
            </div>
            <div>
              <Checkbox
                :binary="true"
                inputId="cat_list"
                v-model="vmodel.template.cat_list"
              />
              <label for="cat_list" class="ml-2">
                Ingredient List with Category</label
              >
            </div>
            <div>
              <Checkbox
                :binary="true"
                inputId="ing_list"
                v-model="vmodel.template.recipe_merge_pdf"
              />
              <label for="ing_list" class="ml-2"> Merge PDF - Recipe </label>
            </div>
            <div>
              <Checkbox
                :binary="true"
                inputId="cat_single_list"
                v-model="vmodel.template.cat_single_list"
              />
              <label for="cat_single_list" class="ml-2">
                Ingredient List with Single-Category</label
              >
            </div>
            <div>
              <Checkbox
                :binary="true"
                inputId="recipe_menu_submenu"
                v-model="vmodel.template.recipe_menu_submenu"
                @change="addImages"
              />
              <label for="recipe_menu_submenu" class="ml-2">
                Recipe List with Menu and Submenu
              </label>
            </div>
            <div>
              <Checkbox
                :binary="true"
                inputId="recipe_menu_submenu_vendor"
                v-model="vmodel.template.recipe_menu_submenu_vendor"
                @change="addImages"
              />
              <label for="recipe_menu_submenu_vendor" class="ml-2">
                Recipe List with Menu, Submenu and Vendor
              </label>
            </div>
            <div>
              <Checkbox
                :binary="true"
                inputId="recipe_menu_submenu_ing"
                v-model="vmodel.template.recipe_menu_submenu_ing"
                @change="addImages"
              />
              <label for="recipe_menu_submenu_ing" class="ml-2">
                Recipe List With Menu, Submenu and Ingredient
              </label>
            </div>
            <div>
              <Checkbox
                :binary="true"
                inputId="recipe_menu_submenu_ing_vendor"
                v-model="vmodel.template.recipe_menu_submenu_ing_vendor"
                @change="addImages"
              />
              <label for="recipe_menu_submenu_ing_vendor" class="ml-2">
                Recipe List With Menu, Submenu, Ingredient and Vendor
              </label>
            </div>
            <div>
              <Checkbox
                :binary="true"
                inputId="ing_recipe"
                v-model="vmodel.template.ing_recipe"
              />
              <label for="ing_recipe" class="ml-2">
                Ingredient List with Recipe
              </label>
            </div>
            <div>
              <Checkbox
                :binary="true"
                inputId="ing_qty_recipe"
                v-model="vmodel.template.ing_qty_recipe"
              />
              <label for="ing_qty_recipe" class="ml-2">
                Ingredient List - QTY with Recipe
              </label>
            </div>
            <div>
              <Checkbox
                :binary="true"
                inputId="ing_total_qty_recipe"
                v-model="vmodel.template.ing_total_qty_recipe"
              />
              <label for="ing_total_qty_recipe" class="ml-2">
                Ingredient List - Total QTY with Recipe
              </label>
            </div>
            <div>
              <Checkbox
                :binary="true"
                inputId="ing_total_qty_cat"
                v-model="vmodel.template.ing_total_qty_cat"
              />
              <label for="ing_total_qty_cat" class="ml-2">
                Ingredient List - Total QTY with Category
              </label>
            </div>
          </div>
        </div>
        <div class="grid mx-0 flex flex-column mt-4">
          <label for="photo" class="form-label mb-2 ml-2"
            >Templates For PDFs</label
          >
          <div class="grid m-0">
            <div class="col-12 pt-0 pb-0">
              <Checkbox
                :binary="true"
                inputId="add_images"
                v-model="vmodel.template.add_images"
                :disabled="
                  vmodel.template.recipe_menu_submenu ||
                  vmodel.template.recipe_menu_submenu_ing ||
                  vmodel.template.recipe_menu_submenu_ing_vendor ||
                  vmodel.template.recipe_menu_submenu_vendor
                "
              />
              <label for="add_images" class="ml-2"> Add Images </label>
            </div>
            <div class="col-12 pt-0 pb-0 mt-2">
              <Checkbox
                :binary="true"
                inputId="multilanguage"
                v-model="vmodel.docsetting.multilanguage"
              />
              <label for="multilanguage" class="ml-2"
                >Multilanguage support
              </label>
            </div>
          </div>
        </div>
        <div class="grid mx-0 flex flex-column mt-4">
          <div class="md:col-4 col-12">
            <DropDownRequire
              :options="languageOption"
              id="defaultlanguage"
              :filter="true"
              placeholder="Select Default Language for PDFs"
              v-model="vmodel.docsetting.defaultlanguage"
              :validation="v$.vmodel.docsetting.defaultlanguage"
              label="Default Language for PDFs"
              optionLabel="name"
              optionValue="code"
            />
          </div>
        </div>
        <div class="grid mx-0">
          <div class="md:col-4 sm:col-12 col-12 flex flex-column gap-2">
            <label class="form-label"> Event Type </label>
            <MultiSelect
              v-model="vmodel.eventtypes"
              display="chip"
              :options="eventtypesOption"
              optionLabel="name"
              optionValue="name"
              placeholder="Select Event Type"
              :maxSelectedLabels="3"
            />
            <span
              v-if="
                v$.vmodel.eventtypes.$error &&
                v$.vmodel.eventtypes.required.$invalid
              "
              id="eventtypes-help"
              class="p-error text-left"
            >
              Event Type is required
            </span>
          </div>
        </div>
        <div class="grid mx-0 mt-4">
          <div class="col-12 flex set-button">
            <PrimeButton
              label="Add"
              class="order-form-button"
              v-if="!isEditMode"
              @click="createCompany"
            >
            </PrimeButton>
            <PrimeButton
              label="Edit"
              class="order-form-button"
              v-else
              @click="createCompany"
            >
            </PrimeButton>
            <PrimeButton
              label="Cancel"
              class="form-cancel-button sm:ml-2"
              @click="onCancel"
            ></PrimeButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/api-service.js";
import DateFormat from "@/libs/DateFormat";
import getPassword from "@/libs/getPassword";
import { useVuelidate } from "@vuelidate/core";
import {
  maxLength,
  required,
  email,
  minLength,
  minValue,
  requiredIf,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      submitted: false,
      isEditMode: false,
      subtitle: "Add Company",
      minEventDate: new Date(),
      pic: "",
      otherpic: "",
      languageOption: [],
      eventtypesOption: [],
      vmodel: {
        pic: "",
        otherpic: "",
        companyname: "",
        email: "",
        password: "",
        contactperson: "",
        contactno: "",
        domainname: "",
        startdate: "",
        enddate: "",
        address: "",
        website: "",
        companyemail: "",
        numofCustomer: 0,
        numofUser: 0,
        numofRecipe: 0,
        eventtypes: [],
        docsetting: {
          showoneimage: true,
          showlogojustify: "center",
          showotherpicjustify: "",
          multilanguage: false,
          defaultlanguage: "en",
        },
        template: {
          main_menu: true,
          ing_list: true,
          cat_list: true,
          recipe_merge_pdf: true,
          cat_single_list: true,
          recipe_menu_submenu: true,
          recipe_menu_submenu_vendor: true,
          recipe_menu_submenu_ing: true,
          recipe_menu_submenu_ing_vendor: true,
          ing_recipe: true,
          ing_qty_recipe: true,
          ing_total_qty_recipe: true,
          ing_total_qty_cat: true,
          add_images: true,
        },
      },
    };
  },
  created() {
    let today = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0
    );
    this.minEventDate = today;
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (this.$route.params.id) {
      this.subtitle = "Update Company";
      this.isEditMode = true;
      this.getCompanyById();
    }
    this.getLanguageDrop();
    this.getEventtypes();
  },
  validations() {
    return {
      vmodel: {
        companyname: { required, max: maxLength(30) },
        email: { required, email, max: maxLength(75) },
        password: { required, min: minLength(8), max: maxLength(12) },
        contactno: {
          required,
          min: minLength(10),
          max: maxLength(30),
        },
        pic: {},
        docsetting: {
          showlogojustify: {
            required: requiredIf(function () {
              return this.pic !== "";
            }),
          },
          showotherpicjustify: {
            required: requiredIf(function () {
              return this.otherpic !== "";
            }),
          },
          defaultlanguage: { required },
        },
        contactperson: { required, max: maxLength(30) },
        domainname: { required, max: maxLength(30) },
        startdate: { required },
        enddate: { required },
        address: { required },
        website: {},
        companyemail: {},
        numofCustomer: { required, minValue: minValue(1) },
        numofUser: { required, minValue: minValue(1) },
        numofRecipe: { required, minValue: minValue(1) },
        eventtypes: { required },
      },
    };
  },
  methods: {
    addImages() {
      this.vmodel.template.add_images = true;
    },
    async getLanguageDrop() {
      this.$root.$globalState.loader = true;
      APIService.get("/language/dropdown")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.languageOption = response.data.data;
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    async getEventtypes() {
      this.$root.$globalState.loader = true;
      APIService.get("/eventtype/dropdown")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.eventtypesOption = response.data.data;
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        !((keyCode >= 48 && keyCode <= 57) || keyCode === 44 || keyCode === 32)
      ) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    onCancel() {
      this.$router.push({ name: "Company" });
    },
    openFileUpload() {
      this.$refs.photo.choose();
    },
    openotherpicUpload() {
      this.$refs.otherpic.choose();
    },
    async createCompany() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let fromDate = new Date(this.vmodel.startdate);
        let toDate = new Date(this.vmodel.enddate);
        let startdate = new Date(
          DateFormat.getLocalTimedate(
            new Date(
              fromDate.getFullYear(),
              fromDate.getMonth(),
              fromDate.getDate(),
              0,
              0,
              0
            )
          )
        );
        let enddate = new Date(
          DateFormat.getLocalTimedate(
            new Date(
              toDate.getFullYear(),
              toDate.getMonth(),
              toDate.getDate(),
              0,
              0,
              0
            )
          )
        );
        if (startdate > enddate) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "End date Must be greater than Startdate.",
            life: 3000,
          });
          return;
        }
        let password = getPassword.generatePassword(this.vmodel.password);
        let encodedPassword = encodeURIComponent(password);
        let payload = {
          companyname: this.vmodel.companyname,
          email: this.vmodel.email,
          password: encodedPassword,
          contactno: this.vmodel.contactno,
          contactperson: this.vmodel.contactperson,
          domainname: this.vmodel.domainname,
          startdate: DateFormat.formatDate(this.vmodel.startdate),
          enddate: DateFormat.formatDate(this.vmodel.enddate),
          pic: this.vmodel.pic,
          otherpic: this.vmodel.otherpic,
          numofCustomer: this.vmodel.numofCustomer,
          numofRecipe: this.vmodel.numofRecipe,
          numofUser: this.vmodel.numofUser,
          address: this.vmodel.address,
          website: this.vmodel.website,
          companyemail: this.vmodel.companyemail,
          template: this.vmodel.template,
          docsetting: this.vmodel.docsetting,
          eventtypes: this.vmodel.eventtypes,
        };
        this.submitted = true;
        this.$root.$globalState.loader = true;
        if (this.$route.params.id) {
          APIService.put("/company", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Company" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.$root.$globalState.loader = false;
              this.submitted = false;
            });
        } else {
          APIService.post("/company", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });

                setTimeout(() => {
                  this.$router.push({ name: "Company" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.$root.$globalState.loader = false;
              this.submitted = false;
            });
        }
      }
    },
    getCompanyById() {
      this.$root.$globalState.loader = true;
      APIService.getById("/company", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.companyname = response.data.data.companyname;
            this.vmodel.email = response.data.data.email;
            this.vmodel.contactperson = response.data.data.contactperson;
            this.vmodel.contactno = response.data.data.contactno;
            this.vmodel.domainname = response.data.data.domainname;
            this.vmodel.pic = response.data.data.pic;
            this.pic = response.data.data.picpath;
            this.vmodel.otherpic = response.data.data.otherpic;
            this.otherpic = response.data.data.otherpicpath;
            let password = decodeURIComponent(response.data.data.password);
            let decodedPassword = getPassword.getPassword(password);
            this.vmodel.password = decodedPassword;
            this.vmodel.startdate = new Date(
              DateFormat.formatDate(response.data.data.startdate)
            );
            this.vmodel.enddate = new Date(
              DateFormat.formatDate(response.data.data.enddate)
            );
            this.vmodel.numofCustomer = response.data.data.numofCustomer;
            this.vmodel.numofRecipe = response.data.data.numofRecipe;
            this.vmodel.numofUser = response.data.data.numofUser;
            this.vmodel.template = response.data.data.template;
            this.vmodel.docsetting = response.data.data.docsetting;
            this.vmodel.address = response.data.data.address;
            this.vmodel.website = response.data.data.website;
            this.vmodel.companyemail = response.data.data.companyemail;
            this.vmodel.eventtypes = response.data.data.eventtypes;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "Company" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
          this.$root.$globalState.loader = false;
        });
    },
    handleFileUpload(fileObj) {
      var file = this.$refs.photo.files[0];
      // const file = event.target.files[0];
      if (
        !fileObj ||
        (fileObj && !fileObj.files) ||
        (fileObj && fileObj.files && fileObj.files.length === 0)
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select valid image",
          life: 3000,
        });
        this.$refs.photo.clear();
        // this.$refs.file.value = null;
      } else if (fileObj && fileObj.files && fileObj.files[0].size > 5245329) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Image size should be less then 5 MB",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.pic = reader.result;
          this.vmodel.pic = reader.result;
        };
        this.$refs.photo.clear();
      }
    },
    handleotherpicUpload(fileObj) {
      var file = this.$refs.otherpic.files[0];
      // const file = event.target.files[0];
      if (
        !fileObj ||
        (fileObj && !fileObj.files) ||
        (fileObj && fileObj.files && fileObj.files.length === 0)
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select valid image",
          life: 3000,
        });
        this.$refs.otherpic.clear();
        // this.$refs.file.value = null;
      } else if (fileObj && fileObj.files && fileObj.files[0].size > 5245329) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Image size should be less then 5 MB",
          life: 3000,
        });
        this.$refs.otherpic.clear();
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.otherpic = reader.result;
          this.vmodel.otherpic = reader.result;
        };
        this.$refs.otherpic.clear();
      }
    },
  },
};
</script>
