<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="list-margin">
    <DataTable
      :value="invoice"
      class="p-datatable-lg p-datatable-customers"
      dataKey="id"
      :rowHover="true"
      :resizableColumns="false"
      @sort="onSort($event)"
    >
      <template #header>
        <div class="grid m-0">
          <div class="col-12 lg:col-6 lg:text-left text-center list-header">
            Invoices
          </div>
          <div class="col-12 lg:col-6 lg:text-right text-center">
            <PrimeButton
              label=" ADD "
              class="add-button mr-3"
              icon="plus-icon"
              @click="gotoCreateInvoice"
            ></PrimeButton>
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch" />
              <InputText
                v-model="search"
                placeholder="Search"
                class="search-input"
              />
            </div>
            <PrimeButton
              class="download-button ml-3"
              icon="pi pi-download"
              @click="downloadCSV"
              :disabled="invoice.length == 0"
            ></PrimeButton>
          </div>
        </div>
      </template>

      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <Column field="invoicenumber" header="Invoice Number" sortable></Column>
      <Column
        field="invoicedate"
        header="Invoice Date"
        sortable
        class="setdatewidth"
      ></Column>
      <Column
        field="duedate"
        header="Due Date"
        sortable
        class="setdatewidth"
      ></Column>
      <Column field="customer" header="Customer Name" sortable>
        <template #body="row">
          <div class="flex">
            <span class="margin-username">{{ row.data.customer }}</span>
          </div>
        </template>
      </Column>
      <Column field="event" header="Event Name" sortable>
        <template #body="row">
          <div class="flex">
            <span class="margin-username">{{ row.data.event }}</span>
          </div>
        </template>
      </Column>
      <Column field="totalamount" header="Total Amount" sortable></Column>
      <Column field="actions" header="Actions">
        <template #body="data">
          <div class="flex">
            <i
              class="pi pi-pencil cursor-pointer edit-icon"
              style="color: gray"
              @click="gotoInvoiceEdit(data.data.id)"
            ></i>
            <i
              class="pi pi-trash cursor-pointer delete-icon ml-1"
              style="color: #dc3545"
              @click="showDeletePopup(data.data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <Paginator
      v-model:first="currentPage"
      :rows="50"
      :totalRecords="totalRecords"
      :rowsPerPageOptions="[50, 100, 150, 200]"
      template="RowsPerPageDropdown CurrentPageReport PageLinks"
      currentPageReportTemplate="{first} - {last} of {totalRecords} items"
      @page="onPage($event)"
      :class="{
        loading: loading,
      }"
    >
      <template #start> Shown per page : </template>
    </Paginator>
    <ConfirmPopup
      :show-popup="showPopup"
      :header="header"
      :message="message"
      @confirmYes="deleteInvoice"
      @confirmNo="showPopup = false"
    ></ConfirmPopup>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import InvoiceFactory from "@/factories/invoice";
export default {
  data() {
    return {
      loading: false,
      invoice: [],
      currentPage: 0,
      itemsPerPage: 50,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      showPopup: false,
      invoiceIdForDelete: "",
      header: "Delete Confirmation",
      message: "Are you sure you want to delete all details from this invoice?",
    };
  },
  mounted() {
    this.getInvoiceList();
  },
  methods: {
    downloadCSV() {
      this.$root.$globalState.loader = true;
      APIService.get(`/invoice/downloadcsv`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data.file);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    gotoCreateInvoice() {
      this.$router.push({ name: "InvoiceCreate" });
    },

    gotoInvoiceEdit(rowId) {
      this.$router.push({
        name: "InvoiceEdit",
        params: { id: rowId },
      });
    },
    showDeletePopup(rowId) {
      this.invoiceIdForDelete = rowId;
      this.showPopup = true;
    },
    getInvoiceList() {
      this.$root.$globalState.loader = true;
      this.invoice = [];
      APIService.get(
        `/invoice/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${this.search}`
      )
        .then((response) => {
          if (response && response.data) {
            this.totalRecords = response.data.totalrecord;
            this.invoice = InvoiceFactory.createFromJsonArray(
              response.data.data
            );
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },

    deleteInvoice() {
      this.$root.$globalState.loader = true;
      APIService.delete(`/invoice/delete/${this.invoiceIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getInvoiceList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.showPopup = false;
        });
    },
    onPage(pageData) {
      this.itemsPerPage = pageData.rows;
      this.currentPage = pageData.first;
      this.getInvoiceList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getInvoiceList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getInvoiceList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
.margin-username {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
:deep(
    .p-datatable.p-datatable-customers .p-datatable-thead tr th.setdatewidth
  ) {
  min-width: 140px;
}
</style>
