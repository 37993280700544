<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="list-margin">
    <DataTable
      :value="eve"
      class="p-datatable-lg p-datatable-customers"
      dataKey="id"
      :rowHover="true"
      :resizableColumns="false"
      @sort="onSort($event)"
      v-model:selection="selectedEvent"
      scrollable
    >
      <template #header>
        <div class="grid m-0">
          <div class="lg:col-4 col-12 lg:text-left text-center list-header">
            Events
          </div>

          <div
            class="lg:col-8 col-12 grid lg:justify-content-end justify-content-center m-0"
          >
            <PrimeButton
              class="download-button mr-3"
              icon="pi pi-file-pdf"
              @click="downloadMergedPDF"
              v-if="selectedEvent && selectedEvent.length > 1"
            ></PrimeButton>
            <PrimeButton
              label=" ADD "
              class="add-button lg:col-2"
              icon="plus-icon"
              @click="gotoCreateMenu"
            ></PrimeButton>
            <Dropdown
              v-model="status"
              :options="statusoption"
              optionLabel="name"
              placeholder="Select a status"
              optionValue="name"
              label="Status"
              class="statusdropdown lg:col-2"
              @change="getData"
            />
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch" />
              <InputText
                placeholder="Search"
                class="search-input"
                v-model="search"
                @keyup="onSearch"
              />
            </div>
            <PrimeButton
              class="download-button ml-3"
              icon="pi pi-download"
              @click="downloadCSV"
              :disabled="eve.length == 0"
            ></PrimeButton>
          </div>
        </div>
      </template>

      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <Column
        selectionMode="multiple"
        headerStyle="width: 3rem"
        v-if="showMergedOption"
      ></Column>
      <Column field="eventname" header="Name" sortable>
        <template #body="row">
          <div class="flex">
            <span class="margin-username">{{ row.data.eventname }}</span>
          </div>
        </template>
      </Column>
      <Column field="eventtype" header="Event Type" sortable>
        <template #body="row">
          <div class="flex">
            <span class="margin-username">{{ row.data.eventtype }}</span>
          </div>
        </template>
      </Column>
      <Column field="customername" header="Customer" sortable>
        <template #body="row">
          <div class="flex">
            <span class="margin-username">{{ row.data.customername }}</span>
          </div>
        </template>
      </Column>
      <Column field="status" header="Status" sortable>
        <template #body="data">
          <Chip
            v-if="data.data.status == 'Confirm'"
            :label="data.data.status"
            class="chip-style-for-order-confirm"
          />
          <Chip
            v-if="data.data.status == 'Inquiry'"
            :label="data.data.status"
            class="chip-style-for-order-inquiry"
          />
          <Chip
            v-if="data.data.status == 'Cancel'"
            :label="data.data.status"
            class="chip-style-for-order-cancel"
          />
        </template>
      </Column>
      <Column
        field="eventdate"
        header="Date and Time"
        class="datetime"
        sortable
      ></Column>
      <Column field="report" header="Print" alignFrozen="right" frozen>
        <template #body="data">
          <div>
            <!-- <PrimeButton
              label="Menu With Ingrediants"
              class="print-menu"
              @click="generateReport(data.data.id)"
            ></PrimeButton>
            <PrimeButton
              label="Menu"
              class="print-menu mt-2"
              @click="generateReportMenu(data.data.id)"
            ></PrimeButton> -->
            <SplitButton
              menuButtonIcon="pi pi-download"
              :model="getRecipeMenuItems(data.data.id)"
              v-if="
                data.data.items.length > 0 &&
                templatePdfs &&
                data.data.hasRecipe
              "
            />
            <!-- :model="
                data.data.hasRecipe
                  ? getRecipeMenuItems(data.data.id)
                  : getSubMenuItems(data.data.id)
              " -->
            <!-- :disabled="selectedEvent && selectedEvent.length > 1" -->
          </div>
        </template>
      </Column>
      <Column field="actions" header="Actions" alignFrozen="right" frozen>
        <template #body="data">
          <div>
            <SplitButton
              menuButtonIcon="pi pi-ellipsis-v"
              :model="getActionArray(data.data.status, data.data.id)"
            />
          </div>
          <!-- <div class="flex">
            <i
              class="pi pi-pencil cursor-pointer"
              style="color: gray"
              @click="gotoEventEdit(data.data.id)"
            ></i>
            <i
              class="pi pi-trash cursor-pointer ml-3"
              style="color: #dc3545"
              @click="showDeletePopup(data.data.id)"
            />
          </div> -->
        </template>
      </Column>
    </DataTable>
    <Paginator
      v-model:first="currentPage"
      :rows="50"
      :totalRecords="totalRecords"
      :rowsPerPageOptions="[50, 100, 150, 200]"
      template="RowsPerPageDropdown CurrentPageReport PageLinks"
      currentPageReportTemplate="{first} - {last} of {totalRecords} items"
      @page="onPage($event)"
      :class="{
        loading: this.$root.$globalState.loader,
      }"
    >
      <template #start> Shown per page : </template>
    </Paginator>
    <div>
      <!-- <Dialog
        header="Convert Unit Type (PDF)"
        modal
        v-model:visible="display"
        :breakpoints="{ '960px': '75vw', '640px': '80vw' }"
        :style="{ width: '36vw' }"
        class="popup"
        :draggable="false"
        :dismissableMask="true"
        @hide="closePopup"
      >
        <div class="grid">
          <div class="col-6">
            <label>gram -> </label>
          </div>
          <div class="col-6"> -->
      <!-- <DropDownRequire
              :options="kgOption"
              id="country"
              :filter="true"
              placeholder="Select Country"
              v-model="vmodel.country"
              :validation="v$.vmodel.country"
              label="Country"
              optionLabel="name"
              optionValue="id"
              @update:modelValue="getStateByCountryId()"
            /> -->
      <!-- </div>
        </div>
        <div class="grid">
          <div class="col-6">
            <label>ml -> </label>
          </div>
          <div class="col-6"> -->
      <!-- <DropDownRequire
              :options="ltOption"
              id="country"
              :filter="true"
              placeholder="Select Country"
              v-model="vmodel.country"
              :validation="v$.vmodel.country"
              label="Country"
              optionLabel="name"
              optionValue="id"
              @update:modelValue="getStateByCountryId()"
            /> -->
      <!-- </div>
        </div>
        <div class="grid mx-0">
          <div class="col-12 flex set-button"> -->
      <!-- <PrimeButton
              label="Add"
              class="order-form-button"
              @click="createCategory"
            >
            </PrimeButton> -->
      <!-- </div>
        </div>
      </Dialog> -->
      <CategorySelectionPopup
        :show-popup="showCategoryMergedPopup"
        @displayNo="closeCategoryMergedPopup()"
        @displayYes="downloadSingleCatMergedPDF"
      ></CategorySelectionPopup>
      <CategorySelectionPopup
        :show-popup="showCategoryPopup"
        @displayNo="closeCategoryPopup()"
        @displayYes="downloadIngCatgSingleArihant"
      ></CategorySelectionPopup>
      <ConfirmPopup
        :show-popup="showPopup"
        :header="header"
        :message="message"
        @confirmYes="deleteEvent"
        @confirmNo="showPopup = false"
      >
      </ConfirmPopup>
    </div>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import EventFactory from "@/factories/event.js";
// import Dialog from "primevue/dialog";
// import Vue3Html2pdf from "vue3-html2pdf";
export default {
  components: {
    // Vue3Html2pdf,
    // Dialog,
  },
  data() {
    return {
      selectedEvent: null,
      selectedEventId: null,
      metaKey: true,
      display: true,
      closePopup: false,
      showCategoryPopup: false,
      showCategoryMergedPopup:false,
      pdf: [],
      statusoption: [
        { name: "All" },
        { name: "Inquiry" },
        { name: "Confirm" },
        { name: "Cancel" },
      ],
      eve: [],
      evelist: [],
      totalRecordsStore: 0,
      templatePdfs: {},
      status: "All",
      currentPage: 0,
      itemsPerPage: 50,
      search: "",
      searchText: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      showPopup: false,
      showMergedOption: false,
      eventIdForDelete: "",
      header: "Delete Confirmation",
      message: "Are you sure you want to delete all details from this event?",
    };
  },

  mounted() {
    this.checkMergedEvent();
    this.getEventList();
    this.getTemplatePdfsOptions();
  },
  methods: {
    async checkMergedEvent() {
      this.$root.$globalState.loader = true;
      APIService.get(`/setting`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            for (let option of response.data.data) {
              if (option.name == "mergedpdf") {
                this.showMergedOption = option.value;
              }
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    closeviewpdf() {
      this.pdfhtml = null;
    },
    downloadSinglePDF() {
      this.showCategoryMergedPopup = true;
    },
    downloadMergedPDF() {
      let events = this.selectedEvent.map((e) => e.id);
      let selectEvents = events.toString();
      this.$root.$globalState.loader = true;
      let payload = {
        eventIds: selectEvents,
      };
      APIService.post(`/event/eventmergedpdf`, payload)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.selectedEvent = null;
        });
    },
    downloadSingleCatMergedPDF(data) {
      let events = this.selectedEvent.map((e) => e.id);
      let selectEvents = events.toString();
      this.$root.$globalState.loader = true;
      let payload = {
        eventIds: selectEvents,
      };
      APIService.post(`/event/mergedpdf/${data}`, payload)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.selectedEvent = null;
        });
    },
    downloadCSV() {
      this.$root.$globalState.loader = true;
      APIService.get(`/event/downloadcsv/${this.status}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data.file);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    getActionArray(status, id) {
      let array = [
        {
          label: "Assign Employees",
          icon: "pi pi-arrow-up-left",
          command: () => {
            this.gotoEmployeeAssign(id);
          },
          disabled: status !== "Confirm",
        },
        {
          label: "Copy",
          icon: "pi pi-copy",
          command: () => {
            this.gotoEventCopy(id);
          },
        },
        {
          label: "Edit",
          icon: "pi pi-pencil",
          command: () => {
            this.gotoEventEdit(id);
          },
        },
        {
          label: "Delete",
          icon: "pi pi-trash",
          command: () => {
            this.showDeletePopup(id);
          },
        },
      ];

      return array;
    },
    getSubMenuItems(id) {
      let array = [
        {
          label: "Download PDF - Submenu with menu",
          command: () => {
            this.generateReportMenu(id);
          },
        },
        {
          label: "Download PDF - Submenu with menu, vendor",
          command: () => {
            this.downloadVendorfile(id);
          },
        },
      ];

      return array;
    },
    getRecipeMenuItems(id) {
      let array = [];
      let object = {};
      if (this.templatePdfs) {
        if (this.templatePdfs.main_menu) {
          object = {
            label: "Download PDF - Main Menu - Recipe",
            command: () => {
              this.generateReportMenuArihant(id);
            },
          };

          array.push(object);
        }

        if (this.templatePdfs.ing_list) {
          object = {
            label: "Download PDF - Ingredient List with Menu",
            command: () => {
              this.generateReportIngArihant(id);
            },
          };

          array.push(object);
        }

        if (this.templatePdfs.cat_list) {
          object = {
            label: "Download PDF - Ingredient List with Category",
            command: () => {
              this.generateReportIngCatgArihant(id);
            },
          };

          array.push(object);
        }

        if (this.templatePdfs.recipe_merge_pdf) {
          object = {
            label: "Download PDF - Merge PDF - Recipe",
            command: () => {
              this.generateReportRecipeMergeArihant(id);
            },
          };

          array.push(object);
        }

        if (this.templatePdfs.cat_single_list) {
          object = {
            label: "Download PDF - Ingredient List with Single-Category",
            command: () => {
              this.generateReportIngCatgSingleArihant(id);
            },
          };

          array.push(object);
        }

        if (this.templatePdfs.recipe_menu_submenu) {
          object = {
            label: "Download PDF - Recipe List with Menu and Submenu",
            command: () => {
              this.generateReportMenu(id);
            },
          };

          array.push(object);
        }

        if (this.templatePdfs.recipe_menu_submenu_vendor) {
          object = {
            label: "Download PDF - Recipe List with Menu, Submenu and Vendor",
            command: () => {
              this.downloadVendorfile(id);
            },
          };

          array.push(object);
        }

        if (this.templatePdfs.recipe_menu_submenu_ing) {
          object = {
            label:
              "Download PDF - Recipe List with Menu, Submenu and Ingredient",
            command: () => {
              this.downloadIngfile(id);
            },
          };

          array.push(object);
        }

        if (this.templatePdfs.recipe_menu_submenu_ing_vendor) {
          object = {
            label:
              "Download PDF - Recipe List With Menu, Submenu, Ingredient and Vendor",
            command: () => {
              this.downloadIngVendorfile(id);
            },
          };

          array.push(object);
        }

        if (this.templatePdfs.ing_recipe) {
          object = {
            label: "Download PDF - Ingredient List with Recipe",
            command: () => {
              this.downloadOnlyIngfile(id);
            },
          };

          array.push(object);
        }

        if (this.templatePdfs.ing_qty_recipe) {
          object = {
            label: "Download PDF - Ingredient List - QTY with Recipe",
            command: () => {
              this.downloadIngIngQtyfile(id);
            },
          };

          array.push(object);
        }

        if (this.templatePdfs.ing_total_qty_recipe) {
          object = {
            label: "Download PDF - Ingredient List - Total QTY with Recipe",
            command: () => {
              this.downloadIngIngTotalQtyfile(id);
            },
          };

          array.push(object);
        }

        if (this.templatePdfs.ing_total_qty_cat) {
          object = {
            label: "Download PDF - Ingredient List - Total QTY with Category",
            command: () => {
              this.downloadIngIngTotalQtyCategoryfile(id);
            },
          };

          array.push(object);
        }
      }
      return array;
    },
    gotoCreateMenu() {
      this.$router.push({ name: "EveCreate" });
    },
    gotoEmployeeAssign(eventId) {
      this.$router.push({
        name: "EmployeeAssign",
        params: { id: eventId },
      });
    },
    gotoEventCopy(eventId) {
      this.$router.push({
        name: "EveCopy",
        params: { id: eventId },
      });
    },
    gotoEventEdit(eventId) {
      this.$router.push({
        name: "EveEdit",
        params: { id: eventId },
      });
    },
    showDeletePopup(eventId) {
      this.eventIdForDelete = eventId;
      this.showPopup = true;
    },
    generateReportMenuArihant(eventId) {
      // this.pdf = [];
      this.$root.$globalState.loader = true;
      APIService.getById(`/event/eventrecipelistmenuarihant/${eventId}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    generateReportIngArihant(eventId) {
      // this.pdf = [];
      this.$root.$globalState.loader = true;
      APIService.getById(`/event/eventrecipelistingarihant/${eventId}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    generateReportIngCatgArihant(eventId) {
      // this.pdf = [];
      this.$root.$globalState.loader = true;
      APIService.getById(`/event/eventrecipelistingcatgarihant/${eventId}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    generateReportRecipeMergeArihant(eventId) {
      // this.pdf = [];
      this.$root.$globalState.loader = true;
      APIService.getById(`/event/eventrecipemergearihant/${eventId}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    closeCategoryPopup() {
      this.showCategoryPopup = false;
    },
    closeCategoryMergedPopup() {
      this.showCategoryMergedPopup = false;
    },
    generateReportIngCatgSingleArihant(eventid) {
      this.showCategoryPopup = true;
      this.selectedEventId = eventid;
      // this.pdf = [];
    },
    downloadIngCatgSingleArihant(data) {
      this.$root.$globalState.loader = true;
      APIService.getById(
        `/event/eventrecipelistingcatgsinglearihant/${this.selectedEventId}/${data}`
      )
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.selectedEventId = null;
        });
    },
    generateReportMenu(eventId) {
      // this.pdf = [];
      this.$root.$globalState.loader = true;
      APIService.getById(`/event/eventrecipelistmenu/${eventId}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    downloadVendorfile(eventId) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      this.showPopup = false;
      APIService.get(`/event/eventrecipelistmenuvendor/${eventId}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    downloadOnlyIngfile(eventId) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      this.showPopup = false;
      APIService.get(`/event/eventrecipelistingtypes/${eventId}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    downloadIngIngQtyfile(eventId) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      this.showPopup = false;
      APIService.get(`/event/eventrecipelistingcolvalue/${eventId}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    downloadIngIngTotalQtyfile(eventId) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      this.showPopup = false;
      APIService.get(`/event/eventrecipelistingtotalvalue/${eventId}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    downloadIngIngTotalQtyCategoryfile(eventId) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      this.showPopup = false;
      APIService.get(`/event/eventrecipelistingcategorytotal/${eventId}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    downloadIngfile(eventId) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      this.showPopup = false;
      APIService.get(`/event/eventrecipelisting/${eventId}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    downloadIngVendorfile(eventId) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      this.showPopup = false;
      APIService.get(`/event/eventrecipelistingvendor/${eventId}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    getTemplatePdfsOptions() {
      this.$root.$globalState.loader = true;
      this.templatePdfs = {};
      APIService.get(`/templatePdfs/dropdown`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.templatePdfs = response.data.data;
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    getEventList() {
      this.$root.$globalState.loader = true;
      this.eve = [];
      APIService.get(
        `/event/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${this.status}/${this.searchText}`
      )
        .then((response) => {
          if (response && response.data) {
            this.totalRecords = response.data.totalrecord;
            this.eve = EventFactory.createFromJsonArray(response.data.data);
            if (this.search.length == 0) {
              this.evelist = EventFactory.createFromJsonArray(
                response.data.data
              );
              this.totalRecordsStore = response.data.totalrecord;
            }
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    deleteEvent() {
      this.$root.$globalState.loader = true;
      APIService.delete(`/event/delete/${this.eventIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getEventList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.showPopup = false;
        });
    },

    onPage(pageData) {
      this.itemsPerPage = pageData.rows;
      this.currentPage = pageData.first;
      this.getEventList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getEventList();
    },
    onSearch() {
      // this.search = this.search.trimStart().trimEnd();
      // this.currentPage = 0;
      // this.getEventList();
      let search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      if (search.length >= 2) {
        this.searchText = this.search.trimStart().trimEnd();
        this.getEventList();
      } else if (search.length == 0) {
        this.searchText = this.search.trimStart().trimEnd();
        this.getEventList();
      } else if (search.length < 2) {
        this.searchText = "";
        this.eve = this.evelist;
        this.totalRecords = this.totalRecordsStore;
      }
    },
    getData() {
      this.currentPage = 0;
      this.getEventList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
@media (min-width: 991.98px) AND (max-width: 1315.98px) {
  .template-view.expand .p-input-icon-right {
    margin-top: 12px;
  }
}
@media (min-width: 991.98px) AND (max-width: 1035.98px) {
  .template-view.not-expand .p-input-icon-right {
    margin-top: 12px;
  }
}
// @media (max-width: 445.98px) {
//   .add-button {
//     margin-right: 0 !important;
//   }
// }
@media (max-width: 991.98px) {
  .add-button {
    margin-right: 12px;
  }
}
@media (max-width: 413.98px) {
  .btn-filter {
    margin-top: 12px;
  }
}
@media (max-width: 363.98px) {
  .btn-filter {
    margin-top: 12px;
    margin-right: 0 !important;
  }
  :deep(.statusdropdown.p-dropdown) {
    margin-top: 12px;
    margin-right: 12px !important;
  }
}
@media (max-width: 495.98px) {
  ::v-deep(.p-datatable.p-datatable-customers .add-button) {
    margin-bottom: 0px;
  }
}
@media (max-width: 626.98px) {
  .p-input-icon-right {
    margin-top: 12px;
  }
}
.btn-filter {
  margin-right: 12px;
  width: 120px;
  justify-content: center;
  align-items: center;
  padding: 12px 24px 12px 16px;
  height: 48px;
  background: #ff9f43;
  border-radius: 8px;
  border-color: #ff9f43;

  .btn-filter-label {
    height: 24px;

    font-family: OpenSans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    letter-spacing: -0.02em;
    color: #ffffff;
    flex: none;
  }
}
.btn-filter:hover {
  background: #ff9f43 !important;
  border-radius: 8px;
  border-color: #ff9f43 !important;
}
:deep(.statusdropdown.p-dropdown) {
  margin-right: 12px;
  width: 120px;
}
.add-button {
  width: 120px;
  margin-right: 1rem;
}
:deep(.p-datatable.p-datatable-customers .p-datatable-thead tr th.datetime) {
  min-width: 200px;
}
:deep(.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td) {
  padding: 1rem 1rem !important;
  border: none !important;
  border-width: 0px !important;
}
:deep(.p-datatable-scrollable .p-frozen-column) {
  background: initial !important;
}
.margin-username {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
