export default class EmployeeAssign {
  constructor(data = {}) {
    return {
      id: data.id || "",
      firstname: data.firstname || "",
      lastname: data.lastname || "",
      role: data.role || "",
      assign: data.assign || "",
      available: data.available,
    };
  }
}
