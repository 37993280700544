<template>
  <Dialog
    v-model:visible="display"
    modal
    :draggable="false"
    :header="header"
    :style="{ width: '50vw' }"
    @hide="closePopup"
  >
    <p>
      {{ message }}
    </p>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";

export default {
  emits: ["displayNo"],
  components: {
    Dialog,
  },
  data() {
    return {
      display: false,
    };
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
      required: true,
    },
    message: {
      type: String,
      default: "Do you want to delete this record?",
    },
    header: {
      type: String,
      default: "Delete Confirmation",
    },
  },
  watch: {
    showPopup: {
      handler(newValue) {
        if (newValue === true) {
          this.openPopup();
          document.querySelector("body").style.overflow = "hidden";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    openPopup() {
      this.display = true;
    },
    closePopup() {
      this.display = false;
      this.$emit("displayNo");
      document.querySelector("body").style.overflow = "auto";
    },
  },
};
</script>
