import Employee from "@/models/employee";

export default class EmployeeFactory {
  static createFromJson(json) {
    return new Employee(json);
  }
  static createFromJsonArray(jsonArray) {
    const jsonData = [];
    jsonArray.forEach((item) => {
      jsonData.push(EmployeeFactory.createFromJson(item));
    });

    return jsonData;
  }
}
