<template>
  <div class="ml-5 mr-5 set-margin-top mb-5">
    <div class="grid">
      <div class="grid">
        <div class="order-form-title w-full">Templates</div>
        <div class="order-form-sub-title">{{ subtitle }}</div>
      </div>
      <div class="order-card w-full">
        <div class="grid col-12">
          <div
            class="md:col-4 sm:col-6 col-12 text-center"
            v-for="(template, index) in templates"
            :id="index"
            :key="index"
            @click="changeTemplate(template.id)"
          >
            <img
              :src="template.coverImage"
              class="img-style"
              :class="selectedTemplate == template.id ? 'selectedTemplate' : ''"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/api-service.js";
export default {
  data() {
    return {
      subtitle: "Update Template",
      templates: [],
      loading: true,
      selectedTemplate: "",
    };
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    this.getTemplates();
    this.getSelectedTemplate();
  },
  methods: {
    async getTemplates() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      await APIService.get("/template/getAllTemplates")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.templates = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    async changeTemplate(templateId) {
      let payload = {
        template: templateId,
      };
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      await APIService.put("/templatesetting", payload)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "Template" });
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.getSelectedTemplate();
          this.$root.$globalState.loader = false;
        });
    },
    async getSelectedTemplate() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      await APIService.get("/templatesetting")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.selectedTemplate = response.data.data.template;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "Template" });
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.img-style {
  height: 400px;
  width: 100%;
  box-shadow: 0px 4px 12px rgba(187, 187, 187, 0.25);
}
.selectedTemplate {
  padding: 10px;
  border: 2px solid #000;
}
</style>
