<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="grid main-container justify-content-center align-items-center">
    <!-- <div class="login-logo">
      <img src="../assets/icons/cat-icon.png" />
    </div> -->
    <div class="grid form-container">
      <div class="form-title">Login</div>
      <div class="form-inputs">
        <div class="field mt-3">
          <label for="email" class="form-label">Email</label>
          <InputText
            id="email"
            type="email"
            placeholder="Email"
            v-model="vmodel.email"
            class="w-full"
            maxLength="75"
            :class="{
              'p-invalid': v$.vmodel.email.$error,
            }"
          />
          <span
            v-if="v$.vmodel.email.$error && v$.vmodel.email.required.$invalid"
            id="email-help"
            class="p-error text-left mt-2"
          >
            {{ v$.vmodel.email.required.$message.replace("Value", "Email") }}
          </span>
          <span
            v-else-if="v$.vmodel.email.$error && v$.vmodel.email.email.$invalid"
            id="email-help"
            class="p-error text-left mt-2"
          >
            {{ v$.vmodel.email.email.$message.replace("Value", "Email") }}
          </span>
          <span
            v-else-if="v$.vmodel.email.$error && v$.vmodel.email.max.$invalid"
            id="email-help"
            class="p-error text-left mt-2"
          >
            {{ v$.vmodel.email.max.$message }}
          </span>
        </div>
        <div class="field mt-1">
          <label for="password" class="form-label float-left">Password</label>
          <span class="p-input-icon-right">
            <i
              :class="
                showPassword
                  ? 'password-hide-icon cursor-pointer'
                  : 'eye-icon cursor-pointer'
              "
              @click="showPassword = !showPassword"
            />
            <InputText
              id="password"
              placeholder="Password"
              :type="passwordInputType"
              class="w-full"
              v-model="vmodel.password"
              :class="{
                'p-invalid': v$.vmodel.password.$error,
              }"
              aria-describedby="password-help"
              v-on:keyup.enter="login"
            />
          </span>
          <span
            v-if="
              v$.vmodel.password.$error && v$.vmodel.password.required.$invalid
            "
            id="password-help"
            class="p-error mt-2"
          >
            {{
              v$.vmodel.password.required.$message.replace("Value", "Password")
            }}
          </span>
          <span
            v-else-if="
              v$.vmodel.password.$error && v$.vmodel.password.min.$invalid
            "
            id="password-help"
            class="p-error mt-2"
          >
            {{
              v$.vmodel.password.min.$message
            }}
          </span>
          <span
            v-else-if="
              v$.vmodel.password.$error && v$.vmodel.password.max.$invalid
            "
            id="password-help"
            class="p-error mt-2"
          >
            {{ v$.vmodel.password.max.$message }}
          </span>
        </div>
        <div class="field mt-5 mb-2">
          <PrimeButton
            label="Login"
            class="btn-login"
            @click="login"
          ></PrimeButton>
        </div>
      </div>
    </div>
    <PrimeToast />
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import getPassword from "@/libs/getPassword";
import APIService from "@/services/api-service";
import { required, email, minLength, maxLength } from "@vuelidate/validators";
var match = process.env.VUE_APP_USERNAME;

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      showPassword: false,
      companyname: "",
      vmodel: {
        email: null,
        password: null,
      },
      submitted: false,
    };
  },
  validations() {
    return {
      vmodel: {
        email: { required, email, max: maxLength(75) },
        password: { required, min: minLength(8), max: maxLength(20) },
      },
    };
  },
  computed: {
    passwordInputType() {
      if (this.showPassword) {
        return "text";
      }
      return "password";
    },
  },
  mounted() {
    let url = window.location.host;
    let company = url.split(".");
    this.companyname = company[0];
  },
  methods: {
    async login() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        this.$root.$globalState.loader = true;
        let password = getPassword.generatePassword(this.vmodel.password);
        let encodedPassword = encodeURIComponent(password);
        let payload;
        if (match == this.vmodel.email) {
          payload = {
            email: this.vmodel.email,
            password: encodedPassword,
            companyname: "",
          };
        } else {
          payload = {
            email: this.vmodel.email,
            password: encodedPassword,
            // companyname: "cater",
            // companyname: "jms",
            companyname: this.companyname,
          };
        }
        this.submitted = true;
        APIService.post("/company/login", payload)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.$store.commit("setUserInfo", response.data.data);
              this.$store.commit("setUserToken", response.data.token);
              this.$store.dispatch("login");
              if (match == response.data.data.email) {
                this.$router.push({ name: "Company" });
              } else {
                this.$router.push({ name: "Dashboard" });
              }
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
            this.$root.$globalState.loader = false;
          });
      }
    },
  },
};
</script>

<style>
@import "@/assets/css/login.css";
</style>
