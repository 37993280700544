<template>
  <Dialog
    header="Add Customer"
    modal
    v-model:visible="display"
    :breakpoints="{ '960px': '75vw', '640px': '80vw' }"
    :style="{ width: '50vw' }"
    class="popup"
    :class="{
      blur: this.$root.$globalState.loader,
    }"
    :draggable="false"
    :dismissableMask="true"
    @hide="closePopup"
  >
    <div class="grid">
      <div class="md:col-4 col-12">
        <InputTextRequire
          type="text"
          v-model="vmodel.name"
          :validation="v$.vmodel.name"
          class="w-full"
          id="name"
          placeholder="Enter Name"
          label="Name"
        />
      </div>
      <div class="md:col-4 col-12">
        <InputTextRequire
          type="text"
          v-model="vmodel.email"
          :validation="v$.vmodel.email"
          class="w-full"
          id="email"
          label="Email"
          placeholder="Enter Email"
          maxLength="75"
        />
      </div>
      <div class="md:col-4 col-12">
        <InputTextRequire
          type="text"
          v-model="vmodel.contactno"
          :validation="v$.vmodel.contactno"
          id="contactno"
          class="w-full"
          label="Contact Number"
          placeholder="Enter Contact Number"
          maxlength="10"
          minlength="10"
          labelnotshow="false"
          @keypress="onlyNumber"
        />
      </div>
    </div>
    <div class="grid">
      <div class="md:col-4 col-12">
        <InputTextRequire
          type="text"
          v-model="vmodel.address1"
          :validation="v$.vmodel.address1"
          id="address1"
          class="w-full"
          placeholder="Enter Address - 1"
          label="Address - 1"
        />
      </div>
      <div class="md:col-4 col-12">
        <InputTextRequire
          type="text"
          v-model="vmodel.address2"
          :validation="v$.vmodel.address2"
          id="address2"
          class="w-full"
          placeholder="Enter Address - 2"
          label="Address - 2"
        />
      </div>
      <div class="md:col-4 col-12">
        <InputTextRequire
          type="text"
          v-model="vmodel.zip"
          :validation="v$.vmodel.zip"
          class="w-full"
          id="zipcode"
          placeholder="Enter Zip Code"
          label="Zip Code"
          maxlength="6"
          minlength="6"
          @keypress="onlyNumber"
        />
      </div>
    </div>
    <div class="grid">
      <div class="md:col-4 col-12">
        <DropDownRequire
          :options="countryOption"
          id="country"
          :filter="true"
          placeholder="Select Country"
          v-model="vmodel.country"
          :validation="v$.vmodel.country"
          label="Country"
          optionLabel="name"
          optionValue="id"
          @update:modelValue="getStateByCountryId()"
        />
      </div>
      <div class="md:col-4 col-12">
        <DropDownRequire
          :options="stateOption"
          v-model="vmodel.state"
          :validation="v$.vmodel.state"
          :filter="true"
          id="state"
          placeholder="Select State"
          label="State"
          optionLabel="name"
          optionValue="id"
          @update:modelValue="getCityByStateId()"
        />
      </div>
      <div class="md:col-4 col-12">
        <DropDownRequire
          v-model="vmodel.city"
          :validation="v$.vmodel.city"
          :options="cityOption"
          :filter="true"
          id="city"
          placeholder="Select City"
          label="City"
          optionLabel="name"
          optionValue="id"
        />
      </div>
    </div>
    <div class="grid mx-0">
      <div class="col-12 flex set-button">
        <PrimeButton
          label="Add"
          class="order-form-button"
          @click="createVendor"
        >
        </PrimeButton>
      </div>
    </div>
  </Dialog>
</template>
<script>
import Dialog from "primevue/dialog";
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  maxLength,
  email,
  minLength,
  numeric,
} from "@vuelidate/validators";

export default {
  emits: ["displayNo","displayYes"],
  components: {
    Dialog,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      display: false,
      submitted: false,
      countryOption: [],
      stateOption: [],
      cityOption: [],
      vmodel: {
        name: "",
        address1: "",
        address2: "",
        zip: "",
        email: "",
        contactno: "",
        country: "",
        state: "",
        city: "",
      },
    };
  },
  validations() {
    if (this.display) {
      return {
        vmodel: {
          name: { required, max: maxLength(30) },
          email: { email, max: maxLength(75) },
          address1: { required, max: maxLength(50) },
          address2: { max: maxLength(50) },
          zip: { max: maxLength(6), min: minLength(6) },
          contactno: {
            required,
            numeric,
            min: minLength(10),
            max: maxLength(10),
          },
          country: {},
          state: {},
          city: {},
        },
      };
    } else {
      return {
        vmodel: {
          name: {},
          email: {},
          address1: {},
          address2: {},
          zip: {},
          contactno: {},
          country: {},
          state: {},
          city: {},
        },
      };
    }
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  watch: {
    showPopup: {
      async handler(newValue) {
        if (newValue === true) {
          await this.v$.$reset();
          await this.openPopup();
          await this.getCountry();
          document.querySelector("body").style.overflow = "hidden";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    async getStateByCountryId() {
      this.$root.$globalState.loader = true;
      this.stateOption = [];
      await APIService.getById("/state/bycountryid", this.vmodel.country)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.stateOption = response.data.data;
          }
        })
        .finally(() => {
          this.submitted = false;
          this.$root.$globalState.loader = false;
        });
    },
    async getCityByStateId() {
      this.$root.$globalState.loader = true;
      this.cityOption = [];
      await APIService.getById("/city/bystateid", this.vmodel.state)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.cityOption = response.data.data;
          }
        })
        .finally(() => {
          this.submitted = false;
          this.$root.$globalState.loader = false;
        });
    },
    async getCountry() {
      this.$root.$globalState.loader = true;
      await APIService.get("/country")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.countryOption = response.data.data;
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    async createVendor() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        this.$root.$globalState.loader = true;
        let payload = {
          name: this.vmodel.name,
          address1: this.vmodel.address1,
          address2: this.vmodel.address2,
          zip: this.vmodel.zip,
          country: this.vmodel.country,
          state: this.vmodel.state,
          email: this.vmodel.email,
          city: this.vmodel.city,
          contactno: this.vmodel.contactno,
        };
        this.submitted = true;
        await APIService.post("/customer", payload)
          .then(async (response) => {
            if (response && response.data && response.data.success) {
              this.$emit("displayYes", response.data.data.id);
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
              this.display = false;
              await this.v$.$reset();
              this.vmodel.name = "";
              this.vmodel.address1 = "";
              this.vmodel.address2 = "";
              this.vmodel.zip = "";
              this.vmodel.country = "";
              this.vmodel.state = "";
              this.vmodel.email = "";
              this.vmodel.city = "";
              this.vmodel.contactno = "";
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
            this.$root.$globalState.loader = false;
          });
      }
    },
    openPopup() {
      this.display = true;
    },
    async closePopup() {
      this.display = false;
      await this.$emit("displayNo");
      await this.v$.$reset();
      this.vmodel.name = "";
      this.vmodel.address1 = "";
      this.vmodel.address2 = "";
      this.vmodel.zip = "";
      this.vmodel.country = "";
      this.vmodel.state = "";
      this.vmodel.email = "";
      this.vmodel.city = "";
      this.vmodel.contactno = "";
      document.querySelector("body").style.overflow = "auto";
    },
  },
};
</script>
