import moment from "moment";

export default {
  getLocalTimedate(value) {
    let newDate = new Date(value);
    newDate = newDate.setTime(
      newDate.getTime() + newDate.getTimezoneOffset() * 60000 * -1
    );
    return newDate;
  },

  formatDate(data, invalid = null) {
    if (!data) {
      return invalid;
    }

    return moment(data)
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format("YYYY-MM-DD");
  },

  formatDateTime(data, invalid = null) {
    if (!data) {
      return invalid;
    }

    return moment(data)
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format("YYYY-MM-DD HH:mm");
  },

  formatTime(data, invalid = null) {
    if (!data) {
      return invalid;
    }

    const formatvalue = moment(data)
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format("HH:mm a");
    if (formatvalue !== "Invalid date") {
      return formatvalue;
    }
    return data;
  },

  setTime(data, invalid = null) {
    if (!data) {
      return invalid;
    }

    const formatvalue = moment(data)
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format("HH:mm");
    if (formatvalue !== "Invalid date") {
      return formatvalue;
    }
    return data;
  },
};
