<template>
  <Dialog
    header="Add Raw Material / Ingredients"
    modal
    v-model:visible="display"
    :breakpoints="{ '960px': '75vw', '640px': '80vw' }"
    :style="{ width: '50vw' }"
    class="popup"
    :class="{
      blur: this.$root.$globalState.loader,
    }"
    :draggable="false"
    :dismissableMask="true"
    @hide="closePopup"
  >
    <div class="grid">
      <div class="md:col-4 col-12">
        <InputTextRequire
          type="text"
          v-model="vmodel.name"
          id="name"
          :validation="v$.vmodel.name"
          class="w-full"
          label="Name"
          placeholder="Enter Name"
        />
      </div>
      <div class="md:col-3 sm:col-10 col-12">
        <DropDownRequire
          type="text"
          v-model="vmodel.category"
          :validation="v$.vmodel.category"
          :options="categoryoption"
          id="category"
          :filter="true"
          class="w-full"
          label="Main Category"
          placeholder="Select Main Category"
          optionLabel="name"
          optionValue="id"
        />
      </div>
      <div class="md:col-1 sm:col-2 col-3 sm:mb-0 mb-2">
        <PrimeButton
          class="download-button mt-4 w-full"
          icon="pi pi-plus"
          @click="showCategoryPopup = true"
        ></PrimeButton>
      </div>
      <div class="md:col-4 col-12">
        <InputNumberRequire
          label="Price"
          v-model="vmodel.price"
          :validation="v$.vmodel.price"
          class="w-full"
          id="price"
          placeholder="Enter Price"
          :max="9999999"
        />
      </div>
    </div>
    <div class="grid">
      <!-- <div class="md:col-4 col-12">
        <InputNumberRequire
          label="Price"
          v-model="vmodel.price"
          :validation="v$.vmodel.price"
          class="w-full"
          id="price"
          placeholder="Enter Price"
          :max="9999999"
        />
      </div> -->
      <!-- <div class="md:col-4 col-12">
        <InputNumberRequire
          type="qty"
          label="Quantity"
          v-model="vmodel.qty"
          id="qty"
          :validation="v$.vmodel.qty"
          class="w-full"
          placeholder="Enter Quantity"
        />
      </div> -->
      <div class="md:col-4 col-12">
        <DropDownRequire
          type="text"
          v-model="vmodel.qtytype"
          :validation="v$.vmodel.qtytype"
          :options="unitoption"
          id="qtytype"
          :filter="true"
          class="w-full"
          label="Unit Type"
          placeholder="Select Unit Type"
          optionLabel="name"
          optionValue="id"
        />
      </div>
      <div class="md:col-4 col-12">
        <DropDownRequire
          type="text"
          v-model="vmodel.type"
          :validation="v$.vmodel.type"
          :options="typeoption"
          id="type"
          :filter="true"
          class="w-full"
          label="Type"
          placeholder="Select Type"
          optionLabel="text"
          optionValue="value"
        />
      </div>
      <div class="md:col-3 sm:col-10 col-9">
        <DropDownRequire
          :options="voption"
          :filter="true"
          id="purchasefrom"
          label="Purchase From"
          placeholder="Select Vendor"
          v-model="vmodel.purchasefrom"
          :validation="v$.vmodel.purchasefrom"
          optionLabel="name"
          optionValue="id"
        />
      </div>
      <div class="md:col-1 sm:col-2 col-3 sm:mb-0 mb-2">
        <PrimeButton
          class="download-button mt-4 w-full"
          icon="pi pi-plus"
          @click="showVendorPopup = true"
        ></PrimeButton>
      </div>
    </div>
    <!-- <div class="grid">
      <div class="md:col-6 col-12">
        <DropDownRequire
          type="text"
          v-model="vmodel.type"
          :validation="v$.vmodel.type"
          :options="typeoption"
          id="type"
          :filter="true"
          class="w-full"
          label="Type"
          placeholder="Select Type"
          optionLabel="text"
          optionValue="value"
        />
      </div>
      <div class="md:col-5 sm:col-10 col-9">
        <DropDownRequire
          :options="voption"
          :filter="true"
          id="purchasefrom"
          label="Purchase From"
          placeholder="Select Vendor"
          v-model="vmodel.purchasefrom"
          :validation="v$.vmodel.purchasefrom"
          optionLabel="name"
          optionValue="id"
        />
      </div>
      <div class="md:col-1 sm:col-2 col-3 sm:mb-0 mb-2">
        <PrimeButton
          class="download-button mt-4 w-full"
          icon="pi pi-plus"
          @click="showVendorPopup = true"
        ></PrimeButton>
      </div>
    </div> -->
    <div class="grid mx-0">
      <div class="col-12 flex set-button">
        <PrimeButton
          label="Add"
          class="order-form-button"
          @click="createVendor"
        >
        </PrimeButton>
      </div>
    </div>
  </Dialog>
  <CategoryAddPopup
    :show-popup="showCategoryPopup"
    @displayNo="closeCategoryPopup()"
    @displayYes="checkCategory"
  ></CategoryAddPopup>
  <VendorAddPopup
    :show-popup="showVendorPopup"
    @displayNo="closeVendorPopup()"
    @displayYes="checkVendor"
  ></VendorAddPopup>
</template>
<script>
import Dialog from "primevue/dialog";
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  maxLength,
  numeric,
  maxValue,
  minValue,
} from "@vuelidate/validators";

export default {
  emits: ["displayNo","displayYes"],
  components: {
    Dialog,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      display: false,
      submitted: false,
      showCategoryPopup: false,
      showVendorPopup: false,
      typeoption: [
        {
          text: "Fixed",
          value: "fixed",
        },
        {
          text: "Usable",
          value: "usable",
        },
      ],
      categoryoption: [],
      unitoption: [],
      voption: [],
      vmodel: {
        name: "",
        type: "",
        qty: 1,
        qtytype: "",
        purchasefrom: "",
        price: 1,
        category: "",
      },
    };
  },
  validations() {
    if (this.display) {
      return {
        vmodel: {
          name: { required, max: maxLength(30) },
          type: {},
          purchasefrom: {},
          price: {
            required,
            numeric,
            min: minValue(1),
            max: maxValue(9999999),
          },
          qty: { required, numeric, min: minValue(1), max: maxValue(99999) },
          qtytype: { required },
          category: { required },
        },
      };
    } else {
      return {
        vmodel: {
          name: {},
          type: {},
          purchasefrom: {},
          price: {},
          qty: {},
          qtytype: {},
          category: {},
        },
      };
    }
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  watch: {
    showPopup: {
      async handler(newValue) {
        if (newValue === true) {
          await this.v$.$reset();
          await this.openPopup();
          await this.getCategory();
          await this.getVendor();
          await this.getUnit();
          document.querySelector("body").style.overflow = "hidden";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    closeCategoryPopup() {
      this.showCategoryPopup = false;
      this.display = true;
    },
    async checkCategory(data) {
      this.showCategoryPopup = false;
      this.vmodel.category = data;
      await this.getCategory();
    },
    closeVendorPopup() {
      this.showVendorPopup = false;
      this.display = true;
    },
    async checkVendor(data) {
      this.showVendorPopup = false;
      this.vmodel.purchasefrom = data;
      await this.getVendor();
    },
    getVendor() {
      this.$root.$globalState.loader = true;
      APIService.get("/vendor/vender")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.voption = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          document.querySelector("body").style.overflow = "hidden";
          this.submitted = false;
        });
    },
    getCategory() {
      this.$root.$globalState.loader = true;
      APIService.get("/category/dropdown")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.categoryoption = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          document.querySelector("body").style.overflow = "hidden";
          this.submitted = false;
        });
    },
    async getUnit() {
      this.$root.$globalState.loader = true;
      await APIService.get("/unit/dropdown")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.unitoption = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    async createVendor() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        this.$root.$globalState.loader = true;
        let qtytypedependentid = await this.unitoption
          .filter((x) => x.id === this.vmodel.qtytype)
          .map((data) => {
            return data.dependantid;
          })[0];
        let payload = {
          name: this.vmodel.name,
          type: this.vmodel.type,
          qty: this.vmodel.qty,
          qtytype: this.vmodel.qtytype,
          qtytypedependentid: qtytypedependentid,
          purchasefrom: this.vmodel.purchasefrom,
          price: this.vmodel.price,
          categoryid: this.vmodel.category,
        };
        this.submitted = true;
        await APIService.post("/rowmaterial", payload)
          .then(async (response) => {
            if (response && response.data && response.data.success) {
              this.$emit("displayYes", response.data.data.id);
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
              this.display = false;
              await this.v$.$reset();
              this.vmodel.name = "";
              this.vmodel.type = "";
              this.vmodel.qty = 1;
              this.vmodel.qtytype = "";
              this.vmodel.purchasefrom = "";
              this.vmodel.price = 1;
              this.vmodel.category = "";
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
            this.$root.$globalState.loader = false;
          });
      }
    },
    openPopup() {
      this.display = true;
    },
    async closePopup() {
      this.display = false;
      await this.$emit("displayNo");
      await this.v$.$reset();
      this.vmodel.name = "";
      this.vmodel.type = "";
      this.vmodel.qty = 1;
      this.vmodel.qtytype = "";
      this.vmodel.purchasefrom = "";
      this.vmodel.price = 1;
      this.vmodel.category = "";
      document.querySelector("body").style.overflow = "auto";
    },
  },
};
</script>
