<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
     <ModalIdle v-if="isIdle" />
    <SidebarMenu
      :is_not_expand="is_not_expand"
      :widthExpand="widthExpand"
      @call="expand()"
      @callpage="expandPage()"
    />
    <div class="template-view" :class="is_not_expand ? 'not-expand' : 'expand'">
      <router-view></router-view>
    </div>
    <PrimeToast />
  </div>
</template>

<script>
import ModalIdle from "@/components/ModalIdle";
import SidebarMenu from "@/components/layout/sidebar-menu.vue";

export default {
  components: {
    SidebarMenu,
    ModalIdle
  },
  data() {
    return {
      is_not_expand: false,
      widthExpand: true,
    };
  },
  watch: {
    $route() {
      if (window.innerWidth < 992) {
        this.widthExpand = false;
      } else {
        this.widthExpand = true;
      }
      window.addEventListener("resize", this.myEventHandler);
    },
  },
  unmounted() {
    window.removeEventListener("resize", this.myEventHandler);
  },
   computed: {
    isIdle() {
     return this.$store.state.idleVue.isIdle;
    },
  },
  mounted() {
    if (window.innerWidth < 992) {
      this.widthExpand = false;
    } else {
      this.widthExpand = true;
    }
    window.addEventListener("resize", this.myEventHandler);
  },
  methods: {
    expand() {
      this.is_not_expand = !this.is_not_expand;
    },
    expandPage() {
      if (this.widthExpand == false) {
        this.is_not_expand = true;
      }
    },
    myEventHandler() {
      if (window.innerWidth < 992) {
        this.widthExpand = false;
      } else {
        this.widthExpand = true;
      }
    },
  },
};
</script>
<style scoped>
.expand {
  margin-left: 280px;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
}
.not-expand {
  margin-left: 0px;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
}
@media (max-width: 991.98px) {
  .template-view {
    margin-left: 0px;
    transition-timing-function: ease-in-out;
    transition-duration: 0.2s;
  }
}
</style>
