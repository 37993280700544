<template>
  <div
    class="container-top"
    :class="is_not_expand ? 'width-navbar' : 'width-expand-navbar'"
  >
    <div class="flex align-items-center">
      <i
        class="cursor-pointer"
        :class="
          !widthExpand && !is_not_expand ? 'pi pi-times' : 'pi pi-align-left'
        "
        @click="this.$emit('call')"
      ></i>
      <!-- <span class="ml-2 navbar-text">Food On Way</span> -->
    </div>
    <img
      src="../../assets/icons/cat-icon.png"
      style="height: 50%"
      :class="{ hidden: widthExpand }"
    />
    <div class="flex align-items-center">
      <span
        class="navbar-profile-text text-right"
        :class="{ hidden: !widthExpand }"
      >
        {{ $store.state.userInfo.username }}
        <!-- Admin -->
      </span>
      <i
        v-click-outside="hide"
        class="cursor-pointer"
        :class="[
          !widthExpand ? 'pi pi-ellipsis-v' : 'arrow-icon arrow-style mt-3',
          { 'absolute set-icon': !widthExpand },
        ]"
        @click="visibleDDMenu = !visibleDDMenu"
      ></i>
    </div>
  </div>
  <div v-if="visibleDDMenu" class="dropdown">
    <div class="grid w-full m-0" :class="{ hidden: widthExpand }">
      <div class="col-12 w-full profile-setting-text text-center">
        {{ $store.state.userInfo.username }}
      </div>
    </div>
    <div class="grid" :class="{ hidden: widthExpand }">
      <hr class="line" />
    </div>
    <div class="grid w-full m-0">
      <div
        class="col-12 w-full profile-setting-text cursor-pointer"
        @click="goToProfilePage"
      >
        Profile Setting
      </div>
    </div>
    <div class="grid">
      <hr class="line" />
    </div>
    <div class="grid w-full m-0">
      <div
        class="col-12 w-full profile-setting-text cursor-pointer"
        @click="logout"
      >
        Logout
      </div>
    </div>
  </div>
  <aside>
    <div
      class="sidebar"
      :class="is_not_expand ? 'width-sidebar' : 'width-expand-sidebar'"
    >
      <div class="logo">
        <img
          src="../../assets/icons/cat-icon.png"
          style="max-height: 67px; padding: 6px"
        />
      </div>
      <div class="menu">
        <router-link
          v-if="match != $store.state.userInfo.email"
          @click="this.$emit('callpage'), closedropdown('Dashboard')"
          :to="{ name: 'Dashboard' }"
          id="Dashboard"
          :class="
            $store.state.activeRoute === 'Dashboard'
              ? 'menu-item selected'
              : 'menu-item'
          "
        >
          <i
            :class="
              $store.state.activeRoute === 'Dashboard'
                ? 'white-dashboard-icon selected'
                : 'dashboard-icon'
            "
          ></i>
          <span class="menu-text">Dashboard</span>
        </router-link>
        <div
          v-if="match != $store.state.userInfo.email"
          class="menu-item cursor-pointer"
          :class="visibleMasterMenu === true ? 'menu-selected' : ''"
          @click="visibleMasterMenu = !visibleMasterMenu"
          id="sidebarMaster"
        >
          <i
            :class="!visibleMasterMenu ? 'master-icon' : 'white-master-icon'"
          ></i>
          <span class="menu-text">Master</span>
          <i
            :class="{
              'chevron-down': !visibleMasterMenu,
              'white-chevron-up': visibleMasterMenu,
            }"
          ></i>
        </div>
        <router-link
          @click="this.$emit('callpage')"
          v-if="visibleMasterMenu"
          :to="{ name: 'Customer' }"
          id="Customer"
          :class="
            $store.state.activeRoute === 'Customer'
              ? 'menu-item selected'
              : 'menu-item'
          "
        >
          <i
            :class="
              $store.state.activeRoute === 'Customer'
                ? 'white-customer-icon selected'
                : 'customer-icon'
            "
          ></i>
          <span class="menu-text">Customers</span>
        </router-link>
        <router-link
          @click="this.$emit('callpage')"
          v-if="visibleMasterMenu"
          :to="{ name: 'Vendor' }"
          id="Vendor"
          :class="
            $store.state.activeRoute === 'Vendor'
              ? 'menu-item selected'
              : 'menu-item'
          "
        >
          <i
            :class="
              $store.state.activeRoute === 'Vendor'
                ? 'white-vendor-icon selected'
                : 'vendor-icon'
            "
          ></i>
          <span class="menu-text">Vendors</span>
        </router-link>
        <router-link
          @click="this.$emit('callpage')"
          v-if="visibleMasterMenu"
          :to="{ name: 'Category' }"
          id="RawMaterialIngredient"
          :class="
            $store.state.activeRoute === 'Category'
              ? 'menu-item selected'
              : 'menu-item'
          "
        >
          <i
            :class="
              $store.state.activeRoute === 'Category'
                ? 'white-rowmaterial-icon selected'
                : 'rowmaterial-icon'
            "
          ></i>
          <span class="menu-text">Main Categories</span>
        </router-link>

        <router-link
          @click="this.$emit('callpage')"
          v-if="visibleMasterMenu"
          :to="{ name: 'RawMaterialIngredient' }"
          id="RawMaterialIngredient"
          :class="
            $store.state.activeRoute === 'RawMaterialIngredient'
              ? 'menu-item selected'
              : 'menu-item'
          "
        >
          <i
            :class="
              $store.state.activeRoute === 'RawMaterialIngredient'
                ? 'white-rowmaterial-icon selected'
                : 'rowmaterial-icon'
            "
          ></i>
          <span class="menu-text">Raw Materials / Ingredients</span>
        </router-link>
        <router-link
          @click="this.$emit('callpage')"
          v-if="visibleMasterMenu"
          :to="{ name: 'RecipeItem' }"
          id="RecipeItem"
          :class="
            $store.state.activeRoute === 'RecipeItem'
              ? 'menu-item selected'
              : 'menu-item'
          "
        >
          <i
            :class="
              $store.state.activeRoute === 'RecipeItem'
                ? 'white-recipe-icon selected'
                : 'recipe-icon'
            "
          ></i>
          <span class="menu-text">Recipes / Items</span>
        </router-link>

        <router-link
          @click="this.$emit('callpage')"
          v-if="visibleMasterMenu"
          :to="{ name: 'Menu' }"
          id="Menu"
          :class="
            $store.state.activeRoute === 'Menu'
              ? 'menu-item selected'
              : 'menu-item'
          "
        >
          <i
            :class="
              $store.state.activeRoute === 'Menu'
                ? 'white-menu-icon selected'
                : 'menu-icon'
            "
          ></i>
          <span class="menu-text">Menus</span>
        </router-link>
        <router-link
          @click="this.$emit('callpage')"
          v-if="visibleMasterMenu"
          :to="{ name: 'SubMenu' }"
          id="SubMenu"
          :class="
            $store.state.activeRoute === 'SubMenu'
              ? 'menu-item selected'
              : 'menu-item'
          "
        >
          <i
            :class="
              $store.state.activeRoute === 'SubMenu'
                ? 'white-submenu-icon selected'
                : 'submenu-icon'
            "
          ></i>
          <span class="menu-text">Sub Menus</span>
        </router-link>
        <router-link
          @click="this.$emit('callpage')"
          v-if="visibleMasterMenu"
          :to="{ name: 'Event' }"
          id="Event"
          :class="
            $store.state.activeRoute === 'Event'
              ? 'menu-item selected'
              : 'menu-item'
          "
        >
          <i
            :class="
              $store.state.activeRoute === 'Event'
                ? 'white-prog-icon selected'
                : 'prog-icon'
            "
          ></i>
          <span class="menu-text">Events</span>
        </router-link>
        <div
          v-if="match != $store.state.userInfo.email"
          class="menu-item cursor-pointer"
          :class="visibleHRMenu === true ? 'menu-selected' : ''"
          @click="visibleHRMenu = !visibleHRMenu"
          id="sidebarHR"
        >
          <i :class="!visibleHRMenu ? 'hr-icon' : 'white-hr-icon'"></i>
          <span class="menu-text">HR</span>
          <i
            :class="{
              'chevron-down': !visibleHRMenu,
              'white-chevron-up': visibleHRMenu,
            }"
          ></i>
        </div>
        <router-link
          @click="this.$emit('callpage')"
          v-if="visibleHRMenu"
          :to="{ name: 'Employee' }"
          id="Employee"
          :class="
            $store.state.activeRoute === 'Employee'
              ? 'menu-item selected'
              : 'menu-item'
          "
        >
          <i
            :class="
              $store.state.activeRoute === 'Employee'
                ? 'white-employee-icon selected'
                : 'employee-icon'
            "
          ></i>
          <span class="menu-text">Employees</span>
        </router-link>
        <router-link
          @click="this.$emit('callpage')"
          v-if="visibleHRMenu"
          :to="{ name: 'Attendance' }"
          id="Attendance"
          :class="
            $store.state.activeRoute === 'Attendance'
              ? 'menu-item selected'
              : 'menu-item'
          "
        >
          <i
            :class="
              $store.state.activeRoute === 'Attendance'
                ? 'white-attendance-icon selected'
                : 'attendance-icon'
            "
          ></i>
          <span class="menu-text">Employee Attendances</span>
        </router-link>

        <router-link
          @click="this.$emit('callpage')"
          v-if="visibleHRMenu"
          :to="{ name: 'Invoice' }"
          id="Invoice"
          :class="
            $store.state.activeRoute === 'Invoice'
              ? 'menu-item selected'
              : 'menu-item'
          "
        >
          <i
            :class="
              $store.state.activeRoute === 'Invoice'
                ? 'white-invoice-icon selected'
                : 'invoice-icon'
            "
          ></i>
          <span class="menu-text">Invoices</span>
        </router-link>
        <router-link
          @click="this.$emit('callpage')"
          v-if="visibleHRMenu"
          :to="{ name: 'Payment' }"
          id="Payment"
          :class="
            $store.state.activeRoute === 'Payment'
              ? 'menu-item selected'
              : 'menu-item'
          "
        >
          <i
            :class="
              $store.state.activeRoute === 'Payment'
                ? 'white-payment-icon selected'
                : 'payment-icon'
            "
          ></i>
          <span class="menu-text">Payments</span>
        </router-link>
        <router-link
          @click="this.$emit('callpage')"
          v-if="visibleHRMenu"
          :to="{ name: 'User' }"
          id="User"
          :class="
            $store.state.activeRoute === 'User'
              ? 'menu-item selected'
              : 'menu-item'
          "
        >
          <i
            :class="
              $store.state.activeRoute === 'User'
                ? 'white-user-icon selected'
                : 'user-icon'
            "
          ></i>
          <span class="menu-text">Users</span>
        </router-link>
        <div
          v-if="match != $store.state.userInfo.email"
          class="menu-item cursor-pointer"
          :class="visibleReportMenu === true ? 'menu-selected' : ''"
          @click="visibleReportMenu = !visibleReportMenu"
          id="sidebarReport"
        >
          <i
            :class="
              !visibleReportMenu ? 'invoice-icon' : 'white-invoice-menu-icon'
            "
          ></i>
          <span class="menu-text">Report</span>
          <i
            :class="{
              'chevron-down': !visibleReportMenu,
              'white-chevron-up': visibleReportMenu,
            }"
          ></i>
        </div>
        <router-link
          @click="this.$emit('callpage')"
          v-if="visibleReportMenu"
          :to="{ name: 'EventReport' }"
          id="EventReport"
          :class="
            $store.state.activeRoute === 'EventReport'
              ? 'menu-item selected'
              : 'menu-item'
          "
        >
          <i
            :class="
              $store.state.activeRoute === 'EventReport'
                ? 'white-invoice-icon selected'
                : 'invoice-icon'
            "
          ></i>
          <span class="menu-text">Event Report</span>
        </router-link>
        <router-link
          @click="this.$emit('callpage')"
          v-if="visibleReportMenu"
          :to="{ name: 'InvoiceReport' }"
          id="InvoiceReport"
          :class="
            $store.state.activeRoute === 'InvoiceReport'
              ? 'menu-item selected'
              : 'menu-item'
          "
        >
          <i
            :class="
              $store.state.activeRoute === 'InvoiceReport'
                ? 'white-invoice-icon selected'
                : 'invoice-icon'
            "
          ></i>
          <span class="menu-text">Invoice Report</span>
        </router-link>
        <div
          v-if="match != $store.state.userInfo.email"
          class="menu-item cursor-pointer"
          :class="visibleSettingMenu === true ? 'menu-selected' : ''"
          @click="visibleSettingMenu = !visibleSettingMenu"
          id="sidebarSetting"
        >
          <i class="pi pi-cog"></i>
          <span class="menu-text">Settings</span>
          <i
            :class="{
              'chevron-down': !visibleSettingMenu,
              'white-chevron-up': visibleSettingMenu,
            }"
          ></i>
        </div>
        <router-link
          @click="this.$emit('callpage')"
          v-if="visibleSettingMenu"
          :to="{ name: 'Setting' }"
          id="Setting"
          :class="
            $store.state.activeRoute === 'Setting'
              ? 'menu-item selected'
              : 'menu-item'
          "
        >
          <i
            :class="
              $store.state.activeRoute === 'Setting'
                ? 'pi pi-cog selected'
                : 'pi pi-cog'
            "
          ></i>
          <span class="menu-text">Setting Configuration</span>
        </router-link>
        <router-link
          @click="this.$emit('callpage')"
          v-if="visibleSettingMenu"
          :to="{ name: 'Template' }"
          id="template"
          :class="
            $store.state.activeRoute === 'Template'
              ? 'menu-item selected'
              : 'menu-item'
          "
        >
          <i
            :class="
              $store.state.activeRoute === 'Template'
                ? 'white-template-icon selected'
                : 'template-icon'
            "
          ></i>
          <span class="menu-text">Templates</span>
        </router-link>

        <router-link
          @click="this.$emit('callpage')"
          v-if="visibleSettingMenu"
          :to="{ name: 'AboutUs' }"
          id="AboutUs"
          :class="
            $store.state.activeRoute === 'AboutUs'
              ? 'menu-item selected'
              : 'menu-item'
          "
        >
          <i
            :class="
              $store.state.activeRoute === 'AboutUs'
                ? 'white-aboutus-icon selected'
                : 'aboutus-icon'
            "
          ></i>
          <span class="menu-text">About Us Page</span>
        </router-link>

        <router-link
          @click="this.$emit('callpage')"
          v-if="visibleSettingMenu"
          :to="{ name: 'TermsAndConditions' }"
          id="TermsAndConditions"
          :class="
            $store.state.activeRoute === 'TermsAndConditions'
              ? 'menu-item selected'
              : 'menu-item'
          "
        >
          <i
            :class="
              $store.state.activeRoute === 'TermsAndConditions'
                ? 'white-terms-icon selected'
                : 'terms-icon'
            "
          ></i>
          <span class="menu-text">Terms And Conditions Page</span>
        </router-link>
        <router-link
          v-if="match == $store.state.userInfo.email"
          :to="{ name: 'Company' }"
          id="Company"
          :class="
            $store.state.activeRoute === 'Company'
              ? 'menu-item selected'
              : 'menu-item'
          "
        >
          <i
            :class="
              $store.state.activeRoute === 'Company'
                ? 'company-active-icon selected'
                : 'company-icon'
            "
          ></i>
          <span class="menu-text">Companies</span>
        </router-link>
        <router-link
          v-if="match == $store.state.userInfo.email"
          :to="{ name: 'EventType' }"
          id="eventtype"
          :class="
            $store.state.activeRoute === 'EventType'
              ? 'menu-item selected'
              : 'menu-item'
          "
        >
          <i
            :class="
              $store.state.activeRoute === 'EventType'
                ? 'pi pi-th-large selected'
                : 'pi pi-th-large'
            "
          ></i>
          <span class="menu-text">Event Type</span>
        </router-link>
      </div>
    </div>
  </aside>
</template>

<script>
var match = process.env.VUE_APP_USERNAME;
export default {
  name: "SidebarMenu",
  emits: ["call", "callpage"],
  props: {
    is_not_expand: {
      type: Boolean,
      required: true,
    },
    widthExpand: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      visibleDDMenu: false,
      visibleMasterMenu: false,
      visibleHRMenu: false,
      visibleReportMenu: false,
      visibleSettingMenu: false,
      match: match,
    };
  },
  mounted() {
    if (
      !this.visibleMasterMenu &&
      (this.checkCurrentRoute("Customer") ||
        this.checkCurrentRoute("Vendor") ||
        this.checkCurrentRoute("Category") ||
        this.checkCurrentRoute("RawMaterialIngredient") ||
        this.checkCurrentRoute("RecipeItem") ||
        this.checkCurrentRoute("Menu") ||
        this.checkCurrentRoute("SubMenu") ||
        this.checkCurrentRoute("Event"))
    ) {
      this.visibleMasterMenu = true;
    } else if (
      !this.visibleHRMenu &&
      (this.checkCurrentRoute("Employee") ||
        this.checkCurrentRoute("Attendance") ||
        this.checkCurrentRoute("Invoice") ||
        this.checkCurrentRoute("Payment") ||
        this.checkCurrentRoute("User"))
    ) {
      this.visibleHRMenu = true;
    } else if (
      !this.visibleReportMenu &&
      (this.checkCurrentRoute("EventReport") ||
        this.checkCurrentRoute("InvoiceReport"))
    ) {
      this.visibleReportMenu = true;
    } else if (
      !this.visibleSettingMenu &&
      (this.checkCurrentRoute("Setting") ||
        this.checkCurrentRoute("Template") ||
        this.checkCurrentRoute("AboutUs") ||
        this.checkCurrentRoute("TermsAndConditions"))
    ) {
      this.visibleSettingMenu = true;
    }
  },
  methods: {
    checkCurrentRoute(module) {
      if (this.$route.name === module) {
        return true;
      }
      return false;
    },
    closedropdown(key) {
      this.visibleMasterMenu = false;
      this.visibleHRMenu = false;
      this.visibleReportMenu = false;
      this.visibleSettingMenu = false;
      this.$router.push({ name: key });
    },
    hide() {
      this.visibleDDMenu = false;
    },
    goToProfilePage() {
      this.$router.push({ name: "ProfileSetting" });
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/sidebar.css";
@media (max-width: 991.98px) {
  .dropdown {
    right: 0.5rem;
  }
}
.set-icon {
  right: 0.5rem;
}
</style>
