<template>
  <div class="ml-5 mr-5 set-margin-top mb-5">
    <div class="grid">
      <div class="grid">
        <div class="order-form-title w-full">
          Raw Material / Ingredient Management
        </div>
        <div class="order-form-sub-title">{{ subtitle }}</div>
      </div>
      <div class="order-card w-full">
        <div class="grid">
          <div class="md:col-4 col-12">
            <InputTextRequire
              type="text"
              v-model="vmodel.name"
              id="name"
              :validation="v$.vmodel.name"
              class="w-full"
              label="Name"
              placeholder="Enter Name"
            />
          </div>
          <div class="md:col-3 sm:col-10 col-9">
            <DropDownRequire
              type="text"
              v-model="vmodel.category"
              :validation="v$.vmodel.category"
              :options="categoryoption"
              id="category"
              :filter="true"
              class="w-full"
              label="Main Category"
              placeholder="Select Main Category"
              optionLabel="name"
              optionValue="id"
            />
          </div>
          <div class="md:col-1 sm:col-2 col-3 sm:mb-0 mb-2">
            <PrimeButton
              class="download-button mt-4 w-full"
              icon="pi pi-plus"
              @click="showCategoryPopup = true"
            ></PrimeButton>
          </div>
          <div class="md:col-4 col-12">
            <InputTextRequire
              type="number"
              label="Price"
              v-model="vmodel.price"
              :validation="v$.vmodel.price"
              class="w-full"
              id="price"
              placeholder="Enter Price"
              :max="9999999"
              @keydown="onlyDigitPrice"
            />
          </div>
        </div>
        <div class="grid">
          <!-- <div class="md:col-4 col-12">
            <InputNumberRequire
              label="Price"
              v-model="vmodel.price"
              :validation="v$.vmodel.price"
              class="w-full"
              id="price"
              placeholder="Enter Price"
              :max="9999999"
              @keydown="onlyDigitPrice"
            />
          </div> -->
          <!-- <div class="md:col-4 col-12">
            <InputNumberRequire
              type="qty"
              label="Quantity"
              v-model="vmodel.qty"
              id="qty"
              :validation="v$.vmodel.qty"
              class="w-full"
              placeholder="Enter Quantity"
              @keydown="onlyDigitQty"
            />
          </div> -->
          <div class="md:col-4 col-12">
            <DropDownRequire
              type="text"
              v-model="vmodel.qtytype"
              :validation="v$.vmodel.qtytype"
              :options="unitoption"
              id="qtytype"
              :filter="true"
              class="w-full"
              label="Unit Type"
              placeholder="Select Unit Type"
              optionLabel="name"
              optionValue="id"
            />
          </div>
          <div class="md:col-4 col-12">
            <DropDownRequire
              type="text"
              v-model="vmodel.type"
              :validation="v$.vmodel.type"
              :options="typeoption"
              id="type"
              :filter="true"
              class="w-full"
              label="Type"
              placeholder="Select Type"
              optionLabel="text"
              optionValue="value"
            />
          </div>
          <div class="md:col-3 sm:col-10 col-9">
            <DropDownRequire
              :options="voption"
              :filter="true"
              id="purchasefrom"
              label="Purchase From"
              placeholder="Select Vendor"
              v-model="vmodel.purchasefrom"
              :validation="v$.vmodel.purchasefrom"
              optionLabel="name"
              optionValue="id"
            />
          </div>
          <div class="md:col-1 sm:col-2 col-3 sm:mb-0 mb-2">
            <PrimeButton
              class="download-button mt-4 w-full"
              icon="pi pi-plus"
              @click="showVendorPopup = true"
            ></PrimeButton>
          </div>
        </div>
        <!-- <div class="grid"> -->
        <!-- <div class="md:col-6 col-12">
            <DropDownRequire
              type="text"
              v-model="vmodel.type"
              :validation="v$.vmodel.type"
              :options="typeoption"
              id="type"
              :filter="true"
              class="w-full"
              label="Type"
              placeholder="Select Type"
              optionLabel="text"
              optionValue="value"
            />
          </div>
          <div class="md:col-5 sm:col-10 col-9">
            <DropDownRequire
              :options="voption"
              :filter="true"
              id="purchasefrom"
              label="Purchase From"
              placeholder="Select Vendor"
              v-model="vmodel.purchasefrom"
              :validation="v$.vmodel.purchasefrom"
              optionLabel="name"
              optionValue="id"
            />
          </div>
          <div class="md:col-1 sm:col-2 col-3 sm:mb-0 mb-2">
            <PrimeButton
              class="download-button mt-4 w-full"
              icon="pi pi-plus"
              @click="showVendorPopup = true"
            ></PrimeButton>
          </div> -->
        <!-- </div> -->
        <div class="grid mx-0 mt-4">
          <div class="col-12 flex set-button">
            <PrimeButton
              label="Add"
              class="order-form-button"
              v-if="!isEditMode"
              @click="createRow"
            >
            </PrimeButton>
            <PrimeButton
              label="Edit"
              class="order-form-button"
              v-else
              @click="createRow"
            >
            </PrimeButton>
            <PrimeButton
              label="Cancel"
              class="form-cancel-button sm:ml-2"
              @click="onCancel"
            ></PrimeButton>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CategoryAddPopup
    :show-popup="showCategoryPopup"
    @displayNo="closeCategoryPopup()"
    @displayYes="checkCategory"
  ></CategoryAddPopup>
  <VendorAddPopup
    :show-popup="showVendorPopup"
    @displayNo="closeVendorPopup()"
    @displayYes="checkVendor"
  ></VendorAddPopup>
</template>

<script>
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  numeric,
  maxLength,
  minValue,
  maxValue,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      submitted: false,
      isEditMode: false,
      loading: false,
      showCategoryPopup: false,
      showVendorPopup: false,
      title: "Add",
      subtitle: "Add Raw Material / Ingredient",
      typeoption: [
        {
          text: "Fixed",
          value: "fixed",
        },
        {
          text: "Usable",
          value: "usable",
        },
      ],
      // unittype: [
      //   {
      //     text: "Gram",
      //     value: "gram",
      //   },
      //   {
      //     text: "Kg",
      //     value: "kg",
      //   },
      //   {
      //     text: "Mililitre ",
      //     value: "ml",
      //   },
      //   {
      //     text: "Litre",
      //     value: "li",
      //   },
      // ],
      categoryoption: [],
      voption: [],
      unitoption: [],
      vmodel: {
        name: "",
        type: "",
        qty: 1,
        qtytype: "",
        purchasefrom: "",
        price: 1,
        category: "",
      },
    };
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (this.$route.params.id) {
      this.subtitle = "Update Raw Material / Ingredient";
      this.isEditMode = true;
      this.getRowById();
    }
    this.$root.$globalState.loader = false;
    this.getCategory();
    this.getVendor();
    this.getUnit();
  },
  validations() {
    return {
      vmodel: {
        name: { required, max: maxLength(22) },
        type: {},
        purchasefrom: {},
        //  unittye:{},
        price: { required, numeric, min: minValue(1), max: maxValue(9999999) },
        qty: { required, numeric, min: minValue(1), max: maxValue(99999) },
        qtytype: { required },
        category: { required },
      },
    };
  },
  methods: {
    closeCategoryPopup() {
      this.showCategoryPopup = false;
    },
    checkCategory(data) {
      this.showCategoryPopup = false;
      this.vmodel.category = data;
      this.getCategory();
    },
    closeVendorPopup() {
      this.showVendorPopup = false;
    },
    checkVendor(data) {
      this.showVendorPopup = false;
      this.vmodel.purchasefrom = data;
      this.getVendor();
    },
    closeUnitPopup() {
      this.showUnitPopup = false;
    },
    checkUnit(data) {
      this.showUnitPopup = false;
      this.vmodel.qtytype = data;
      this.getUnit();
    },
    onlyDigitPrice($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        (this.vmodel.price >= 9999999 &&
          keyCode > 47 &&
          keyCode < 58 &&
          keyCode !== 8 &&
          keyCode !== 32) ||
        (this.vmodel.price >= 9999999 &&
          keyCode !== 229 &&
          keyCode !== 8 &&
          keyCode !== 32)
      ) {
        $event.preventDefault();
      }
    },
    onlyDigitQty($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        (this.vmodel.qty >= 99999 &&
          keyCode > 47 &&
          keyCode < 58 &&
          keyCode !== 8 &&
          keyCode !== 32) ||
        (this.vmodel.price >= 99999 &&
          keyCode !== 229 &&
          keyCode !== 8 &&
          keyCode !== 32)
      ) {
        $event.preventDefault();
      }
    },
    onCancel() {
      this.$router.push({ name: "RawMaterialIngredient" });
    },
    getVendor() {
      this.$root.$globalState.loader = true;
      APIService.get("/vendor/vender")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.voption = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    getCategory() {
      this.$root.$globalState.loader = true;
      APIService.get("/category/dropdown")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.categoryoption = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    getUnit() {
      this.$root.$globalState.loader = true;
      APIService.get("/unit/dropdown")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.unitoption = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    async createRow() {
      const isFormCorrect = await this.v$.vmodel.$validate();
      if (!isFormCorrect) return;
      else {
        // if (!this.vmodel.qtytype.match(/^[A-Za-z]+$/)) {
        //   this.$toast.add({
        //     severity: "error",
        //     summary: "Error",
        //     detail: "Unit (Type) only allow alphabetic characters.",
        //     life: 3000,
        //   });
        //   return;
        // }
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        this.$root.$globalState.loader = true;
          // dependantid
        let payload = {
          name: this.vmodel.name,
          type: this.vmodel.type,
          qty: this.vmodel.qty,
          qtytype: this.vmodel.qtytype,
          purchasefrom: this.vmodel.purchasefrom,
          price: this.vmodel.price,
          categoryid: this.vmodel.category,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/rowmaterial", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "RawMaterialIngredient" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
              this.$root.$globalState.loader = false;
            });
        } else {
          APIService.post("/rowmaterial", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "RawMaterialIngredient" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
              this.$root.$globalState.loader = false;
            });
        }
      }
    },
    getRowById() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      APIService.getById("/rowmaterial", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.name = response.data.data.name;
            this.vmodel.type = response.data.data.type;
            this.vmodel.qty = response.data.data.qty;
            this.vmodel.qtytype = response.data.data.qtytype;
            this.vmodel.purchasefrom = response.data.data.purchasefrom;
            this.vmodel.price = response.data.data.price;
            this.vmodel.category = response.data.data.categoryid;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "RawMaterialIngredient" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
          this.$root.$globalState.loader = false;
        });
    },
  },
};
</script>
<style>
.p-calendar-w-btn .p-datepicker-trigger {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #ff9f43 !important;
  border-color: #ff9f43 !important;
}
</style>
