import RemainingPayment from "@/models/remainingPayment";

export default class RemainingPaymentFactory {
  static createFromJson(json) {
    return new RemainingPayment(json);
  }
  static createFromJsonArray(jsonArray) {
    const jsonData = [];
    jsonArray.forEach((item) => {
      jsonData.push(RemainingPaymentFactory.createFromJson(item));
    });

    return jsonData;
  }
}
