//import DateFormat from "@/libs/DateFormat"

export default class EventType {
  constructor(data = {}) {
    return {
      id: data.id || "",
      name: data.name || "",
    };
  }
}
