<template>
  <div class="ml-5 mr-5 set-margin-top mb-5">
    <div class="grid">
      <div class="grid">
        <div class="order-form-title w-full">Customer Management</div>
        <div class="order-form-sub-title">{{ subtitle }}</div>
      </div>
      <div class="order-card w-full">
        <div class="grid">
          <div class="md:col-4 col-12">
            <InputTextRequire
              type="text"
              v-model="vmodel.name"
              :validation="v$.vmodel.name"
              class="w-full"
              id="name"
              placeholder="Enter Name"
              label="Name"
            />
          </div>
          <div class="md:col-4 col-12">
            <InputTextRequire
              type="text"
              v-model="vmodel.email"
              :validation="v$.vmodel.email"
              class="w-full"
              id="email"
              label="Email"
              placeholder="Enter Email"
              maxLength="75"
            />
          </div>
          <div class="md:col-4 col-12">
            <InputTextRequire
              type="text"
              v-model="vmodel.contactno"
              :validation="v$.vmodel.contactno"
              id="contactno"
              class="w-full"
              label="Contact Number"
              placeholder="Enter Contact Number"
              maxlength="10"
              minlength="10"
              labelnotshow="false"
              @keypress="onlyNumber"
            />
          </div>
        </div>
        <div class="grid">
          <div class="md:col-4 col-12">
            <InputTextRequire
              type="text"
              v-model="vmodel.address1"
              :validation="v$.vmodel.address1"
              id="address1"
              class="w-full"
              placeholder="Enter Address - 1"
              label="Address - 1"
            />
          </div>
          <div class="md:col-4 col-12">
            <InputTextRequire
              type="text"
              v-model="vmodel.address2"
              :validation="v$.vmodel.address2"
              id="address2"
              class="w-full"
              placeholder="Enter Address - 2"
              label="Address - 2"
            />
          </div>
          <div class="md:col-4 col-12">
            <InputTextRequire
              type="text"
              v-model="vmodel.zip"
              :validation="v$.vmodel.zip"
              class="w-full"
              id="zipcode"
              placeholder="Enter Zip Code"
              label="Zip Code"
              maxlength="6"
              minlength="6"
              @keypress="onlyNumber"
            />
          </div>
        </div>
        <div class="grid">
          <div class="md:col-4 col-12">
            <DropDownRequire
              :options="countryOption"
              id="country"
              :filter="true"
              placeholder="Select Country"
              v-model="vmodel.country"
              :validation="v$.vmodel.country"
              label="Country"
              optionLabel="name"
              optionValue="id"
              @update:modelValue="getStateByCountryId()"
            />
          </div>
          <div class="md:col-4 col-12">
            <DropDownRequire
              :options="stateOption"
              v-model="vmodel.state"
              :validation="v$.vmodel.state"
              :filter="true"
              id="state"
              placeholder="Select State"
              label="State"
              optionLabel="name"
              optionValue="id"
              @update:modelValue="getCityByStateId()"
            />
          </div>
          <div class="md:col-4 col-12">
            <DropDownRequire
              v-model="vmodel.city"
              :validation="v$.vmodel.city"
              :options="cityOption"
              :filter="true"
              id="city"
              placeholder="Select City"
              label="City"
              optionLabel="name"
              optionValue="id"
            />
          </div>
        </div>
        <div class="grid mx-0 mt-4">
          <div class="col-12 flex set-button">
            <PrimeButton
              label="Add"
              class="order-form-button"
              v-if="!isEditMode"
              @click="createMember"
            >
            </PrimeButton>
            <PrimeButton
              label="Edit"
              class="order-form-button"
              v-else
              @click="createMember"
            >
            </PrimeButton>
            <PrimeButton
              label="Cancel"
              class="form-cancel-button sm:ml-2"
              @click="onCancel"
            ></PrimeButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  numeric,
  maxLength,
  minLength,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      submitted: false,
      isEditMode: false,
      title: "Add",
      subtitle: "Add Customer",
      countryOption: [],
      stateOption: [],
      cityOption: [],
      vmodel: {
        name: "",
        address1: "",
        address2: "",
        zip: "",
        email: "",
        contactno: "",
        country: "",
        state: "",
        city: "",
      },
    };
  },
  validations() {
    return {
      vmodel: {
        name: { required, max: maxLength(30) },
        email: { email, max: maxLength(75) },
        address1: { required, max: maxLength(50) },
        address2: { max: maxLength(50) },
        zip: { max: maxLength(6), min: minLength(6) },
        contactno: {
          required,
          numeric,
          min: minLength(10),
          max: maxLength(10),
        },
        country: {},
        state: {},
        city: {},
      },
    };
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (this.$route.params.id) {
      this.subtitle = "Update Customer";
      this.isEditMode = true;
      this.getMemberById();
    }
    this.$root.$globalState.loader = false;
    this.getCountry();
  },
  methods: {
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    onCancel() {
      this.$router.push({ name: "Customer" });
    },
    getStateByCountryId() {
      this.$root.$globalState.loader = true;
      this.stateOption = [];
      APIService.getById("/state/bycountryid", this.vmodel.country)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.stateOption = response.data.data;
          }
        })
        .finally(() => {
          this.submitted = false;
          this.$root.$globalState.loader = false;
        });
    },
    getCityByStateId() {
      this.$root.$globalState.loader = true;
      this.cityOption = [];
      APIService.getById("/city/bystateid", this.vmodel.state)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.cityOption = response.data.data;
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    getCountry() {
      this.$root.$globalState.loader = true;
      APIService.get("/country")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.countryOption = response.data.data;
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    async createMember() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        this.$root.$globalState.loader = true;
        let payload = {
          name: this.vmodel.name,
          address1: this.vmodel.address1,
          address2: this.vmodel.address2,
          zip: this.vmodel.zip,
          country: this.vmodel.country,
          state: this.vmodel.state,
          email: this.vmodel.email,
          city: this.vmodel.city,
          contactno: this.vmodel.contactno,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/customer", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Customer" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
              this.$root.$globalState.loader = false;
            });
        } else {
          APIService.post("/customer", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Customer" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
              this.$root.$globalState.loader = false;
            });
        }
      }
    },

    getMemberById() {
      this.$root.$globalState.loader = true;
      APIService.getById("/customer", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.name = response.data.data.name;
            this.vmodel.address1 = response.data.data.address1;
            this.vmodel.address2 = response.data.data.address2;
            this.vmodel.zip = response.data.data.zip;
            this.vmodel.country = response.data.data.country;
            this.vmodel.email = response.data.data.email;
            this.vmodel.state = response.data.data.state;
            this.vmodel.city = response.data.data.city;
            this.vmodel.contactno = response.data.data.contactno;
            this.getStateByCountryId();
            this.getCityByStateId();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "Customer" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
          this.$root.$globalState.loader = false;
        });
    },
  },
};
</script>
