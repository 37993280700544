<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="list-margin">
    <DataTable
      :value="attendance"
      class="p-datatable-lg p-datatable-customers"
      dataKey="id"
      :rowHover="true"
      :resizableColumns="false"
      @sort="onSort($event)"
    >
      <template #header>
        <div class="grid m-0">
          <div class="col-12 lg:col-6 lg:text-left text-center list-header">
            Employee Attendance
          </div>
          <div class="col-12 lg:col-6 lg:text-right text-center">
            <PrimeButton
              label=" ADD "
              class="add-button mr-3"
              icon="plus-icon"
              @click="gotoCreateCustomer"
            ></PrimeButton>
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch" />
              <InputText placeholder="Search" class="search-input" />
            </div>
            <PrimeButton
              class="download-button ml-3"
              icon="pi pi-download"
              @click="downloadCSV"
              :disabled="true"
            ></PrimeButton>
          </div>
        </div>
      </template>

      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <Column field="ename" header="Employee Name" sortable></Column>
      <Column field="pname" header="Program Name" sortable> </Column>
      <Column field="date" header="Event Date" sortable></Column>
      <Column field="actions" header="Actions">
        <template #body="data">
          <div class="flex">
            <i
              class="pi pi-pencil cursor-pointer edit-icon"
              style="color: gray"
              @click="gotoCustomerEdit(data.data.id)"
            ></i>
            <i
              class="pi pi-trash cursor-pointer delete-icon ml-1"
              style="color: #dc3545"
              @click="showDeletePopup(data.data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <Paginator
      v-model:first="currentPage"
      :rows="10"
      :totalRecords="totalRecords"
      :rowsPerPageOptions="[10, 25, 50, 100]"
      template="RowsPerPageDropdown CurrentPageReport PageLinks"
      currentPageReportTemplate="{first} - {last} of {totalRecords} items"
      @page="onPage($event)"
    >
      <template #start> Shown per page : </template>
    </Paginator>
  </div>
</template>
<script>
export default {
  data() {
    return {
      attendance: [
        {
          ename: "Riya",
          pname: "Program 1",
          date: "22/11/2022",
        },
        {
          ename: "Tina",
          pname: "Program 2",
          date: "12/01/2022",
        },
      ],
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      showPopup: false,
      attedanceIdForDelete: "",
    };
  },
  methods: {
    downloadCSV() {
      console.log("this is downloadcsv");
    },
    gotoCreateCustomer() {
      this.$router.push({ name: "AttendanceCreate" });
    },
    onPage(pageData) {
      this.itemsPerPage = pageData.rows;
      this.currentPage = pageData.first;
      this.getAttedanceList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getAttedanceList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getAttedanceList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
</style>
