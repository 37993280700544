<template>
  <div class="ml-5 mr-5 set-margin-top mb-5">
    <div class="grid">
      <div class="grid">
        <div class="order-form-title w-full">Invoice Management</div>
        <div class="order-form-sub-title">{{ subtitle }}</div>
      </div>
      <div class="order-card w-full">
        <div class="grid">
          <div class="md:col-6 col-12">
            <DropDownRequire
              v-model="vmodel.eventid"
              :validation="v$.vmodel.eventid"
              :options="eventOption"
              id="eventid"
              :filter="true"
              placeholder="Event"
              label="Event"
              optionLabel="eventname"
              optionValue="id"
              @update:modelValue="getEventDetails()"
              :disabled="isEditMode"
            />
          </div>
          <div class="md:col-6 col-12">
            <label for="category" class="form-label">Event Details</label>
            <div class="box mt-2">
              <div class="grid m-0">
                <label class="form-label">Customer Name :</label>
                <label class="form-label sm:mx-2 form-label-show set-margin">{{
                  eventDetails.customername
                }}</label>
              </div>
              <div class="grid m-0">
                <label class="form-label">Customer Contact No :</label>
                <label class="form-label sm:mx-2 form-label-show">{{
                  eventDetails.customercontactno
                }}</label>
              </div>
              <div class="grid m-0">
                <label class="form-label">Event Venue :</label>
                <label class="form-label sm:mx-2 form-label-show set-margin">{{
                  eventDetails.eventvenue
                }}</label>
              </div>
              <div class="grid m-0">
                <label class="form-label">Event Date and Time :</label>
                <label class="form-label sm:mx-2 form-label-show">{{
                  eventDetails.eventdate
                }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="md:col-6 col-12">
            <InputTextRequire
              type="text"
              v-model="vmodel.invoicenumber"
              :validation="v$.vmodel.invoicenumber"
              id="invoicenumber"
              class="w-full"
              label="Invoice Number (Auto Generated)"
              placeholder="Enter Invoice Number"
              :disabled="true"
              minlength="5"
            />
          </div>

          <div class="md:col-6 col-12">
            <label for="invoicedate" class="form-label"> Invoice Date </label>
            <Calendar
              v-model="vmodel.invoicedate"
              id="invoicedate"
              type="text"
              class="w-full mt-2 mb-2"
              label="Invoice Date"
              placeholder="Enter Invoice Date"
              showIcon
              dateFormat="yy-mm-dd"
              :class="{
                'p-invalid': v$.vmodel.invoicedate.$error,
              }"
            />
            <span
              v-if="
                v$.vmodel.invoicedate.$error &&
                v$.vmodel.invoicedate.required.$invalid
              "
              id="invoicedate-help"
              class="p-error text-left mt-4"
            >
              Invoice Date is required
            </span>
          </div>
        </div>
        <div class="grid">
          <div class="md:col-6 col-12">
            <InputNumberRequire
              v-model="vmodel.invoiceamount"
              :validation="v$.vmodel.invoiceamount"
              id="invoiceamount"
              class="w-full"
              :min="1"
              :max="9999999"
              @keydown="onlyDigitAmount"
              label="Invoice Amount"
              placeholder="Enter Amount"
              @update:modelValue="calAmount()"
            />
          </div>
          <div class="md:col-6 col-12">
            <label for="duedate" class="form-label"> Due Date </label>
            <Calendar
              v-model="vmodel.duedate"
              type="text"
              label="Due Date"
              class="w-full mt-2 mb-2"
              placeholder="Enter Due Date"
              id="duedate"
              showIcon
              dateFormat="yy-mm-dd"
              :class="{
                'p-invalid': v$.vmodel.duedate.$error,
              }"
            />
            <span
              v-if="
                v$.vmodel.duedate.$error && v$.vmodel.duedate.required.$invalid
              "
              id="duedate-help"
              class="p-error text-left mt-4"
            >
              Due Date is required
            </span>
          </div>
        </div>

        <div class="grid">
          <div class="col-12">
            <label for="category" class="form-label">Details</label>
            <div id="box" class="box mt-2">
              <div
                class="grid"
                v-for="(overview, index) in vmodel.overview"
                :id="index"
                :key="index"
              >
                <div class="md:col-5 col-12">
                  <div class="grid m-0">
                    <div class="md:col-2 col-1 p-0 flex align-items-center">
                      <i
                        class="draggable-icon cursor-pointer mt-3"
                        :draggable="true"
                        @dragstart="dragStart(index)"
                        @dragover="dragOver(index)"
                        @drop="drop"
                      ></i>
                    </div>
                    <div class="md:col-10 col-10 p-0">
                      <InputGroup
                        type="text"
                        v-model="vmodel.overview[index].description"
                        :validation="
                          v$.vmodel.overview.$each.$response.$errors[index]
                            .description[0]
                        "
                        class="description-text"
                        id="description"
                        label="Description"
                        placeholder="Enter Description"
                        :customfield="true"
                        :validated="validated"
                      />
                    </div>
                  </div>
                </div>
                <div class="md:col-5 col-12">
                  <NumberCustom
                    type="text"
                    v-model="vmodel.overview[index].price"
                    :validation="
                      v$.vmodel.overview.$each.$response.$errors[index].price[0]
                    "
                    label="Price"
                    id="price"
                    placeholder="Enter Price"
                    :min="0"
                    :max="9999999"
                    :customfield="true"
                    :validated="validated"
                  />
                </div>
                <div
                  class="md:col-2 col-12 flex justify-content-center align-items-center relative"
                >
                  <PrimeButton
                    icon="bin-icon"
                    v-if="vmodel.overview.length > 1"
                    class="bin-button"
                    @click="removeItem(index)"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-4">
                  <PrimeButton
                    icon="pi pi-plus"
                    class="add-item-button"
                    @click="repeateAgain()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="md:col-6 col-12">
            <InputTextRequire
              type="text"
              v-model="vmodel.taxno"
              :validation="v$.vmodel.taxno"
              id="taxno"
              class="w-full"
              label="Tax No"
              placeholder="Enter Tax No"
              maxlength="20"
            />
          </div>
          <div class="md:col-6 col-12">
            <InputPercent
              v-model="vmodel.tax"
              :validation="v$.vmodel.tax"
              placeholder="Enter Tax"
              sectionClass="field mt-1"
              id="discount"
              label="Tax"
              :min="1"
              :max="100"
              @keydown="onlyDigit"
              @update:modelValue="calAmount()"
            />
          </div>
        </div>
        <div class="grid">
          <div class="col-12">
            <div class="grid m-0">
              <label class="form-label">Total Amount :</label>
              <label class="form-label mx-2">{{
                vmodel.totalamount.toFixed(2)
              }}</label>
            </div>
          </div>
          <div class="col-12">
            <div class="grid m-0">
              <label class="form-label">Company Name :</label>
              <label class="form-label mx-2">{{
                $store.state.userInfo.username
              }}</label>
            </div>
          </div>
        </div>
        <div class="grid mx-0 mt-4">
          <div class="col-12 flex set-button">
            <PrimeButton
              label="Add"
              class="order-form-button"
              v-if="!isEditMode"
              @click="createInvoice"
            >
            </PrimeButton>
            <PrimeButton
              label="Edit"
              class="order-form-button"
              v-else
              @click="createInvoice"
            >
            </PrimeButton>
            <PrimeButton
              label="Cancel"
              class="form-cancel-button sm:ml-2"
              @click="onCancel"
            ></PrimeButton>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <InfoPopup
      :show-popup="showInfoPopup"
      :message="infomessage"
      :header="infoheader"
      @displayNo="closePopup()"
    ></InfoPopup>
  </div>
  <!-- </div> -->
  <!-- </div>
  </div> -->
</template>

<script>
import APIService from "@/services/api-service.js";
import DateFormat from "@/libs/DateFormat";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  minLength,
  helpers,
  maxLength,
  maxValue,
  minValue,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      subtitle: "Add Invoice",
      eventOption: [],
      eventDetails: {
        eventvenue: "",
        customername: "",
        customercontactno: "",
        eventdate: "",
      },
      validated: false,
      isEditMode: false,
      paidAmount: 0,
      showInfoPopup: false,
      infoheader: "",
      infomessage: "",
      dragIndex: null,
      vmodel: {
        customerid: "",
        eventid: "",
        invoicedate: new Date(),
        invoicenumber: "",
        invoiceamount: 0,
        duedate: "",
        taxno: "",
        tax: 0,
        totalamount: 0,
        overview: [
          {
            description: "",
            price: 0,
          },
        ],
      },
    };
  },
  validations() {
    return {
      vmodel: {
        customerid: { required },
        eventid: { required },
        invoicedate: { required },
        invoicenumber: { required, min: minLength(8), max: maxLength(8) },
        invoiceamount: { required, min: minValue(1), max: maxValue(9999999) },
        duedate: { required },
        tax: { required, minValue: minValue(0), maxValue: maxValue(100) },
        taxno: { required, max: maxLength(20) },
        totalamount: { required },
        overview: {
          $each: helpers.forEach({
            description: { required },
            price: {
              required,
              min: minValue(0),
              max: maxValue(9999999),
            },
          }),
        },
      },
    };
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (this.$route.params.id) {
      this.subtitle = "Update Invoice";
      this.isEditMode = true;
      this.getInvoiceById();
    }
    this.vmodel.invoicenumber = Math.floor(
      10000000 + Math.random() * 90000000
    ).toString();
    this.getEventDropdown();
    this.$root.$globalState.loader = false;
  },
  methods: {
    dragStart(index) {
      this.dragIndex = index;
    },
    dragOver(index) {
      if (this.dragIndex !== null) {
        const draggedItem = this.vmodel.overview.splice(this.dragIndex, 1)[0];
        this.vmodel.overview.splice(index, 0, draggedItem);
        this.dragIndex = index;
      }
    },
    drop() {
      this.dragIndex = null;
    },
    onlyDigitAmount($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (this.vmodel.invoiceamount >= 9999999 && keyCode > 47 && keyCode < 58)
        $event.preventDefault();
    },
    async onlyDigit($event) {
      if (this.vmodel.tax >= 100) {
        $event.preventDefault();
      }
    },
    onCancel() {
      this.$router.push({ name: "Invoice" });
    },
    getEventDetails() {
      this.$root.$globalState.loader = true;
      APIService.getById("/event", this.vmodel.eventid)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.eventDetails.customername = response.data.data.customername;
            this.eventDetails.customercontactno =
              response.data.data.customercontactno;
            this.eventDetails.eventvenue = response.data.data.venue;
            this.vmodel.customerid = response.data.data.customerid;
            this.eventDetails.eventdate = DateFormat.formatDateTime(
              response.data.data.eventdate
            );
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "Invoice" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
          this.checkInvoiceExist();
          this.$root.$globalState.loader = false;
        });
    },
    checkInvoiceExist() {
      if (!this.$route.params.id) {
        this.$root.$globalState.loader = true;
        APIService.getById("/invoice/invoiceexist", this.vmodel.eventid)
          .then((response) => {
            if (response && response.data && response.data.data) {
              if (response.data.data.length > 0) {
                this.infoheader = "Invoice Information";
                this.infomessage = `For this event, invoice already created.`;
                this.showInfoPopup = true;
              }
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
              setTimeout(() => {
                this.$router.push({ name: "Invoice" });
              });
            }
          })
          .finally(() => {
            this.submitted = false;
            this.$root.$globalState.loader = false;
          });
      }
    },
    getEventDropdown() {
      this.$root.$globalState.loader = true;
      APIService.get("/event/confirmevent")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.eventOption = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    repeateAgain() {
      const newItem = {
        description: "",
        price: 0,
      };
      this.vmodel.overview = [...this.vmodel.overview, newItem];
      this.calAmount();
    },
    removeItem(index) {
      this.vmodel.overview.splice(index, 1);
      this.calAmount();
    },
    async calAmount() {
      if (this.vmodel.invoiceamount != null) {
        this.vmodel.totalamount = parseFloat(this.vmodel.invoiceamount);
      } else {
        this.vmodel.totalamount = 0;
      }
      let taxamount = parseFloat(
        parseFloat(this.vmodel.totalamount * this.vmodel.tax) / 100
      );
      this.vmodel.totalamount =
        parseFloat(this.vmodel.totalamount) + parseFloat(taxamount);
    },
    async createInvoice() {
      this.validated = true;
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let sum = 0;
        this.vmodel.overview.forEach((element) => {
          sum += element.price;
        });

        if (sum > this.vmodel.invoiceamount) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Invoice Amount should be greater than Price.",
            life: 3000,
          });
          return;
        }

        if (this.vmodel.duedate < this.vmodel.invoicedate) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Due date should be greater than Invoice date.",
            life: 3000,
          });
          return;
        }

        if (this.vmodel.totalamount < this.paidAmount) {
          this.infoheader = "Invoice Information";
          this.infomessage = `Customer already paid Rs. ${this.paidAmount} for this invoice, you can't edited this invoice.`;
          this.showInfoPopup = true;
          return;
        }
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        this.$root.$globalState.loader = true;
        let payload = {
          customerid: this.vmodel.customerid,
          eventid: this.vmodel.eventid,
          invoicenumber: this.vmodel.invoicenumber,
          invoicedate: this.vmodel.invoicedate,
          invoiceamount: this.vmodel.invoiceamount,
          duedate: this.vmodel.duedate,
          tax: this.vmodel.tax,
          taxno: this.vmodel.taxno,
          totalamount: this.vmodel.totalamount,
          overview: this.vmodel.overview,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/invoice", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Invoice" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
              this.$root.$globalState.loader = false;
            });
        } else {
          APIService.post("/invoice", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });

                setTimeout(() => {
                  this.$router.push({ name: "Invoice" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
              this.$root.$globalState.loader = false;
            });
        }
      }
    },
    getInvoiceById() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      APIService.getById("/invoice", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.customerid = response.data.data.customerid;
            this.vmodel.eventid = response.data.data.eventid;
            this.vmodel.invoicedate = new Date(
              DateFormat.formatDate(response.data.data.invoicedate)
            );
            this.vmodel.invoicenumber = response.data.data.invoicenumber;
            this.vmodel.invoiceamount = response.data.data.invoiceamount;
            this.vmodel.duedate = new Date(
              DateFormat.formatDate(response.data.data.duedate)
            );
            this.vmodel.tax = response.data.data.tax;
            this.vmodel.taxno = response.data.data.taxno;
            this.vmodel.totalamount = response.data.data.totalamount;
            this.vmodel.overview = response.data.data.overview;
            this.paidAmount = response.data.data.paidAmount;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "Invoice" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
          this.$root.$globalState.loader = false;
          this.getEventDetails();
        });
    },
    closePopup() {
      this.showInfoPopup = false;
      setTimeout(() => {
        this.$router.push({ name: "Invoice" });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  border-radius: 8px;
  border: 1px solid #ccc9d6;
  padding: 20px;
  height: auto;
}
.bin-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px !important;
  gap: 8px;
  height: 36px;
  width: 36px !important;
  background: #feeded;
  opacity: 0.4;
  border: 1px solid rgba(241, 71, 71, 0.4);
  border-radius: 4px;
  position: absolute;
  top: 35%;
}

.bin-button:enabled:hover {
  background: #feeded;
  border: 1px solid rgba(241, 71, 71, 0.4);
}
.add-item-button:enabled:hover {
  background: #06253c;
  color: #fff;
  border-color: #06253c;
}
.add-item-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  height: 37px !important;
  width: 37px !important;
  background: #06253c;
  border-radius: 4px;
  border: #06253c;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: #fff;
}
:deep(.description-text .p-inputtext) {
  width: 100%;
}
:deep(.p-inputtext.p-inputnumber-input) {
  width: 100% !important;
}
.set-margin {
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 575.98px) {
  .form-label-show {
    margin-top: 12px;
    margin-bottom: 12px;
    width: 100%;
  }
}
</style>
