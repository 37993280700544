import Recipe from '@/models/recipe'

export default class RecipeFactory {
    static createFromJson(json) {
        return new Recipe(json)
    }

    static createFromJsonArray(jsonArray) {
        const jsonData = []
        jsonArray.forEach(item => {
            jsonData.push(RecipeFactory.createFromJson(item))
        });

        return jsonData
    }
}
