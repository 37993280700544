<template>
  <div class="ml-5 mr-5 set-margin-top mb-5">
    <div class="grid">
      <div class="grid">
        <div class="order-form-title w-full">Setting Configuration</div>
        <div class="order-form-sub-title">{{ subtitle }}</div>
      </div>
      <div class="order-card w-full">
        <div class="field mt-4">
          <div class="grid">
            <div class="col-3">
              <label for="hour" class="form-label"
                >Display Employee available after assign</label
              >
            </div>
            <div class="col-2">
              <InputNumberRequire
                v-model="vmodel.available"
                :validation="v$.vmodel.available"
                sectionClass="field"
                placeholder="Minute"
                id="available"
                :useGrouping="false"
                :min="0"
                suffix=" Minute"
                label="Minute"
                :labelshow="false"
              />
            </div>
          </div>
          <div class="grid mb-2" v-if="$store.state.userInfo.multilanguage">
            <div class="col-3 flex align-items-center">
              <label for="hour" class="form-label"
                >PDF Download - Language</label
              >
            </div>
            <div class="col-4 flex align-items-center">
              <div class="grid m-0 p-0 form-label flex-row align-items-center">
                <label class="col-4">Gujarati Language</label>
                <label class="switch col-4">
                  <Checkbox
                    :binary="true"
                    v-model="vmodel.language"
                    class="p-cart"
                  />
                  <span class="slider round"></span>
                </label>
                <label class="col-4">English Language</label>
              </div>
            </div>
          </div>
          <div class="grid">
            <div class="col-4 flex align-items-center">
              <Checkbox
                :binary="true"
                inputId="mergedpdf"
                v-model="vmodel.mergedpdf"
              /><label for="mergedpdf" class="form-label ml-2"
                >Merge PDF for More than one Events</label
              >
            </div>
          </div>
        </div>
        <div class="mt-4">
          <PrimeButton
            label="Update"
            class="order-form-button"
            @click="onUpdate"
          ></PrimeButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, minValue, maxValue } from "@vuelidate/validators";
import APIService from "@/services/api-service.js";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      submitted: false,
      subtitle: "Update Setting Configuration",
      loading: false,
      vmodel: {
        available: 0,
        mergedpdf: false,
        language: true,
      },
    };
  },
  validations() {
    return {
      vmodel: {
        available: { required, min: minValue(0), max: maxValue(999) },
      },
    };
  },
  mounted() {
    this.getSetting();
  },
  methods: {
    getSetting() {
      this.$root.$globalState.loader = true;
      APIService.get(`/setting`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            for (let data of response.data.data) {
              if (data.name == "availableemployee") {
                this.vmodel.available = data.value;
              }
              if (data.name == "mergedpdf") {
                this.vmodel.mergedpdf = data.value ? true : false;
              }
              if (data.name == "language") {
                this.vmodel.language = data.value ? true : false;
              }
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    async onUpdate() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          availableemployee: this.vmodel.available,
          mergedpdf: this.vmodel.mergedpdf,
          language: this.vmodel.language,
        };
        this.$root.$globalState.loader = true;
        this.submitted = true;
        APIService.put(`/setting`, payload)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
            this.$root.$globalState.loader = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-label {
  font-size: 16px;
  line-height: 24px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ff9f43;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

::v-deep(.p-checkbox.p-cart) {
  display: none;
}

.p-checkbox-checked + .slider {
  background-color: #ff9f43;
}

.p-checkbox:focus + .slider {
  box-shadow: 0 0 1px #ff9f43;
}

.p-checkbox-checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  width: 60px !important;
}

.slider.round:before {
  border-radius: 50%;
}
.pad-check {
  padding: 0px 0px 0px 10px !important;
}
</style>
