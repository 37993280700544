<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="list-margin">
    <DataTable
      :value="invoicelist"
      class="p-datatable-lg p-datatable-customers"
      dataKey="id"
      :rowHover="true"
      :resizableColumns="false"
      v-model:expandedRowGroups="expandedRowGroups"
      expandableRowGroups
      rowGroupMode="subheader"
      groupRowsBy="customername"
      sortField="customername"
      sortMode="single"
      :sortOrder="1"
    >
      <template #header>
        <div class="grid m-0">
          <div class="lg:col-4 col-12 lg:text-left text-center list-header">
            Invoice Report
          </div>

          <div
            class="lg:col-8 col-12 grid lg:justify-content-end justify-content-center m-0"
          ></div>
        </div>
      </template>
      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <template #groupheader="slotProps">
        <span class="vertical-align-middle ml-2 font-bold line-height-3">{{
          slotProps.data.customername
        }}</span>
      </template>
      <Column field="eventname" header="Event Name">
        <template #body="row">
          <div class="flex">
            <span class="margin-username">{{ row.data.eventname }}</span>
          </div>
        </template>
      </Column>
      <Column field="customername" header="Customer">
        <template #body="row">
          <div class="flex">
            <span class="margin-username">{{ row.data.customername }}</span>
          </div>
        </template>
      </Column>
      <Column field="totalamount" header="Total Amount"></Column>
      <Column field="paidamount" header="Paid Amount"></Column>
      <Column field="remainingamount" header="Remaining Amount"></Column>
    </DataTable>
    <Paginator
      v-model:first="currentPage"
      :rows="50"
      :totalRecords="totalRecords"
      :rowsPerPageOptions="[50, 100, 150, 200]"
      template="RowsPerPageDropdown CurrentPageReport PageLinks"
      currentPageReportTemplate="{first} - {last} of {totalRecords} items"
      @page="onPage($event)"
      :class="{
        loading: loading,
      }"
    >
      <template #start> Shown per page : </template>
    </Paginator>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import InvoiceReportFactory from "@/factories/invoiceReport";
export default {
  data() {
    return {
      loading: false,
      minEventDate: new Date(),
      lastDate: new Date(),
      expandedRowGroups: null,
      invoicelist: [],
      currentPage: 0,
      itemsPerPage: 50,
      totalRecords: 0,
    };
  },
  created() {
    let today = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0
    );
    this.minEventDate = today;
    let todaydays = this.minEventDate.getDate();
    let minusdays = todaydays - 30;
    this.lastDate.setDate(minusdays);
    this.dates = [this.lastDate, this.minEventDate];
  },
  mounted() {
    this.getEventList();
  },
  methods: {
    getEventList() {
      this.$root.$globalState.loader = true;
      this.invoicelist = [];
      APIService.get(
        `/event/eventlist/invoice/${this.currentPage}/${this.itemsPerPage}`
      )
        .then((response) => {
          if (response && response.data) {
            this.totalRecords = response.data.totalrecord;
            this.invoicelist = InvoiceReportFactory.createFromJsonArray(
              response.data.data
            );
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    onPage(pageData) {
      this.itemsPerPage = pageData.rows;
      this.currentPage = pageData.first;
      this.getEventList();
    },
    getData() {
      this.currentPage = 0;
      this.getEventList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
@media (min-width: 991.98px) AND (max-width: 1315.98px) {
  .template-view.expand .p-input-icon-right {
    margin-top: 12px;
  }
}
@media (min-width: 991.98px) AND (max-width: 1035.98px) {
  .template-view.not-expand .p-input-icon-right {
    margin-top: 12px;
  }
}
// @media (max-width: 445.98px) {
//   .add-button {
//     margin-right: 0 !important;
//   }
// }
@media (max-width: 991.98px) {
  .add-button {
    margin-right: 12px;
  }
}
@media (max-width: 413.98px) {
  .btn-filter {
    margin-top: 12px;
  }
}
@media (max-width: 363.98px) {
  .btn-filter {
    margin-top: 12px;
    margin-right: 0 !important;
  }
  :deep(.statusdropdown.p-dropdown) {
    margin-top: 12px;
    margin-right: 12px !important;
  }
}
@media (max-width: 495.98px) {
  ::v-deep(.p-datatable.p-datatable-customers .add-button) {
    margin-bottom: 0px;
  }
}
@media (max-width: 626.98px) {
  .p-input-icon-right {
    margin-top: 12px;
  }
}
.btn-filter {
  margin-right: 12px;
  width: 120px;
  justify-content: center;
  align-items: center;
  padding: 12px 24px 12px 16px;
  height: 48px;
  background: #ff9f43;
  border-radius: 8px;
  border-color: #ff9f43;

  .btn-filter-label {
    height: 24px;

    font-family: OpenSans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    letter-spacing: -0.02em;
    color: #ffffff;
    flex: none;
  }
}
.btn-filter:hover {
  background: #ff9f43 !important;
  border-radius: 8px;
  border-color: #ff9f43 !important;
}
// :deep(.statusdropdown.p-dropdown) {
//   margin-right: 12px;
//   width: 120px;
// }
.add-button {
  width: 120px;
  margin-right: 1rem;
}
:deep(.p-datatable.p-datatable-customers .p-datatable-thead tr th.datetime) {
  min-width: 200px;
}
.margin-username {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
