import Companay from '@/models/company'

export default class CompanyFactory {
    static createFromJson(json) {
        return new Companay(json)
    }
    static createFromJsonArray(jsonArray) {
        const jsonData = []
        jsonArray.forEach(item => {
            jsonData.push(CompanyFactory.createFromJson(item))
        })

        return jsonData
    }
}
