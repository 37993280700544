<template>
  <Dialog
    header="Edit Quantity of Recipe / Items"
    modal
    v-model:visible="display"
    :breakpoints="{ '960px': '75vw', '640px': '80vw' }"
    :style="{ width: '60vw' }"
    class="popup"
    :class="{
      blur: this.$root.$globalState.loader,
    }"
    :draggable="false"
    :dismissableMask="true"
    @hide="closePopup"
  >
    <div class="grid">
      <div class="col-12">
        <div class="col-12 m-0 p-0">
          <div class="grid align-items-center m-0 p-0">
            <div class="col-10 p-0 m-0">
              <label for="category" class="form-label"
                >Raw Material / Ingredient with {{ people }} persons</label
              >
            </div>
          </div>
        </div>
        <div id="box" class="box mt-3">
          <div
            class="grid"
            v-for="(materials, index) in vmodel.materials"
            :id="index"
            :key="index"
          >
            <div class="md:col-4 col-12">
              <div class="grid m-0">
                <div class="md:col-10 col-10 p-0">
                  <DropdownCustom
                    v-model="vmodel.materials[index].rowmaterialid"
                    :validation="
                      v$.vmodel.materials.$each.$response.$errors[index]
                        .rowmaterialid[0]
                    "
                    :options="itemList"
                    :filter="true"
                    optionLabel="name"
                    optionValue="id"
                    id="materials"
                    label="Raw Material / Ingredient"
                    :showlabel="false"
                    :maxSelectedLabels="3"
                    placeholder="Select Raw Material / Ingredient"
                    class="w-full"
                    :customfield="true"
                    :validated="validated"
                    @update:modelValue="getItemListDetails(index)"
                    :disabled="true"
                  />
                </div>
              </div>
            </div>
            <div
              class="md:col-2 col-12 flex justify-content-center align-items-center"
            >
              <label v-if="vmodel.materials[index].rowmaterialid">{{
                getRowCategory(vmodel.materials[index].rowmaterialid)
              }}</label>
              <label v-else>-</label>
            </div>
            <div class="md:col-3 col-6 flex md:justify-content-center">
              <div class="grid justify-content-center">
                <div class="col-12 flex justify-content-center p-0">
                  <label for="" class="form-label"></label>
                  <div class="quantity-field">
                    <PrimeButton
                      class="value-button decrease-button"
                      @click="decrement(index)"
                      :class="{
                        invalid:
                          validated &&
                          v$.vmodel.materials.$each.$response.$errors[index]
                            .qty[0],
                      }"
                    >
                      <span class="decrement-icon"></span>
                    </PrimeButton>
                    <InputNumber
                      class="number"
                      v-model="vmodel.materials[index].qty"
                      :minFractionDigits="2"
                      :maxFractionDigits="2"
                      :useGrouping="false"
                      labelClass="hidden"
                      :class="{
                        'p-invalid':
                          validated &&
                          v$.vmodel.materials.$each.$response.$errors[index]
                            .qty[0],
                      }"
                    />
                    <PrimeButton
                      class="value-button increase-button"
                      :class="{
                        invalid:
                          validated &&
                          v$.vmodel.materials.$each.$response.$errors[index]
                            .qty[0],
                      }"
                      @click="increment(index)"
                    >
                      <span class="increment-icon"></span>
                    </PrimeButton>
                  </div>
                </div>
                <div class="col-12 flex justify-content-center p-0">
                  <span
                    v-if="
                      validated &&
                      v$.vmodel.materials.$each.$response.$errors[index].qty[0]
                    "
                    class="p-error text-left mt-2"
                  >
                    Minimum qty allowed is 1
                  </span>
                </div>
              </div>
            </div>
            <div
              class="md:col-2 col-6 flex justify-content-center align-items-center"
            >
              <label v-if="vmodel.materials[index].rowmaterialid">{{
                getUnitOptions(vmodel.materials[index].rowmaterialid)
              }}</label>
              <label v-else>-</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid mx-0">
      <div class="col-12 flex set-button">
        <PrimeButton
          label="SET"
          class="order-form-button"
          @click="createRecipe"
        >
        </PrimeButton>
      </div>
    </div>
  </Dialog>
</template>
<script>
import Dialog from "primevue/dialog";
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, minValue } from "@vuelidate/validators";

export default {
  emits: ["displayNo", "displayYes"],
  components: {
    Dialog,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      display: false,
      submitted: false,
      validated: false,
      itemList: [],
      units: [],
      showMenuPopup: false,
      showRawMaterialPopup: false,
      dragIndex: null,
      pic: "",
      vmodel: {
        materials: [
          {
            rowmaterialid: "",
            rowmaterialname: "",
            qty: 1,
          },
        ],
      },
    };
  },
  validations() {
    if (this.display) {
      if (this.$store.state.userInfo.add_images) {
        return {
          vmodel: {
            materials: {
              $each: helpers.forEach({
                rowmaterialid: { required },
                rowmaterialname: { required },
                qty: { required, minValue: minValue(0.01) },
              }),
            },
          },
        };
      } else {
        return {
          vmodel: {
            materials: {
              $each: helpers.forEach({
                rowmaterialid: { required },
                rowmaterialname: { required },
                qty: { required, minValue: minValue(0.01) },
              }),
            },
          },
        };
      }
    } else {
      return {
        vmodel: {
          materials: {
            $each: helpers.forEach({
              rowmaterialid: {},
              rowmaterialname: {},
              qty: {},
            }),
          },
        },
      };
    }
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
      required: true,
    },
    recipeId: {
      type: String,
      default: null,
      required: true,
    },
    people: { type: Number, default: 1, required: false },
    editeditems: {
      type: Array,
      required: true,
    },
  },
  watch: {
    showPopup: {
      async handler(newValue) {
        if (newValue === true) {
          await this.v$.$reset();
          await this.openPopup();
          await this.getItem();
          await this.getunitDrop();
          if (
            this.recipeId &&
            this.editeditems &&
            this.editeditems.length == 0
          ) {
            await this.getRecipeById(this.recipeId);
          } else {
            this.vmodel.materials = this.editeditems;
          }
          await (document.querySelector("body").style.overflow = "hidden");
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async getRecipeById(recipeId) {
      this.$root.$globalState.loader = true;
      await APIService.getById("/recipe", recipeId)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.materials = response.data.data.materials.map(
              (material) => ({
                ...material,
                qty: material.qty * this.people, // Apply people multiplier
              })
            );
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    onlyDigit($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (this.vmodel.noofperson >= 99999 && keyCode > 47 && keyCode < 58)
        $event.preventDefault();
    },
    async getunitDrop() {
      this.$root.$globalState.loader = true;
      await APIService.get("/unit/dropdown")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.units = response.data.data;
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    getRowCategory(index) {
      return this.itemList
        .filter((x) => x.id === index)
        .map((data) => {
          return data.category;
        })[0];
    },
    getUnitOptions(index) {
      return this.itemList
        .filter((x) => x.id === index)
        .map((data) => {
          return data.qtytype;
        })[0];
    },
    async getItemListDetails(index) {
      let check1 = this.addedItemList.indexOf(
        this.vmodel.materials[index].rowmaterialid
      );
      if (check1 !== -1 && index !== check1) {
        this.vmodel.materials[index].rowmaterialid = "";
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Raw Material / Ingrediants already used.",
          life: 3000,
        });
        return;
      }
      if (this.addedItemList.length > 0) {
        if (this.addedItemList[index]) {
          this.addedItemList[index] =
            this.vmodel.materials[index].rowmaterialid;
        } else {
          this.addedItemList.push(this.vmodel.materials[index].rowmaterialid);
        }
      } else {
        this.addedItemList.push(this.vmodel.materials[index].rowmaterialid);
      }
      await this.itemList
        .filter((x) => x.id === this.vmodel.materials[index].rowmaterialid)
        .map((data) => {
          this.vmodel.materials[index].rowmaterialname = data.name;
          this.vmodel.materials[index].qty = 1;
          this.vmodel.materials[index].qtytype = data.qtytype;
        });
    },
    async getItem() {
      this.$root.$globalState.loader = true;
      await APIService.get("/rowmaterial/row")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.itemList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    increment(index) {
      if (typeof this.vmodel.materials[index].qty !== "number") {
        this.vmodel.materials[index].qty = 0;
      }
      this.vmodel.materials[index].qty =
        parseFloat(this.vmodel.materials[index].qty) + 1;
    },
    decrement(index) {
      if (typeof this.vmodel.materials[index].qty !== "number") {
        this.vmodel.materials[index].qty = 0;
      }
      this.vmodel.materials[index].qty =
        parseFloat(this.vmodel.materials[index].qty) - 1;
    },

    async createRecipe() {
      this.validated = true;
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        this.submitted = true;
        const adjustedMaterials = this.vmodel.materials.map((material) => ({
          ...material,
          recipeid: this.recipeId,
        }));

        await this.$emit("displayYes", adjustedMaterials);
        this.display = false;
        await this.v$.$reset();
        this.addedItemList = [];
        this.submitted = false;
      }
    },
    openPopup() {
      this.display = true;
    },
    async closePopup() {
      this.display = false;
      await this.$emit("displayNo");
      await this.v$.$reset();
      await (document.querySelector("body").style.overflow = "auto");
    },
  },
};
</script>
<style lang="scss" scoped>
.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px;
  gap: 8px;
  height: 36px;
  background: #ff9f43 !important;
  border-radius: 8px;
  border: none;
}
.add-button :hover {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px;
  gap: 8px;
  height: 36px;
  background: #ff9f43 !important;
  border-radius: 8px;
  border: none;
}
:deep(.pi) {
  margin-left: 10px;
}

.box {
  border-radius: 8px;
  border: 1px solid #ccc9d6;
  padding: 20px;
  height: auto;
}
.quantity-field {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 170px;
  border-radius: 4px;
}

.quantity-field .value-button {
  border: 1px solid #ddd;
  margin: 0px;
  width: 40px;
  height: 52px;
  padding: 13px;
  background: #ffffff;
  outline: none;
  cursor: pointer;
}

.quantity-field .value-button:hover {
  background: #ffffff;
}

.quantity-field .value-button:active {
  background: #ffffff;
}

.quantity-field .decrease-button {
  margin-right: -4px;
  border-width: 1px 1px 1px 1px;
  border-radius: 4px 0 0 4px;
  // border-right: 1px solid #e6e3e1 !important;
  width: 50px;
  height: 56px;
}

.quantity-field .increase-button {
  margin-left: -4px;
  border-width: 1px 1px 1px 1px;
  border-radius: 0 4px 4px 0;
  // border-left: 1px solid #e6e3e1 !important;
  // border-left: none;
  width: 50px;
  height: 56px;
}

::v-deep(.quantity-field .number) {
  .p-inputtext {
    display: flex;
    text-align: center;
    border: 1px solid #ddd;
    border-right: none;
    border-left: none;
    // margin: 0px;
    width: 70px;
    height: 56px;
    box-sizing: border-box;
    background: white;
    font-family: Inter;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    // padding-top: 15px;
    // padding-right: 5px;
    // padding-left: 1px;
    color: #141414;
    border-radius: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;
    margin-right: 4px;
    margin-left: 4px;
  }
}

.qty-style {
  width: 36px;
  margin-top: -21px;
  border-radius: unset;
  border-left: none;
  border-right: none;
  padding-left: 11px;
}

.quantity-field .number::selection {
  background: none;
}

.bin-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px !important;
  gap: 8px;
  height: 36px;
  width: 36px !important;
  margin-top: 1px;
  background: #feeded;
  opacity: 0.4;
  border: 1px solid rgba(241, 71, 71, 0.4);
  border-radius: 4px;
}

.bin-button:enabled:hover {
  background: #feeded;
  border: 1px solid rgba(241, 71, 71, 0.4);
}
.add-item-button:enabled:hover {
  background: #06253c;
  color: #fff;
  border-color: #06253c;
}
.add-item-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  height: 37px !important;
  width: 37px !important;
  background: #06253c;
  border-radius: 4px;
  border: #06253c;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: #fff;
}

:deep(.p-multiselect-label) {
  height: 48px !important;
}
</style>
