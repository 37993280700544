import EventType from "@/models/eventtype";

export default class EventTypeFactory {
  static createFromJson(json) {
    return new EventType(json);
  }
  static createFromJsonArray(jsonArray) {
    const jsonData = [];
    jsonArray.forEach((item) => {
      jsonData.push(EventTypeFactory.createFromJson(item));
    });

    return jsonData;
  }
}
