<template>
  <div class="ml-5 mr-5 set-margin-top mb-5">
    <div class="grid">
      <div class="grid">
        <div class="order-form-title w-full">Menu Management</div>
        <div class="order-form-sub-title">{{ subtitle }}</div>
      </div>
      <div class="order-card w-full">
        <div
          class="grid mx-0 flex flex-column"
          v-if="$store.state.userInfo.add_images"
        >
          <label for="photo" class="form-label mb-2">Image</label>
          <div
            class="photo-section cursor-pointer"
            @click="openFileUpload"
            :class="{
              invalid: v$.vmodel.pic.$error,
            }"
          >
            <img
              v-if="pic !== ''"
              :src="pic"
              alt=""
              class="upload-img"
              height="110"
              width="120"
              label="Image"
            />

            <span class="upload-icon" v-if="pic == ''"></span>
            <div class="upload-text" v-if="pic == ''">Upload Image Here</div>
          </div>
          <FileUpload
            ref="photo"
            name="photo"
            accept=".png,.jpg,.jpeg"
            @select="handleFileUpload($event)"
            v-show="false"
            :aria-describedby="`photo-help`"
          />
          <div
            v-if="
              v$.vmodel.pic.$error &&
              v$.vmodel.pic.required &&
              v$.vmodel.pic.required.$invalid
            "
            class="p-error mt-2"
          >
            {{ v$.vmodel.pic.required.$message.replace("Value", "Image") }}
          </div>
        </div>
        <div class="grid mt-2">
          <div class="md:col-6 col-12">
            <InputTextRequire
              v-model="vmodel.name"
              :validation="v$.vmodel.name"
              id="name"
              type="text"
              class="w-full"
              label="Name"
              placeholder="Enter Name"
            />
          </div>
        </div>
        <div class="grid">
          <div class="md:col-6 col-12">
            <InputTextRequire
              v-model="vmodel.notes"
              :validation="v$.vmodel.notes"
              id="notes"
              type="text"
              class="w-full"
              label="Notes"
              placeholder="Enter Notes"
            />
          </div>
        </div>
        <div class="grid mx-0 mt-4">
          <div class="col-12 flex set-button">
            <PrimeButton
              label="Add"
              class="order-form-button"
              v-if="!isEditMode"
              @click="createMenu"
            >
            </PrimeButton>
            <PrimeButton
              label="Edit"
              class="order-form-button"
              v-else
              @click="createMenu"
            >
            </PrimeButton>
            <PrimeButton
              label="Cancel"
              class="form-cancel-button sm:ml-2"
              @click="onCancel"
            ></PrimeButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      submitted: false,
      isEditMode: false,
      loading: false,
      pic: "",
      subtitle: "Add Menu",
      itemList: [],
      vmodel: {
        name: "",
        pic: "",
        notes: "",
      },
    };
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (this.$route.params.id) {
      this.subtitle = "Update Menu";
      this.isEditMode = true;
      this.getMenuById();
    }
    this.$root.$globalState.loader = false;
  },
  validations() {
    if (this.$store.state.userInfo.add_images) {
      return {
        vmodel: {
          name: { required, max: maxLength(30) },
          pic: {
            required,
          },
          notes: { max: maxLength(30) },
        },
      };
    } else {
      return {
        vmodel: {
          name: { required, max: maxLength(30) },
          pic: {},
          notes: { max: maxLength(30) },
        },
      };
    }
  },
  methods: {
    openFileUpload() {
      this.$refs.photo.choose();
    },
    onCancel() {
      this.$router.push({ name: "Menu" });
    },
    async createMenu() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        this.$root.$globalState.loader = true;
        let payload = {
          name: this.vmodel.name,
          pic: this.vmodel.pic,
          notes: this.vmodel.notes,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/menu", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Menu" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
              this.$root.$globalState.loader = false;
            });
        } else {
          APIService.post("/menu", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Menu" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
              this.$root.$globalState.loader = false;
            });
        }
      }
    },
    getMenuById() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      APIService.getById("/menu", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.name = response.data.data.name;
            this.vmodel.pic = response.data.data.pic;
            this.pic = response.data.data.picpath;
            this.vmodel.notes = response.data.data.notes;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "Menu" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
          this.$root.$globalState.loader = false;
        });
    },
    handleFileUpload(fileObj) {
      var file = this.$refs.photo.files[0];
      // const file = event.target.files[0];
      if (
        !fileObj ||
        (fileObj && !fileObj.files) ||
        (fileObj && fileObj.files && fileObj.files.length === 0)
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select valid image",
          life: 3000,
        });
        this.$refs.photo.clear();
        // this.$refs.file.value = null;
      } else if (fileObj && fileObj.files && fileObj.files[0].size > 3145728) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Image size should be less then 3 MB",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.pic = reader.result;
          this.vmodel.pic = reader.result;
        };
        this.$refs.photo.clear();
      }
    },
  },
};
</script>
