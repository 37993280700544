<template>
  <Dialog
    header="Add Main Category"
    modal
    v-model:visible="display"
    :breakpoints="{ '960px': '75vw', '640px': '80vw' }"
    :style="{ width: '36vw' }"
    class="popup"
    :class="{
      blur: this.$root.$globalState.loader,
    }"
    :draggable="false"
    :dismissableMask="true"
    @hide="closePopup"
  >
    <div class="grid">
      <div class="col-12">
        <InputTextRequire
          v-model="vmodel.name"
          :validation="v$.vmodel.name"
          id="categoryname"
          type="text"
          class="w-full"
          placeholder="Enter Name"
          label="Name"
          labelnotshow="false"
        />
      </div>
    </div>
    <div class="grid mx-0">
      <div class="col-12 flex set-button">
        <PrimeButton
          label="Add"
          class="order-form-button"
          @click="createCategory"
        >
        </PrimeButton>
      </div>
    </div>
  </Dialog>
</template>
<script>
import Dialog from "primevue/dialog";
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";

export default {
  emits: ["displayNo","displayYes"],
  components: {
    Dialog,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      display: false,
      submitted: false,
      vmodel: {
        name: "",
      },
    };
  },
  validations() {
    if (this.display) {
      return {
        vmodel: {
          name: { required, max: maxLength(30) },
        },
      };
    } else {
      return {
        vmodel: {
          name: {},
        },
      };
    }
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  watch: {
    showPopup: {
      async handler(newValue) {
        if (newValue === true) {
          await this.v$.$reset();
          await this.openPopup();
          document.querySelector("body").style.overflow = "hidden";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async createCategory() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        this.$root.$globalState.loader = true;
        let payload = {
          name: this.vmodel.name,
        };
        this.submitted = true;
        await APIService.post("/category", payload)
          .then(async (response) => {
            if (response && response.data && response.data.success) {
              this.$emit("displayYes", response.data.data.id);
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
              this.display = false;
              await this.v$.$reset();
              this.vmodel.name = "";
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
            this.$root.$globalState.loader = false;
          });
      }
    },
    openPopup() {
      this.display = true;
    },
    async closePopup() {
      this.display = false;
      await this.$emit("displayNo");
      await this.v$.$reset();
      this.vmodel.name = "";
      document.querySelector("body").style.overflow = "auto";
    },
  },
};
</script>
