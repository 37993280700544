export default class InvoiceReport {
  constructor(data = {}) {
    return {
      id: data.id || "",
      eventname: data.eventname || "",
      customername: data.customername || "",
      totalamount: data.totalamount,
      paidamount: data.paidamount,
      remainingamount: data.remainingamount,
    };
  }
}
