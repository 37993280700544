<template>
  <div class="ml-5 mr-5 set-margin-top mb-5">
    <div class="grid">
      <div class="grid">
        <div class="order-form-title w-full">User Management</div>
        <div class="order-form-sub-title">{{ subtitle }}</div>
      </div>
      <div class="order-card w-full">
        <div class="grid">
          <!-- <div :class="isEditMode ? 'col-6' : 'col-4'"> -->
          <div class="md:col-6 col-12">
            <InputTextRequire
              :validation="v$.vmodel.companyname"
              v-model="vmodel.companyname"
              id="companyname"
              type="text"
              class="w-full"
              placeholder="Enter Name"
              label="Name"
            />
          </div>
          <div class="md:col-6 col-12">
            <!-- <div :class="isEditMode ? 'col-6' : 'col-4'"> -->
            <InputTextRequire
              v-model="vmodel.email"
              :validation="v$.vmodel.email"
              id="email"
              type="text"
              class="w-full"
              label="Email"
              placeholder="Enter Email"
              maxLength="75"
            />
          </div>
        </div>

        <div class="grid">
          <div class="md:col-6 col-12">
            <InputTextRequire
              v-model="vmodel.password"
              :validation="v$.vmodel.password"
              id="password"
              type="text"
              class="w-full"
              label="Password"
              placeholder="Enter Password"
            />
          </div>
          <div class="md:col-6 col-12">
            <InputTextRequire
              v-model="vmodel.contactno"
              :validation="v$.vmodel.contactno"
              type="text"
              id="contactno"
              class="w-full"
              placeholder="Enter Contact Number"
              label="Contact Number"
              maxlength="10"
              minlength="10"
              @keypress="onlyNumber"
            />
          </div>
        </div>
        <div class="grid">
          <div class="col-12">
            <label for="status" class="form-label">Status : </label>
          </div>
          <div class="col-12 flex flex-wrap gap-3">
            <div class="flex align-items-center">
              <RadioButton
                v-model="vmodel.status"
                inputId="active"
                name="status"
                :value="true"
              />
              <label for="active" class="ml-2 form-label">Active</label>
            </div>
            <div class="flex align-items-center">
              <RadioButton
                v-model="vmodel.status"
                inputId="inactive"
                name="status"
                :value="false"
              />
              <label for="inactive" class="ml-2 form-label">Inactive</label>
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="col-12 mt-2">
            <label for="photo" class="form-label block mb-2">Permissions</label>
            <table class="col-12 permission">
              <tr>
                <th>Pages</th>
                <th>Permission</th>
              </tr>
              <tr
                v-for="(permission, index) in vmodel.permissions"
                :class="{
                  hidden:
                    index == 'UserList' ||
                    index == 'ProfileSetting' ||
                    index == 'CompanyList',
                }"
                :id="index"
                :key="index"
              >
                <td>
                  <span class="text-show">{{ index }}</span>
                </td>
                <td>
                  <div class="card flex flex-wrap gap-3">
                    <div
                      class="flex align-items-center"
                      v-for="(action, key) in permission"
                      :key="key"
                      :id="index"
                    >
                      <Checkbox
                        v-model="permission[key]"
                        :binary="true"
                        inputId="dashboard-all"
                        name="dashboard-all"
                        @change="checkpermission(index, key, permission[key])"
                      />
                      <label for="dashboard-all" class="ml-2 text-show">
                        {{ key }}
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="grid mx-0 mt-4">
          <div class="col-12 flex set-button">
            <PrimeButton
              label="Add"
              class="order-form-button"
              v-if="!isEditMode"
              @click="createUser"
            >
            </PrimeButton>
            <PrimeButton
              label="Edit"
              class="order-form-button"
              v-else
              @click="createUser"
            >
            </PrimeButton>
            <PrimeButton
              label="Cancel"
              class="form-cancel-button sm:ml-2"
              @click="onCancel"
            ></PrimeButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/api-service.js";
import getPassword from "@/libs/getPassword";
import { useVuelidate } from "@vuelidate/core";
import {
  maxLength,
  required,
  email,
  minLength,
  numeric,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      loading: true,
      submitted: false,
      isEditMode: false,
      subtitle: "Add User",
      minEventDate: new Date(),
      vmodel: {
        companyname: "",
        email: "",
        password: "",
        contactno: "",
        status: true,
        permissions: {
          Dashboard: {
            all: true,
            view: true,
          },
          Customer: {
            all: true,
            view: true,
            add: true,
            edit: true,
            delete: true,
          },
          Vendor: {
            all: true,
            view: true,
            add: true,
            edit: true,
            delete: true,
          },
          RawMaterialIngredient: {
            all: true,
            view: true,
            add: true,
            edit: true,
            delete: true,
          },
          RecipeItem: {
            all: true,
            view: true,
            add: true,
            edit: true,
            delete: true,
          },
          Menu: {
            all: true,
            view: true,
            add: true,
            edit: true,
            delete: true,
          },
          SubMenu: {
            all: true,
            view: true,
            add: true,
            edit: true,
            delete: true,
          },
          Event: {
            all: true,
            view: true,
            add: true,
            edit: true,
            delete: true,
          },
          Template: {
            all: true,
            view: true,
            add: true,
            edit: true,
            delete: true,
          },
          AboutUs: {
            all: true,
            view: true,
            add: true,
            edit: true,
            delete: true,
          },
          TermsAndConditions: {
            all: true,
            view: true,
            add: true,
            edit: true,
            delete: true,
          },
          Employee: {
            all: true,
            view: true,
            add: true,
            edit: true,
            delete: true,
          },
          Attendance: {
            all: true,
            view: true,
            add: true,
            edit: true,
            delete: true,
          },
          Invoice: {
            all: true,
            view: true,
            add: true,
            edit: true,
            delete: true,
          },
          Payment: {
            all: true,
            view: true,
            add: true,
            edit: true,
            delete: true,
          },
          Cart: {
            all: true,
            view: true,
            add: true,
            edit: true,
            delete: true,
          },
          ProfileSetting: {
            all: true,
            view: true,
            add: true,
            edit: true,
            delete: true,
          },
          UserList: {
            all: false,
            view: false,
            add: false,
            edit: false,
            delete: false,
          },
          CompanyList: {
            all: false,
            view: false,
            add: false,
            edit: false,
            delete: false,
          },
        },
      },
    };
  },
  created() {
    let today = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0
    );
    this.minEventDate = today;
  },
  mounted() {
    if (this.$route.params.id) {
      this.subtitle = "Update User";
      this.isEditMode = true;
      this.getCompanyById();
    }
  },
  validations() {
    return {
      vmodel: {
        companyname: { required, max: maxLength(30) },
        email: { required, email, max: maxLength(75) },
        password: { required, min: minLength(8), max: maxLength(12) },
        contactno: {
          required,
          numeric,
          min: minLength(10),
          max: maxLength(10),
        },
        status: {
          required,
        },
      },
    };
  },

  methods: {
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    onCancel() {
      this.$router.push({ name: "User" });
    },
    openFileUpload() {
      this.$refs.photo.choose();
    },
    async checkpermission(index, key, value) {
      if (index == "Cart") {
        if (value) {
          let keys = Object.keys(this.vmodel.permissions["Dashboard"]);
          for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            this.vmodel.permissions["Dashboard"][key] = true;
          }
        }
      }
      if(index == "Dashboard"){
        if (!value) {
          let keys = Object.keys(this.vmodel.permissions["Cart"]);
          for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            this.vmodel.permissions["Cart"][key] = false;
          }
        } else {
          let keys = Object.keys(this.vmodel.permissions["Cart"]);
          for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            this.vmodel.permissions["Cart"][key] = true;
          }
        }
      }
      if (key == "all") {
        if (value) {
          let keys = Object.keys(this.vmodel.permissions[index]);
          for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (key !== "all") {
              this.vmodel.permissions[index][key] = true;
            }
          }
        } else {
          let keys = Object.keys(this.vmodel.permissions[index]);
          for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (key !== "all") {
              this.vmodel.permissions[index][key] = false;
            }
          }
        }
      } else if (key == "view") {
        if (!value) {
          let keys = Object.keys(this.vmodel.permissions[index]);
          for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (key !== "all" || key !== "view") {
              this.vmodel.permissions[index][key] = false;
            }
          }
        } else {
          let keys = Object.keys(this.vmodel.permissions[index]);
          keys = keys.filter((str) => str != "all");
          let keyValue = [];
          for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            keyValue.push(this.vmodel.permissions[index][key]);
          }
          const allTrue = keyValue.every((value) => value === true);
          if (allTrue) {
            this.vmodel.permissions[index]["all"] = true;
          }
        }
      } else {
        if (value) {
          let keys = Object.keys(this.vmodel.permissions[index]);
          if (!this.vmodel.permissions[index]["view"]) {
            this.vmodel.permissions[index]["view"] = true;
          }
          keys = keys.filter((str) => str != "all");
          let keyValue = [];
          for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            keyValue.push(this.vmodel.permissions[index][key]);
          }
          const allTrue = keyValue.every((value) => value === true);
          if (allTrue) {
            this.vmodel.permissions[index]["all"] = true;
          }
        } else {
          this.vmodel.permissions[index]["all"] = false;
        }
      }
    },
    async createUser() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let password = getPassword.generatePassword(this.vmodel.password);
        let encodedPassword = encodeURIComponent(password);
        let payload = {
          companyname: this.vmodel.companyname,
          email: this.vmodel.email,
          password: encodedPassword,
          contactno: this.vmodel.contactno,
          isactive: this.vmodel.status,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/user", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "User" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/user", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });

                setTimeout(() => {
                  this.$router.push({ name: "User" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },

    getCompanyById() {
      this.$root.$globalState.loader = true;
      APIService.getById("/user", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.companyname = response.data.data.companyname;
            this.vmodel.email = response.data.data.email;
            this.vmodel.contactno = response.data.data.contactno;
            let password = decodeURIComponent(response.data.data.password);
            let decodedPassword = getPassword.getPassword(password);
            this.vmodel.password = decodedPassword;
            this.vmodel.status = response.data.data.isactive;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "User" });
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.text-show {
  text-transform: capitalize;
}
</style>
