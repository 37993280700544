import RowMaterial from '@/models/rowmaterial'

export default class RowMaterialFactory {
    static createFromJson(json) {
        return new RowMaterial(json)
    }
    static createFromJsonArray(jsonArray) {
        const jsonData = []
        jsonArray.forEach(item => {
            jsonData.push(RowMaterialFactory.createFromJson(item))
        })

        return jsonData
    }
}
