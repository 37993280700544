<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="list-margin">
    <DataTable
      :value="customer"
      class="p-datatable-lg p-datatable-customers"
      dataKey="id"
      :rowHover="true"
      :resizableColumns="true"
      @sort="onSort($event)"
    >
      <template #header>
        <div class="grid m-0">
          <div class="col-12 lg:col-6 lg:text-left text-center list-header">
            Customers
          </div>
          <div class="col-12 lg:col-6 lg:text-right text-center">
            <PrimeButton
              label=" ADD "
              class="add-button mr-3"
              icon="plus-icon"
              @click="gotoCreateCustomer"
            ></PrimeButton>
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch" />
              <InputText
                v-model="search"
                placeholder="Search"
                class="search-input"
                @keyup="onSearch"
              />
              <!-- v-on:keyup.enter="onSearch" -->
            </div>
            <PrimeButton
              class="download-button ml-3"
              icon="pi pi-download"
              @click="downloadCSV"
              :disabled="customer.length == 0"
            ></PrimeButton>
          </div>
        </div>
      </template>

      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <Column field="name" header="Name" sortable></Column>
      <Column field="contactno" header="contact number" sortable> </Column>
      <Column field="actions" header="Actions">
        <template #body="data">
          <div class="flex">
            <i
              class="pi pi-pencil cursor-pointer edit-icon"
              style="color: gray"
              @click="gotoMemberEdit(data.data.id)"
            ></i>
            <i
              class="pi pi-trash cursor-pointer delete-icon ml-1"
              style="color: #dc3545"
              @click="showDeletePopup(data.data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <Paginator
      v-model:first="currentPage"
      :rows="50"
      :totalRecords="totalRecords"
      :rowsPerPageOptions="[50, 100, 150, 200]"
      template="RowsPerPageDropdown CurrentPageReport PageLinks"
      currentPageReportTemplate="{first} - {last} of {totalRecords} items"
      @page="onPage($event)"
      :class="{
        loading: loading,
      }"
    >
      <template #start> Shown per page : </template>
    </Paginator>
    <div>
      <ConfirmPopup
        :show-popup="showPopup"
        @confirmYes="deleteMember"
        @confirmNo="showPopup = false"
      ></ConfirmPopup>
    </div>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import CustomerFactory from "@/factories/customer.js";
export default {
  data() {
    return {
      loading: false,
      customer: [],
      customerlist: [],
      totalRecordsStore: 0,
      currentPage: 0,
      itemsPerPage: 50,
      search: "",
      searchText: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      showPopup: false,
      memberIdForDelete: "",
    };
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    this.getMemberList();
  },
  methods: {
    downloadCSV() {
      this.$root.$globalState.loader = true;
      APIService.get(`/customer/downloadcsv`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data.file);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    gotoCreateCustomer() {
      this.$router.push({ name: "CustomerCreate" });
    },
    gotoMemberEdit(memberId) {
      this.$router.push({
        name: "CustomerEdit",
        params: { id: memberId },
      });
    },
    showDeletePopup(memberId) {
      this.memberIdForDelete = memberId;
      this.showPopup = true;
    },
    getMemberList() {
      this.$root.$globalState.loader = true;
      this.customer = [];
      APIService.get(
        `/customer/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${this.searchText}`
      )
        .then((response) => {
          if (response && response.data) {
            this.totalRecords = response.data.totalrecord;
            this.customer = CustomerFactory.createFromJsonArray(
              response.data.data
            );
            if (this.search.length == 0) {
              this.customerlist = CustomerFactory.createFromJsonArray(
                response.data.data
              );
              this.totalRecordsStore = response.data.totalrecord;
            }
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    deleteMember() {
      this.$root.$globalState.loader = true;
      APIService.delete(`/customer/delete/${this.memberIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getMemberList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.showPopup = false;
        });
    },
    onPage(pageData) {
      this.itemsPerPage = pageData.rows;
      this.currentPage = pageData.first;
      this.getMemberList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getMemberList();
    },
    onSearch() {
      let search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      if (search.length >= 2) {
        this.searchText = this.search.trimStart().trimEnd();
        this.getMemberList();
      } else if (search.length == 0) {
        this.searchText = this.search.trimStart().trimEnd();
        this.getMemberList();
      } else if (search.length < 2) {
        this.searchText = "";
        this.customer = this.customerlist;
        this.totalRecords = this.totalRecordsStore;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
</style>
