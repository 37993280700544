import Submenu from '@/models/submenu'

export default class SubmenuFactory {
    static createFromJson(json) {
        return new Submenu(json)
    }
    static createFromJsonArray(jsonArray) {
        const jsonData = []
        jsonArray.forEach(item => {
            jsonData.push(SubmenuFactory.createFromJson(item))
        })

        return jsonData
    }
}
