<template>
  <Dialog
    header="Add Submenu"
    modal
    v-model:visible="display"
    :breakpoints="{ '960px': '75vw', '640px': '80vw' }"
    :style="{ width: '50vw' }"
    class="popup"
    :class="{
      blur: this.$root.$globalState.loader,
    }"
    :draggable="false"
    :dismissableMask="true"
    @hide="closePopup"
  >
    <div
      class="grid mx-0 flex flex-column"
      v-if="$store.state.userInfo.add_images"
    >
      <label for="photo" class="form-label mb-2">Image</label>
      <div
        class="photo-section cursor-pointer"
        @click="openFileUpload"
        :class="{
          invalid: v$.vmodel.pic.$error,
        }"
      >
        <img
          v-if="pic !== ''"
          :src="pic"
          alt=""
          class="upload-img"
          height="110"
          width="120"
          label="Image"
        />

        <span class="upload-icon" v-if="pic == ''"></span>
        <div class="upload-text" v-if="pic == ''">Upload Image Here</div>
      </div>
      <FileUpload
        ref="photo"
        name="photo"
        accept=".png,.jpg,.jpeg"
        @select="handleFileUpload($event)"
        v-show="false"
        :aria-describedby="`photo-help`"
      />
      <div
        v-if="
          v$.vmodel.pic.$error &&
          v$.vmodel.pic.required &&
          v$.vmodel.pic.required.$invalid
        "
        class="p-error mt-2"
      >
        {{ v$.vmodel.pic.required.$message.replace("Value", "Image") }}
      </div>
    </div>
    <div class="grid col-12">
      <div class="md:col-5 sm:col-11 col-10">
        <DropDownRequire
          :validation="v$.vmodel.menuid"
          v-model="vmodel.menuid"
          :options="menuOption"
          :filter="true"
          id="menuOption"
          placeholder="Select Menu"
          label="Menu"
          optionLabel="name"
          optionValue="id"
        />
      </div>
      <div class="sm:col-1 col-2 p-0 mt-5 flex justify-content-center">
        <PrimeButton
          class="download-button"
          icon="pi pi-plus"
          @click="showMenuPopup = true"
        ></PrimeButton>
      </div>
      <div class="md:col-6 col-12">
        <InputTextRequire
          :validation="v$.vmodel.name"
          v-model="vmodel.name"
          id="name"
          type="text"
          class="w-full"
          label="Name"
          placeholder="Enter Name"
        />
      </div>
    </div>
    <div class="grid">
      <div class="md:col-6 col-12">
        <InputTextRequire
          v-model="vmodel.notes"
          :validation="v$.vmodel.notes"
          id="notes"
          type="text"
          class="w-full"
          label="Notes"
          placeholder="Enter Notes"
        />
      </div>
    </div>
    <div class="grid mx-0">
      <div class="col-12 flex set-button">
        <PrimeButton
          label="Add"
          class="order-form-button"
          @click="createSubMenu"
        >
        </PrimeButton>
      </div>
    </div>
  </Dialog>
  <MenuAddPopup
    :show-popup="showMenuPopup"
    @displayNo="closeMenuPopup()"
    @displayYes="checkMenu"
  ></MenuAddPopup>
</template>
<script>
import Dialog from "primevue/dialog";
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";

export default {
  components: {
    Dialog,
  },
  emits: ["displayNo","displayYes"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      display: false,
      submitted: false,
      itemList: [],
      menuOption: [],
      showMenuPopup: false,
      pic: "",
      vmodel: {
        name: "",
        menuid: "",
        pic: "",
        notes: "",
      },
    };
  },
  validations() {
    if (this.display) {
      if (this.$store.state.userInfo.add_images) {
        return {
          vmodel: {
            name: { required, max: maxLength(30) },
            menuid: { required },
            pic: { required },
            notes: { max: maxLength(30) },
          },
        };
      } else {
        return {
          vmodel: {
            name: { required, max: maxLength(30) },
            menuid: { required },
            pic: {},
            notes: { max: maxLength(30) },
          },
        };
      }
    } else {
      return {
        vmodel: {
          name: {},
          menuid: {},
          pic: {},
          notes: {},
        },
      };
    }
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  watch: {
    showPopup: {
      async handler(newValue) {
        if (newValue === true) {
          await this.v$.$reset();
          await this.openPopup();
          await this.getmenu();
          document.querySelector("body").style.overflow = "hidden";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async getmenu() {
      this.$root.$globalState.loader = true;
      await APIService.get("/menu/dropdown")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.menuOption = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
          document.querySelector("body").style.overflow = "hidden";
        });
    },
    closeMenuPopup() {
      this.showMenuPopup = false;
    },
    async checkMenu(data) {
      this.showMenuPopup = false;
      this.vmodel.menuid = data;
      await this.getmenu();
    },
    async createSubMenu() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        this.$root.$globalState.loader = true;
        let payload = {
          name: this.vmodel.name,
          menuid: this.vmodel.menuid,
          pic: this.vmodel.pic,
          notes: this.vmodel.notes,
        };
        this.submitted = true;
        APIService.post("/submenu", payload)
          .then(async (response) => {
            if (response && response.data && response.data.success) {
              await this.$emit("displayYes", response.data.data.id);
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
              this.display = false;
              await this.v$.$reset();
              this.vmodel.name = "";
              this.vmodel.menuid = "";
              this.vmodel.pic = "";
              this.vmodel.notes = "";
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
            this.$root.$globalState.loader = false;
          });
      }
    },
    openPopup() {
      this.display = true;
    },
    async closePopup() {
      this.display = false;
      await this.$emit("displayNo");
      await this.v$.$reset();
      this.vmodel.name = "";
      this.vmodel.menuid = "";
      this.vmodel.pic = "";
      this.vmodel.notes = "";
      document.querySelector("body").style.overflow = "auto";
    },
  },
};
</script>
