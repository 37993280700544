<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="list-margin">
    <div class="grid">
      <div class="mt-3 md:col-4 col-12 md:text-left text-center">
        <label class="form-label" style="font-size: 17px">
          Name : {{ name }} - {{ customercontactno }}
        </label>
      </div>
      <div class="mt-3 md:col-4 col-12 md:text-left text-center">
        <label class="form-label" style="font-size: 17px">
          Date : {{ date }} {{ time }}
        </label>
      </div>
      <div class="mt-3 md:col-4 col-12 md:text-left text-center">
        <label class="form-label" style="font-size: 17px">
          Venue : {{ venue }}</label
        >
      </div>
    </div>
    <DataTable
      :value="employee"
      class="p-datatable-lg p-datatable-customers"
      dataKey="id"
      :rowHover="true"
      :resizableColumns="true"
      @sort="onSort($event)"
      v-model:selection="selectedEmployee"
      :rowClass="rowClass"
    >
      <template #header>
        <div class="grid m-0">
          <div class="col-12 lg:col-6 lg:text-left text-center list-header">
            {{ eventname }}
          </div>
          <div class="col-12 lg:col-6 lg:text-right text-center">
            <PrimeButton
              label="Assign Employee"
              class="add-button mr-3"
              icon="pi pi-arrow-up-left"
              @click="assignEmployee"
              :disabled="
                selectedEmployee == null || selectedEmployee.length == 0
              "
            ></PrimeButton>
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch" />
              <InputText
                v-model="search"
                placeholder="Search"
                class="search-input"
              />
            </div>
          </div>
        </div>
      </template>

      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
      <Column field="firstname" header="First Name" sortable></Column>
      <Column field="lastname" header="Last Name" sortable> </Column>
      <Column field="role" header="Role" sortable></Column>
      <Column field="available" header="Status" sortable>
        <template #body="data">
          <Chip
            v-if="data.data.available"
            label="Available"
            class="chip-style-for-order-confirm"
          />
          <Chip
            v-if="!data.data.available"
            label="Not Available"
            class="chip-style-for-order-cancel"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import EmployeeAssignFactory from "@/factories/employeeAssign";
import DateFormat from "@/libs/DateFormat";
export default {
  data() {
    return {
      employee: [],
      search: "",
      sortColumn: "id",
      sortDir: "asc",
      selectedEmployee: null,
      metaKey: true,
      name: "",
      customercontactno: "",
      date: "",
      time: "",
      venue: "",
      eventname: "",
    };
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    this.getEventById();
    this.getEmployeeList();
  },
  methods: {
    rowClass(data) {
      return data.available ? "" : "available";
    },
    async assignEmployee() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      let employee = this.selectedEmployee.map((emp) => emp.id);
      let assignedEmployee = employee.toString();
      let payload = {
        employees: assignedEmployee,
      };
      if (this.$route.params.id) {
        APIService.post(`/employeeassign/${this.$route.params.id}`, payload)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.$root.$globalState.loader = false;
          });
      }
    },
    getEventById() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      APIService.getById("/event", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.name = response.data.data.customername;
            this.customercontactno = response.data.data.customercontactno;
            this.eventname = response.data.data.eventname;
            this.venue = response.data.data.venue;
            this.time = response.data.data.time;
            this.date = DateFormat.formatDate(response.data.data.eventdate);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "Event" });
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    getEmployeeList() {
      this.$root.$globalState.loader = true;
      this.employee = [];
      APIService.get(
        `/employee/selectionEmployee/${this.$route.params.id}/${this.sortColumn}/${this.sortDir}/${this.search}`
      )
        .then((response) => {
          if (response && response.data) {
            this.employee = EmployeeAssignFactory.createFromJsonArray(
              response.data.data
            );
            this.selectedEmployee = response.data.assignedEmployee;
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    deleteEmployee() {
      this.$root.$globalState.loader = true;
      APIService.delete(`/employee/delete/${this.employeeIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getEmployeeList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.showPopup = false;
        });
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getEmployeeList();
    },
    onPage(pageData) {
      this.itemsPerPage = pageData.rows;
      this.currentPage = pageData.first;
      this.getEmployeeList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getEmployeeList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
.p-chip.chip-style-for-order-cancel {
  width: max-content;
}
::v-deep(.available .p-selection-column .p-checkbox) {
  display: none !important;
}
.add-button {
  gap: 8px;
}
</style>
