<template>
  <div class="ml-5 mr-5 mt-5 mb-5">
    <div class="grid">
      <div class="flex order-form-title">
        {{ title }}
      </div>
      <div class="order-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="md:col-4 col-12">
              <div class="order-form-sub-title">About Us Page</div>
            </div>
            <div class="md:col-8 col-12">
              <span class="content">Content</span>
              <div v-if="dataLoaded">
                <EditorRequire
                  v-model="vmodel.content"
                  :validation="v$.vmodel.content"
                />
              </div>
              <div class="grid mx-0 mt-4">
                <div class="col-12 flex set-button">
                  <PrimeButton
                    label="Edit"
                    class="order-form-button"
                    @click="aboutUsEdit"
                  ></PrimeButton>
                  <PrimeButton
                    label="Cancel"
                    class="form-cancel-button sm:ml-2"
                    @click="onCancel"
                  ></PrimeButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { maxLength } from "@vuelidate/validators";
import APIService from "@/services/api-service.js";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      title: "About Us",
      dataLoaded: false,
      vmodel: {
        content: "",
      },
    };
  },
  validations() {
    return {
      vmodel: {
        content: { max: maxLength(1000) },
      },
    };
  },
  mounted() {
    this.$root.$globalState.loader = true;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (this.$route.name) {
      this.getAboutUsId();
    } else {
      this.$root.$globalState.loader = false;
      this.dataLoaded = true;
    }
  },
  methods: {
    onCancel() {
      this.$router.go(-1);
    },
    async getAboutUsId() {
      this.$root.$globalState.loader = true;
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      APIService.getById("/cmspages", this.$route.name)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.content = response.data.data.content;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
          this.dataLoaded = true;
          this.$root.$globalState.loader = false;
        });
    },
    async aboutUsEdit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        this.$root.$globalState.loader = true;
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        let payload = {
          content: this.vmodel.content,
        };
        if (this.$route.name) {
          APIService.patch("/cmspages", payload, this.$route.name)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
              this.$root.$globalState.loader = false;
            });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.order-form-sub-title {
  margin-left: 0;
}
</style>
