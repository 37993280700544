<template>
  <div class="ml-5 mr-5 set-margin-top mb-5">
    <div class="grid">
      <div class="grid">
        <div class="order-form-title w-full">Payment Management</div>
        <div class="order-form-sub-title">{{ subtitle }}</div>
      </div>
      <div class="order-card w-full">
        <div class="grid">
          <div class="md:col-6 col-12">
            <InputTextRequire
              type="text"
              v-model="vmodel.paymentid"
              :validation="v$.vmodel.paymentid"
              id="paymentnumber"
              class="w-full"
              label="Payment Receipt Number (Auto Generated)"
              placeholder="Enter Payment Receipt Number"
              :disabled="true"
            />
          </div>

          <div class="md:col-6 col-12">
            <label for="paymentdate" class="form-label"> Payment Date </label>
            <Calendar
              v-model="vmodel.paymentdate"
              id="paymentdate"
              type="text"
              class="w-full mt-2 mb-2"
              label="Payment Date"
              placeholder="Enter Payment Date"
              showIcon
              dateFormat="yy-mm-dd"
              :class="{
                'p-invalid': v$.vmodel.paymentdate.$error,
              }"
              :disabled="isEditMode"
            />
            <!-- :maxDate="minEventDate" -->
            <span
              v-if="
                v$.vmodel.paymentdate.$error &&
                v$.vmodel.paymentdate.required.$invalid
              "
              id="invoicedate-help"
              class="p-error text-left mt-4"
            >
              Payment Date is required
            </span>
          </div>
        </div>
        <div class="grid">
          <div class="md:col-6 col-12">
            <label for="invoicenumber" class="form-label">Invoice Number</label>
            <Dropdown
              v-model="vmodel.invoiceid"
              filter
              :options="eventOption"
              id="invoicenumber"
              placeholder="Invoice Number"
              label="Invoice Number"
              optionLabel="invoicenumber"
              optionValue="id"
              :class="{
                'p-invalid': v$.vmodel.invoiceid.$error,
              }"
              class="w-full mt-2"
              :disabled="isEditMode"
              @update:modelValue="getInvoiceDetails()"
            />
            <span
              v-if="
                v$.vmodel.invoiceid.$error &&
                v$.vmodel.invoiceid.required.$invalid
              "
              id="invoicenumber-help"
              class="p-error text-left mt-2"
              style="display: block"
            >
              Invoice Number is required
            </span>
          </div>
          <div class="md:col-6 col-12">
            <label for="category" class="form-label">Details</label>
            <div class="box mt-2">
              <div class="grid m-0">
                <label class="form-label">Invoice Date :</label>
                <label class="form-label sm:mx-2 form-label-show">{{
                  invoiceDetails.invoicedate
                }}</label>
              </div>
              <div class="grid m-0">
                <label class="form-label">Due Date :</label>
                <label class="form-label sm:mx-2 form-label-show">{{
                  invoiceDetails.duedate
                }}</label>
              </div>
              <div class="grid m-0">
                <label class="form-label">Event Name :</label>
                <label class="form-label sm:mx-2 form-label-show set-margin">{{
                  invoiceDetails.eventname
                }}</label>
              </div>
              <div class="grid m-0">
                <label class="form-label">Customer Name :</label>
                <label class="form-label sm:mx-2 form-label-show set-margin">{{
                  invoiceDetails.customername
                }}</label>
              </div>
              <div class="grid m-0">
                <label class="form-label">Customer Contact No :</label>
                <label class="form-label sm:mx-2 form-label-show">{{
                  invoiceDetails.customercontactno
                }}</label>
              </div>
              <div class="grid m-0">
                <label class="form-label">Total Amount :</label>
                <label class="form-label sm:mx-2 form-label-show">{{
                  invoiceDetails.totalamount
                }}</label>
              </div>
              <div class="grid m-0">
                <label class="form-label">Remaining Payment Amount :</label>
                <label class="form-label sm:mx-2 form-label-show">{{
                  invoiceDetails.remainingAmount
                }}</label>
              </div>
              <div class="grid m-0">
                <label class="form-label">Event Date and Time :</label>
                <label class="form-label sm:mx-2 form-label-show">{{
                  invoiceDetails.eventdate
                }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="md:col-6 col-12">
            <DropDownRequire
              v-model="vmodel.paymenttype"
              :validation="v$.vmodel.paymenttype"
              :options="paymentOption"
              id="invoicenumber"
              :filter="true"
              placeholder="Payment Type"
              label="Payment Type"
              optionLabel="Label"
              optionValue="type"
              :disabled="isEditMode"
            />
          </div>
          <div class="md:col-6 col-12">
            <label for="invoicenumber" class="form-label">Payment Amount</label>
            <InputNumber
              type="text"
              v-model="vmodel.amount"
              id="amount"
              class="w-full mt-2"
              :min="1"
              :useGrouping="false"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              placeholder="Enter Payment Amount"
              :disabled="isEditMode"
              :class="{
                'p-invalid': v$.vmodel.amount.$error,
              }"
            />
            <span
              v-if="
                v$.vmodel.amount.$error && v$.vmodel.amount.required.$invalid
              "
              id="amount-help"
              class="p-error text-left mt-2"
              style="display: block"
            >
              Payment Amount is required
            </span>
            <span
              v-else-if="
                v$.vmodel.amount?.$error &&
                v$.vmodel.amount?.minValue &&
                v$.vmodel.amount?.minValue.$invalid
              "
              id="amount-help"
              class="p-error text-left mt-2"
              style="display: block"
            >
              {{ v$.vmodel.amount?.minValue.$message }}
            </span>
            <span
              v-else-if="
                v$.vmodel.amount?.$error &&
                v$.vmodel.amount?.maxValue &&
                v$.vmodel.amount?.maxValue.$invalid
              "
              id="amount-help"
              class="p-error text-left mt-2"
              style="display: block"
            >
              {{ v$.vmodel.amount?.maxValue.$message }}
            </span>
          </div>
        </div>
        <div class="grid">
          <div class="md:col-6 col-12">
            <InputTextRequire
              type="text"
              v-model="vmodel.referenceno"
              :validation="v$.vmodel.referenceno"
              id="referenceno"
              class="w-full"
              label="Reference Number"
              placeholder="Enter Reference Number"
              :disabled="isEditMode"
              @keypress="onlyNumber"
            />
          </div>

          <div class="md:col-6 col-12">
            <InputTextRequire
              type="text"
              v-model="vmodel.receiveby"
              :validation="v$.vmodel.receiveby"
              id="paymentnumber"
              class="w-full"
              label="Receive By"
              placeholder="Enter Receive By Name"
              :disabled="isEditMode"
              @keypress="onlyCharacter"
            />
          </div>
        </div>
        <div class="grid mx-0 mt-4">
          <div class="col-12 flex set-button">
            <PrimeButton
              label="Add"
              class="order-form-button"
              v-if="!isEditMode"
              @click="createPayment"
            >
            </PrimeButton>
            <PrimeButton
              label="Cancel"
              class="form-cancel-button"
              :class="!isEditMode ? 'sm:ml-2' : ''"
              @click="onCancel"
            ></PrimeButton>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <ConfirmPopup
      :show-popup="showPopup"
      :message="message"
      :header="header"
      @confirmYes="addPaymentSuccess"
      @confirmNo="showPopup = false"
    ></ConfirmPopup>
    <InfoPopup
      :show-popup="showInfoPopup"
      :message="infomessage"
      :header="infoheader"
      @displayNo="closePopup()"
    ></InfoPopup>
  </div>
  <!-- </div> -->
  <!-- </div>
  </div> -->
</template>

<script>
import APIService from "@/services/api-service.js";
import DateFormat from "@/libs/DateFormat";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  maxLength,
  minValue,
  maxValue,
  minLength,
  numeric,
  requiredIf,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      subtitle: "Add Payment",
      customerOption: [],
      eventOption: [],
      minEventDate: new Date(),
      showPopup: false,
      showInfoPopup: false,
      loading: false,
      header: "Add Payment confirmation",
      message: "Are you sure to make payment? This is not edited.",
      infoheader: "",
      infomessage: "",
      invoiceDetails: {
        invoicenumber: "",
        invoicedate: "",
        duedate: "",
        eventname: "",
        eventvenue: "",
        customername: "",
        customercontactno: "",
        totalamount: "",
        remainingAmount: "",
        eventdate: "",
      },
      paymentOption: [
        {
          type: "Cash",
          Label: "Cash",
        },
        {
          type: "Online",
          Label: "Online",
        },
      ],
      validated: false,
      isEditMode: false,
      vmodel: {
        paymentid: "",
        paymenttype: "",
        eventid: "",
        paymentdate: new Date(),
        invoiceid: "",
        amount: 0,
        referenceno: "",
        receiveby: "",
      },
    };
  },
  validations() {
    return {
      vmodel: {
        paymentid: { required },
        paymenttype: { required },
        eventid: { required },
        paymentdate: { required },
        invoiceid: { required },
        amount: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(9999999.99),
        },
        referenceno: {
          requiredIf: requiredIf((items, index) => {
            return index.paymenttype !== "Cash";
          }),
          numeric,
          min: minLength(1),
          max: maxLength(25),
        },
        receiveby: { required, max: maxLength(30) },
      },
    };
  },
  created() {
    let today = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0
    );
    this.minEventDate = today;
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (this.$route.params.id) {
      this.subtitle = "Update Payment";
      this.isEditMode = true;
      this.getPaymentById();
    } else {
      this.$root.$globalState.loader = false;
      this.vmodel.paymentid = Math.floor(
        10000000 + Math.random() * 90000000
      ).toString();
    }
    this.getInvoiceDropdown();
  },
  methods: {
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    onlyCharacter($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        (keyCode < 65 || keyCode > 90) &&
        (keyCode < 97 || keyCode > 123) &&
        keyCode != 32
      ) {
        $event.preventDefault();
      }
    },
    onCancel() {
      this.$router.push({ name: "Payment" });
    },
    getInvoiceDetails() {
      this.$root.$globalState.loader = true;
      if (!this.isEditMode) {
        this.vmodel.amount = 0;
      }
      APIService.getById("/invoice", this.vmodel.invoiceid)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.invoiceDetails.invoicedate = DateFormat.formatDate(
              response.data.data.invoicedate
            );
            this.invoiceDetails.invoicenumber =
              response.data.data.invoicenumber;
            this.invoiceDetails.duedate = DateFormat.formatDate(
              response.data.data.duedate
            );
            this.invoiceDetails.eventname = response.data.data.eventname;
            this.invoiceDetails.customername = response.data.data.customername;
            this.invoiceDetails.customercontactno =
              response.data.data.customercontactno;
            this.invoiceDetails.totalamount = response.data.data.totalamount;
            this.invoiceDetails.remainingAmount =
              response.data.data.remainingAmount;
            this.invoiceDetails.eventdate = DateFormat.formatDateTime(
              response.data.data.eventdate
            );
            this.vmodel.eventid = response.data.data.eventid;

            if (response.data.data.remainingAmount == 0 && !this.isEditMode) {
              this.infoheader = "Payment Information";
              this.infomessage = `No any remaining payment amount for invoice number - ${this.invoiceDetails.invoicenumber}.`;
              this.showInfoPopup = true;
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "Payment" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
          this.$root.$globalState.loader = false;
        });
    },
    getInvoiceDropdown() {
      this.$root.$globalState.loader = true;
      APIService.get("/invoice/dropdown")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.eventOption = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    async addPaymentSuccess() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      let payload = {
        paymentid: this.vmodel.paymentid,
        paymenttype: this.vmodel.paymenttype,
        eventid: this.vmodel.eventid,
        paymentdate: this.vmodel.paymentdate,
        invoiceid: this.vmodel.invoiceid,
        amount: this.vmodel.amount,
        referenceno: this.vmodel.referenceno,
        receiveby: this.vmodel.receiveby,
      };
      this.submitted = true;
      APIService.post("/payment", payload)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: response.data.message,
              life: 3000,
            });

            setTimeout(() => {
              this.$router.push({ name: "Payment" });
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
          this.showPopup = false;
          this.$root.$globalState.loader = false;
        });
    },
    closePopup() {
      this.showInfoPopup = false;
      setTimeout(() => {
        this.$router.push({ name: "Payment" });
      });
    },
    async createPayment() {
      this.validated = true;
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let invoicedate = new Date(this.invoiceDetails.invoicedate);
        invoicedate = new Date(
          invoicedate.getFullYear(),
          invoicedate.getMonth(),
          invoicedate.getDate(),
          0,
          0,
          0
        );
        if (this.vmodel.paymentdate < invoicedate) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Payment Date should not be less than invoice Date.",
            life: 3000,
          });
          this.showPopup = false;
          return;
        }

        if (
          !this.vmodel.referenceno.match(/^[0-9\s]+$/) &&
          this.vmodel.paymenttype !== "Cash"
        ) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Reference Number only allow numeric.",
            life: 3000,
          });
          this.showPopup = false;
          return;
        }

        if (!this.vmodel.receiveby.match(/^[A-Za-z\s]+$/)) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Receive By only allow alphabetic characters.",
            life: 3000,
          });
          this.showPopup = false;
          return;
        }

        if (this.vmodel.amount > this.invoiceDetails.remainingAmount) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail:
              "Payment Amount should be less than Remaining Payment Amount.",
            life: 3000,
          });
          this.showPopup = false;
          return;
        }
        this.showPopup = true;
      }
    },
    getPaymentById() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      APIService.getById("/payment", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.paymentid = response.data.data.paymentid;
            this.vmodel.paymenttype = response.data.data.paymenttype;
            this.vmodel.eventid = response.data.data.eventid;
            this.vmodel.paymentdate = new Date(
              DateFormat.formatDate(response.data.data.paymentdate)
            );
            this.vmodel.invoiceid = response.data.data.invoiceid;
            this.vmodel.amount = response.data.data.amount;
            this.vmodel.referenceno = response.data.data.referenceno;
            this.vmodel.receiveby = response.data.data.receiveby;
            this.getInvoiceDetails();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "Payment" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
          this.$root.$globalState.loader = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  border-radius: 8px;
  border: 1px solid #ccc9d6;
  padding: 20px;
  height: auto;
}
.set-margin {
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 575.98px) {
  .form-label-show {
    margin-top: 12px;
    margin-bottom: 12px;
    width: 100%;
  }
}
</style>
