<template>
  <div class="card spinner" v-if="loading">
    <ProgressSpinner />
  </div>
  <div
    v-prevent-right-click
    :class="{
      blur: loading,
    }"
  >
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    "$root.$globalState.loader": {
      handler(newValue) {
        document.querySelector("body").style.overflow =
          newValue == true ? "hidden" : "auto";
        this.loading = newValue;
      },
      immediate: true,
    },
  },
};
</script>
