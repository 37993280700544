<template>
  <div :class="!showlabel ? '' : sectionClass">
    <label :for="id" class="form-label" v-if="showlabel">{{ label }}</label>
    <Dropdown
      :id="id"
      :modelValue="modelValue"
      :placeholder="placeholder"
      :options="options"
      :optionLabel="optionLabel"
      :optionValue="optionValue"
      :filter="filter"
      :disabled="disabled"
      class="w-full"
      dropdownicon
      :class="{
        'p-invalid':
          (validated && customfield && validation) ||
          (validation && validation.$error),
      }"
      @change="$emit('update:modelValue', $event.value)"
    />
    <span
      v-if="
        validated &&
        customfield &&
        validation &&
        validation.$params.type == 'requiredIf'
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.$message.replace("The value", this.label) }}
    </span>
    <span
      v-else-if="
        (validated && customfield && validation) ||
        (validation &&
          validation.$error &&
          validation.required.$invalid &&
          validation.required)
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{
        validated && customfield && validation
          ? validation.$message.replace("Value", this.label)
          : validation.required.$message.replace("Value", this.label)
      }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    labelClass: {
      type: String,
      default: "form-label",
    },
    sectionClass: {
      type: String,
      default: "field",
    },
    id: {
      type: String,
      required: true,
      default: "",
    },
    label: {
      type: String,
      required: true,
      default: "",
    },
    validation: {
      type: Object,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String,
      default: "",
    },
    optionLabel: {
      type: String,
      default: "",
    },
    optionValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customfield: {
      type: Boolean,
      default: false,
    },
    validated: {
      type: Boolean,
      default: false,
    },
    showlabel: {
      type: Boolean,
      default: true,
    },
    filter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
