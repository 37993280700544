<template>
  <div class="ml-5 mr-5 set-margin-top mb-5">
    <div class="grid">
      <div class="grid">
        <div class="order-form-title w-full">Profile Setting</div>
        <div class="order-form-sub-title">Update Profile</div>
      </div>

      <div class="order-card w-full">
        <!-- <div class="col-12">
          <div class="grid col-6">
            <span class="p-input-icon-right w-full">
              <i
                :class="
                  showCurrentPassword
                    ? 'password-hide-icon cursor-pointer'
                    : 'eye-icon cursor-pointer'
                "
                @click="showCurrentPassword = !showCurrentPassword"
              />
              <InputTextRequire
                label="Current Password"
                id="password"
                placeholder="Current Password"
                :type="currentPasswordInputType"
                v-model="vmodel.current_password"
              />
            </span>
          </div>
        </div>
        <div class="grid col-12">
          <div class="col-6">
            <span class="p-input-icon-right w-full">
              <i
                :class="
                  showConfirmPassword
                    ? 'password-hide-icon cursor-pointer'
                    : 'eye-icon cursor-pointer'
                "
                @click="showConfirmPassword = !showConfirmPassword"
              />
              <InputTextRequire
                id="password"
                placeholder="New Password"
                label="New Password"
                :type="newPasswordInputType"
                class="w-full"
                v-model="vmodel.new_password"
                aria-describedby="new_password-help"
              />
            </span>
          </div>
        </div>
        <div class="grid col-12">
          <div class="col-6">
            <span class="p-input-icon-right w-full">
              <i
                :class="
                  showConfirmPassword
                    ? 'password-hide-icon cursor-pointer'
                    : 'eye-icon cursor-pointer'
                "
                @click="showConfirmPassword = !showConfirmPassword"
              />
              <InputTextRequire
                id="password"
                placeholder="Confirm New Password"
                :type="confirmPasswordInputType"
                label="Confirm New Password"
                v-model="vmodel.confirm_password"
                aria-describedby="confirm_password-help"
              />
            </span>
          </div>
        </div> -->
        <div class="grid">
          <div class="field md:col-6 col-12">
            <div class="flex-row">
              <label for="password" class="form-label float-left"
                >Current Password</label
              >
            </div>
            <span class="p-input-icon-right">
              <i
                :class="
                  showCurrentPassword
                    ? 'password-hide-icon cursor-pointer'
                    : 'eye-icon cursor-pointer'
                "
                @click="showCurrentPassword = !showCurrentPassword"
              />
              <InputText
                id="password"
                placeholder="Current Password"
                :type="currentPasswordInputType"
                class="w-full"
                v-model="vmodel.current_password"
                aria-describedby="current_password-help"
              />
            </span>
          </div>
        </div>
        <div class="grid">
          <div class="field mt-1 md:col-6 col-12">
            <div class="flex-row">
              <label for="password" class="form-label float-left"
                >New Password</label
              >
            </div>
            <span class="p-input-icon-right">
              <i
                :class="
                  showNewPassword
                    ? 'password-hide-icon cursor-pointer'
                    : 'eye-icon cursor-pointer'
                "
                @click="showNewPassword = !showNewPassword"
              />
              <InputText
                id="password"
                placeholder="New Password"
                :type="newPasswordInputType"
                class="w-full"
                v-model="vmodel.new_password"
                aria-describedby="new_password-help"
              />
            </span>
            <!-- <span
                      v-if="
                        v$.vmodel.new_password.$error &&
                        v$.vmodel.new_password.required.$invalid
                      "
                      id="new_password-help"
                      class="p-error text-left mt-2"
                    >
                      {{
                        v$.vmodel.new_password.required.$message.replace(
                          "Value",
                          "New Password"
                        )
                      }}
                    </span>
                    <span
                      v-else-if="
                        v$.vmodel.new_password.$error &&
                        v$.vmodel.new_password.min.$invalid
                      "
                      id="new_password-help"
                      class="p-error mt-2"
                    >
                      {{ v$.vmodel.new_password.min.$message }}
                    </span>
                    <span
                      v-else-if="
                        v$.vmodel.new_password.$error &&
                        v$.vmodel.new_password.max.$invalid
                      "
                      id="new_password-help"
                      class="p-error mt-2"
                    >
                      {{ v$.vmodel.new_password.max.$message }}
                    </span> -->
          </div>
        </div>
        <div class="grid">
          <div class="field mt-1 md:col-6 col-12">
            <div class="flex-row">
              <label for="password" class="form-label float-left"
                >Confirm New Password</label
              >
            </div>
            <span class="p-input-icon-right">
              <i
                :class="
                  showConfirmPassword
                    ? 'password-hide-icon cursor-pointer'
                    : 'eye-icon cursor-pointer'
                "
                @click="showConfirmPassword = !showConfirmPassword"
              />
              <InputText
                id="password"
                placeholder="Confirm New Password"
                :type="confirmPasswordInputType"
                class="w-full"
                v-model="vmodel.confirm_password"
                aria-describedby="confirm_password-help"
              />
            </span>
            <!-- <span
                      v-if="
                        v$.vmodel.confirm_password.$error &&
                        v$.vmodel.confirm_password.required.$invalid
                      "
                      id="confirm_password-help"
                      class="p-error text-left mt-2"
                    >
                      {{
                        v$.vmodel.confirm_password.required.$message.replace(
                          "Value",
                          "Confirm New Password"
                        )
                      }}
                    </span>
                    <span
                      v-else-if="
                        v$.vmodel.confirm_password.$error &&
                        v$.vmodel.confirm_password.min.$invalid
                      "
                      id="confirm_password-help"
                      class="p-error mt-2"
                    >
                      {{ v$.vmodel.confirm_password.min.$message }}
                    </span>
                    <span
                      v-else-if="
                        v$.vmodel.confirm_password.$error &&
                        v$.vmodel.confirm_password.max.$invalid
                      "
                      id="confirm_password-help"
                      class="p-error mt-2"
                    >
                      {{ v$.vmodel.confirm_password.max.$message }}
                    </span>
                    <span
                      v-else-if="
                        v$.vmodel.confirm_password.$error &&
                        v$.vmodel.confirm_password.sameAs.$invalid
                      "
                      id="confirm_password-help"
                      class="p-error text-left mt-2"
                    >
                      New Password && Confirm New Password must be match
                    </span> -->
          </div>
        </div>
        <div class="flex mt-3">
          <PrimeButton
            label="Change"
            class="order-form-button ml-0"
          ></PrimeButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      submitted: false,
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      title: "Add",
      vmodel: {
        current_password: null,
        new_password: null,
        confirm_password: null,
      },
    };
  },
  computed: {
    currentPasswordInputType() {
      if (this.showCurrentPassword) {
        return "text";
      }
      return "password";
    },
    newPasswordInputType() {
      if (this.showNewPassword) {
        return "text";
      }
      return "password";
    },
    confirmPasswordInputType() {
      if (this.showConfirmPassword) {
        return "text";
      }
      return "password";
    },
  },
};
</script>

<style>
::v-deep(.p-input-icon-right > .p-inputtext) {
  padding-right: 6rem !important;
}
</style>
