
// document.addEventListener('contextmenu', event => event.preventDefault());
 
// document.onkeydown = function (e) {

//     // disable F12 key
//     if(e.keyCode == 123) {
//         return false;
//     }
//   }
export default {
  
    mounted(el) {
      const handleRightClick = (e) => {
        if (!['table', 'input'].includes(e.target.tagName.toLowerCase())) {
          e.preventDefault();
        }
      };
      
      const handleMouseDown = (e) => {
        if (e.button === 2 && !['table', 'input'].includes(e.target.tagName.toLowerCase())) {
          e.preventDefault();
        }
      };
  
      el.addEventListener('contextmenu', handleRightClick);
      el.addEventListener('mousedown', handleMouseDown);
  
      el._handleRightClick = handleRightClick;
      el._handleMouseDown = handleMouseDown;
    },
    beforeUnmount(el) {
      el.removeEventListener('contextmenu', el._handleRightClick);
      el.removeEventListener('mousedown', el._handleMouseDown);
    },
  };