<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="list-margin">
    <!-- <div class="grid col-12"> -->
    <div class="grid">
      <div class="mt-3 md:col-4 col-12 md:text-left text-center">
        <label class="form-label" style="font-size: 17px">
          Name : {{ vmodel.name }} - {{ vmodel.customercontactno }}
        </label>
      </div>
      <div class="mt-3 md:col-4 col-12 md:text-left text-center">
        <label class="form-label" style="font-size: 17px">
          Date : {{ date }} {{ vmodel.time }}
        </label>
      </div>
      <div class="mt-3 md:col-4 col-12 md:text-left text-center">
        <label class="form-label" style="font-size: 17px">
          Venue : {{ vmodel.venue }}</label
        >
      </div>
    </div>
    <div class="grid">
      <!-- <div class="col-2" style="font-size: 2.3em; margin-top: auto">Items</div> -->
      <div class="col-10 px-0 p-input-icon-right">
        <i class="search-icon cursor-pointer search-right" />
        <InputText
          v-model="search"
          placeholder="Search..."
          id="search"
          class="w-full search-input"
          @keyup="onSearch"
        />
      </div>
      <div class="col-2 flex align-items-center justify-content-center">
        <Checkbox
          :binary="true"
          inputId="listed"
          v-model="isSelectedRecipe"
          @change="emptyItemList"
        /><label for="listed" class="ml-2"> Include Recipe </label>
      </div>
    </div>
    <!-- </div> -->
    <div
      class="col-12 grid m-0"
      :class="[
        {
          'justify-content-center': recipeitems == null,
        },
      ]"
    >
      <div class="md:col-2 col-2 cursor-pointer" @click="checkRecipeItem()">
        <PrimeCard class="item-card mt-2">
          <template #content>
            <!-- <img :src="item.pic" class="img-style" v-if="isSelectedRecipe" /> -->
            <span class="mt-1"><i class="pi pi-plus"></i></span>
            <span class="mt-2"
              >Add {{ isSelectedRecipe ? "Recipe" : "Submenu" }}</span
            >
          </template>
        </PrimeCard>
      </div>
      <div class="md:col-10 col-10 flex flex-row list-items">
        <div
          class="md:col-3 col-4"
          v-for="(item, index) in recipeitems"
          :id="index"
          :key="index"
          @click="addToCart(item.id)"
        >
          <PrimeCard class="item-card">
            <template #content>
              <!-- <img :src="item.pic" class="img-style" v-if="isSelectedRecipe" /> -->
              <span class="mt-1">{{ item.name }}</span>
              <span class="mt-2" v-if="!isSelectedRecipe">{{
                item.menuname
              }}</span>
            </template>
          </PrimeCard>
        </div>
      </div>
      <div class="flex justify-content-center" v-if="recipeitems == null">
        <h3 v-if="!isSelectedRecipe">No submenu found.</h3>
        <h3 v-else>No recipe found.</h3>
      </div>
    </div>
    <div class="col-12 m-0">
      <PrimeCard class="ordercard">
        <template #content>
          <div class="cart-list" v-if="vmodel.items.length > 0">
            <div
              class="grid mt-2 box-border"
              v-for="(item, index) in vmodel.items"
              :id="index"
              :key="index"
            >
              <div
                class="col-12 flex flex-column form-label md:align-items-start align-items-center"
                :class="isSelectedRecipe ? 'md:col-2' : 'md:col-2'"
                v-if="!isSelectedRecipe"
              >
                <div class="grid m-0">
                  <div class="md:col-2 col-1 p-0 flex align-items-center">
                    <i
                      class="draggable-icon cursor-pointer mt-5"
                      :draggable="true"
                      @dragstart="dragStart(index)"
                      @dragover="dragOver(index)"
                      @drop="drop"
                    ></i>
                  </div>
                  <div class="md:col-10 col-10 p-0">
                    <span class="mt-2">{{ item.name }}</span>
                  </div>
                </div>
              </div>
              <div
                class="col-12 flex flex-column form-label md:align-items-start align-items-center"
                :class="isSelectedRecipe ? 'md:col-2' : 'md:col-2'"
                v-if="isSelectedRecipe"
              >
                <div class="grid m-0">
                  <div class="md:col-2 col-1 p-0 flex align-items-center">
                    <i
                      class="draggable-icon cursor-pointer mt-5"
                      :draggable="true"
                      @dragstart="dragStart(index)"
                      @dragover="dragOver(index)"
                      @drop="drop"
                    ></i>
                  </div>
                  <div class="md:col-10 col-10 p-0">
                    <span class="mt-2">{{ item.recipename }}</span>
                  </div>
                </div>
              </div>
              <!-- <div
                class="md:col-2 col-12 flex flex-column form-label md:align-items-start align-items-center"
                v-if="isSelectedRecipe"
              >
                <img :src="item.pic" height="80" width="80" />
                <span class="mt-3">{{ item.recipename }}</span>
              </div> -->
              <div
                class="form-label flex flex-column md:align-items-start align-items-center"
              >
                <label class="mb-4">Inbound/Outbound</label>
                <label class="switch">
                  <Checkbox
                    :binary="true"
                    v-model="vmodel.items[index].checked"
                    class="p-cart"
                    @change="vendorChecked(index)"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
              <div
                class="lg:col-2 md:col-5 col-12 p-0 pad-check"
                v-if="vmodel.items[index].checked"
              >
                <DropdownCustom
                  v-model="vmodel.items[index].vendorid"
                  :validation="
                    v$.vmodel.items.$each.$response.$errors[index].vendorid[0]
                  "
                  :options="voption"
                  optionLabel="name"
                  :filter="true"
                  optionValue="id"
                  id="vendor"
                  label="Vendor"
                  :showlabel="true"
                  placeholder="Select Vendor"
                  :customfield="true"
                  :validated="validated"
                />
              </div>
              <div
                class="lg:col-1 p-0 mt-4 pad-check"
                v-if="vmodel.items[index].checked"
              >
                <PrimeButton
                  class="download-button"
                  icon="pi pi-plus"
                  @click="openVendorPopup(index)"
                ></PrimeButton>
              </div>
              <div class="lg:col-1 md:col-2 col-12 p-0 pad-check">
                <!-- <label class="form-label">Person</label> -->
                <NumberCustom
                  type="text"
                  v-model="vmodel.items[index].people"
                  :validation="
                    v$.vmodel.items.$each.$response.$errors[index].people[0]
                  "
                  label="Person"
                  id="Person"
                  placeholder="0"
                  :min="0"
                  :max="9999999"
                  maxLangth="4"
                  :customfield="true"
                  :validated="validated"
                />
              </div>
              <div class="lg:col-3 md:col-5 col-12 p-0 pad-check">
                <InputGroup
                  type="text"
                  v-model="vmodel.items[index].remarks"
                  :validation="
                    v$.vmodel.items.$each.$response.$errors[index].remarks[0]
                  "
                  class="w-full"
                  id="remarks"
                  label="Remark"
                  placeholder="Remark"
                  :customfield="true"
                  :validated="validated"
                />
              </div>
              <div
                class="lg:col-1 md:col-1 col-12 flex bin-btn justify-content-center md:justify-content-end relative p-0"
                :class="
                  vmodel.items[index].checked
                    ? 'lg:col-1 md:col-1'
                    : 'lg:col-4 md:col-1'
                "
              >
                <PrimeButton
                  icon="bin-icon"
                  class="bin-button"
                  @click="removeItem(index)"
                />
              </div>
            </div>
          </div>
        </template>
      </PrimeCard>
    </div>
    <Dialog
      position="top"
      modal
      header="Event Details"
      v-model:visible="showPopup"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      :draggable="false"
    >
      <div class="flex flex-column gap-2 justify-content-center">
        <PrimeButton
          label="Download PDF - Main Menu - Recipe"
          class="order-form-button ml-2"
          @click="generateReportMenuArihant"
          v-if="isSelectedRecipe && templatePdfs.main_menu"
        ></PrimeButton>
        <PrimeButton
          label="Download PDF - Ingredient List with Menu"
          class="order-form-button ml-2"
          @click="generateReportIngArihant"
          v-if="isSelectedRecipe && templatePdfs.ing_list"
        ></PrimeButton>
        <PrimeButton
          label="Download PDF - Ingredient List with Category"
          class="order-form-button ml-2"
          @click="generateReportIngCatgArihant"
          v-if="isSelectedRecipe && templatePdfs.cat_list"
        ></PrimeButton>
        <PrimeButton
          label="Download PDF - Recipe List with Menu and Submenu"
          class="order-form-button ml-2"
          @click="downloadfile"
          v-if="isSelectedRecipe && templatePdfs.recipe_menu_submenu"
        ></PrimeButton>
        <PrimeButton
          label="Download PDF - Recipe List with Menu, Submenu and Vendor"
          class="order-form-button ml-2"
          @click="downloadVendorfile"
          v-if="isSelectedRecipe && templatePdfs.recipe_menu_submenu_vendor"
        ></PrimeButton>
        <PrimeButton
          label="Download PDF - Recipe List with Menu, Submenu and Ingredient"
          class="order-form-button ml-2"
          @click="downloadIngfile"
          v-if="isSelectedRecipe && templatePdfs.recipe_menu_submenu_ing"
        ></PrimeButton>
        <PrimeButton
          label="Download PDF - Recipe List With Menu, Submenu, Ingredient and Vendor"
          class="order-form-button ml-2"
          @click="downloadIngVendorfile"
          v-if="isSelectedRecipe && templatePdfs.recipe_menu_submenu_ing_vendor"
        ></PrimeButton>
        <PrimeButton
          label="Download PDF - Ingredient List with Recipe"
          class="order-form-button ml-2"
          @click="downloadOnlyIngfile"
          v-if="isSelectedRecipe && templatePdfs.ing_recipe"
        ></PrimeButton>
        <PrimeButton
          label="Download PDF - Ingredient List - QTY with Recipe"
          class="order-form-button ml-2"
          @click="downloadIngIngQtyfile"
          v-if="isSelectedRecipe && templatePdfs.ing_qty_recipe"
        ></PrimeButton>
        <PrimeButton
          label="Download PDF - Ingredient List - Total QTY with Recipe"
          class="order-form-button ml-2"
          @click="downloadIngIngTotalQtyfile"
          v-if="isSelectedRecipe && templatePdfs.ing_total_qty_recipe"
        ></PrimeButton>
        <PrimeButton
          label="Download PDF - Ingredient List - Total QTY with Category"
          class="order-form-button ml-2"
          @click="downloadIngIngTotalQtyCategoryfile"
          v-if="isSelectedRecipe && templatePdfs.ing_total_qty_cat"
        ></PrimeButton>
        <PrimeButton
          label="Download PDF - Submenu With Menu"
          class="order-form-button ml-2"
          @click="downloadfile"
          v-if="!isSelectedRecipe"
        ></PrimeButton>
        <PrimeButton
          label="Download PDF - Submenu With Menu, Vendor"
          class="order-form-button ml-2"
          @click="downloadVendorfile"
          v-if="!isSelectedRecipe"
        ></PrimeButton>
      </div>
    </Dialog>
    <SubMenuAddPopup
      :show-popup="showSubMenuPopup"
      @displayNo="closeSubMenuPopup()"
      @displayYes="checkSubMenu"
    ></SubMenuAddPopup>
    <RecipeAddPopup
      :show-popup="showRecipePopup"
      @displayNo="closeRecipePopup()"
      @displayYes="checkRecipe"
    ></RecipeAddPopup>
    <VendorAddPopup
      :show-popup="showVendorPopup"
      @displayNo="closeVendorPopup()"
      @displayYes="checkVendor"
    ></VendorAddPopup>
    <div class="grid mx-0 mt-2">
      <div class="col-12 flex set-button">
        <PrimeButton
          label="Update"
          class="order-form-button"
          @click="createEvent"
        >
        </PrimeButton>
      </div>
    </div>
  </div>
</template>
<script>
import Dialog from "primevue/dialog";
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import DateFormat from "@/libs/DateFormat";
import {
  required,
  maxLength,
  numeric,
  maxValue,
  minValue,
  helpers,
  requiredIf,
} from "@vuelidate/validators";
export default {
  components: {
    Dialog,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      pdf: [],
      showPopup: false,
      eid: this.$route.params.id,
      qty: 0,
      checked: false,
      search: "",
      date: "",
      voption: [],
      templatePdfs: {},
      tempData: [],
      tempCheck: false,
      // tempSubmenuCart: [],
      // tempRecipeCart: [],
      showSubMenuPopup: false,
      showRecipePopup: false,
      showVendorPopup: false,
      isSelectedRecipe: false,
      validated: false,
      dragIndex: null,
      openIndex: "",
      vmodel: {
        id: "",
        cart: [],
        customerid: "",
        pic: "",
        name: "",
        customercontactno: "",
        people: "",
        status: "",
        venue: "",
        date: "",
        time: "",
        remarks: "",
        eventname: "",
        eventtype: "",
        items: [],
      },
      submitted: false,
      recipeitems: [],
      itemList: [],
    };
  },
  validations() {
    return {
      vmodel: {
        items: {
          $each: helpers.forEach({
            people: {
              required,
              numeric,
              min: minValue(1),
              max: maxValue(99999),
            },
            vendorid: {
              requiredIf: requiredIf((items, index) => {
                return index.checked;
              }),
            },
            remarks: { max: maxLength(50) },
          }),
        },
      },
    };
  },
  async mounted() {
    if (this.$route.params.id) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      this.isEditMode = true;
      await this.getEventById();
    }
    if (this.isSelectedRecipe) {
      await this.getRecipe();
    } else {
      await this.getsubmenu();
    }
    await this.getVendor();
    await this.getTemplatePdfsOptions();
  },
  methods: {
    dragStart(index) {
      this.dragIndex = index;
    },
    dragOver(index) {
      if (this.dragIndex !== null) {
        const draggedItem = this.vmodel.items.splice(this.dragIndex, 1)[0];
        this.vmodel.items.splice(index, 0, draggedItem);
        this.dragIndex = index;
      }
    },
    drop() {
      this.dragIndex = null;
    },
    async getRecipe() {
      this.itemList = [];
      this.$root.$globalState.loader = true;
      await APIService.get("/recipe/recipe")
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.itemList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    async getsubmenu() {
      this.itemList = [];
      this.$root.$globalState.loader = true;
      await APIService.get("/menu/dropdown/onlysubmenu")
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.itemList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    closeRecipePopup() {
      this.showRecipePopup = false;
    },
    async checkRecipe(data) {
      this.showRecipePopup = false;
      await this.getRecipe();
      await this.getVendor();
      await this.setRecipeDetails(data);
      // if (this.isSelectedRecipe) {
      //   this.itemList
      //     .filter((x) => x.id === this.vmodel.items[index].itemid)
      //     .map((data) => {
      //       this.vmodel.items[index].recipeid = this.vmodel.items[index].itemid;
      //       this.vmodel.items[index].recipename = data.name;
      //       this.vmodel.items[index].people = this.vmodel.noofperson;
      //       this.vmodel.items[index].remarks = "";
      //     });
      // } else {
      //   this.itemList
      //     .filter((x) =>
      //       x.submenu.some(
      //         (subElement) => subElement.id === this.vmodel.items[index].itemid
      //       )
      //     )
      //     .map((element) => {
      //       element.sub = element.submenu
      //         .filter(
      //           (subElement) =>
      //             subElement.id === this.vmodel.items[index].itemid
      //         )
      //         .map((x) => {
      //           let newElt = Object.assign({}, x);
      //           return newElt;
      //         });
      //       this.vmodel.items[index].submenuid = element.sub[0].id;
      //       this.vmodel.items[index].name = element.sub[0].name;
      //       this.vmodel.items[index].menuid = element.sub[0].menuid;
      //       this.vmodel.items[index].menuname = element.name;
      //       this.vmodel.items[index].people = this.vmodel.noofperson;
      //       this.vmodel.items[index].remarks = "";
      //     });
      // }
      // if (this.vmodel.items) {
      //   let index = this.vmodel.items.length - 1;
      //   this.vmodel.items[index].itemid = data;
      //   this.getItemListDetails(index);
      // }
    },
    async setRecipeDetails(data) {
      let index = 0;
      if (this.vmodel.items) {
        const newItem = {
          recipename: "",
          itemid: "",
          recipeid: "",
          people: 1,
          vendorid: "",
          remarks: "",
          checked: false,
        };
        this.vmodel.items = [...this.vmodel.items, newItem];
        index = this.vmodel.items.length - 1;
        this.vmodel.items[index].recipeid = data;
        await this.itemList
          .filter((x) => x.id == data)
          .map((data) => {
            this.vmodel.items[index].recipename = data.name;
            this.vmodel.items[index].people = 1;
            this.vmodel.items[index].vendorid = "";
            this.vmodel.items[index].remarks = "";
            this.vmodel.items[index].checked = false;
          });
      } else {
        const newItem = {
          recipename: "",
          itemid: "",
          recipeid: "",
          people: 1,
          vendorid: "",
          remarks: "",
          checked: false,
        };
        this.vmodel.items = [...this.vmodel.items, newItem];
        this.vmodel.items[index].recipeid = data;
        await this.itemList
          .filter((x) => x.id == data)
          .map((data) => {
            this.vmodel.items[index].recipename = data.name;
            this.vmodel.items[index].people = 1;
            this.vmodel.items[index].vendorid = "";
            this.vmodel.items[index].remarks = "";
            this.vmodel.items[index].checked = false;
          });
      }
    },
    closeSubMenuPopup() {
      this.showSubMenuPopup = false;
    },
    async checkSubMenu(data) {
      this.showSubMenuPopup = false;
      await this.getsubmenu();
      await this.getVendor();
      await this.setSubmenu(data);
    },
    async setSubmenu(data) {
      let index = 0;
      if (this.vmodel.items && this.vmodel.items.length > 0) {
        const newItem = {
          name: "",
          submenuid: "",
          menuid: "",
          menuname: "",
          people: 1,
          vendorid: "",
          remarks: "",
          checked: false,
        };
        this.vmodel.items = [...this.vmodel.items, newItem];

        index = this.vmodel.items.length - 1;
        this.vmodel.items[index].submenuid = data;
        index = this.vmodel.items.length - 1;
        this.vmodel.items[index].submenuid = data;
        await this.itemList
          .filter((x) => x.submenu.some((subElement) => subElement.id == data))
          .map((element) => {
            element.sub = element.submenu
              .filter((subElement) => subElement.id == data)
              .map((x) => {
                let newElt = Object.assign({}, x);
                return newElt;
              });
            this.vmodel.items[index].name = element.sub[0].name;
            this.vmodel.items[index].menuid = element.sub[0].menuid;
            this.vmodel.items[index].menuname = element.name;
            this.vmodel.items[index].people = this.vmodel.people;
            this.vmodel.items[index].remarks = "";
            this.vmodel.items[index].checked = false;
          });
      } else {
        const newItem = {
          name: "",
          submenuid: "",
          menuid: "",
          menuname: "",
          people: 1,
          vendorid: "",
          remarks: "",
          checked: false,
        };
        this.vmodel.items = [...this.vmodel.items, newItem];
        this.vmodel.items[0].submenuid = data;
        await this.itemList
          .filter((x) => x.submenu.some((subElement) => subElement.id == data))
          .map((element) => {
            element.sub = element.submenu
              .filter((subElement) => subElement.id == data)
              .map((x) => {
                let newElt = Object.assign({}, x);
                return newElt;
              });
            this.vmodel.items[index].name = element.sub[0].name;
            this.vmodel.items[index].menuid = element.sub[0].menuid;
            this.vmodel.items[index].menuname = element.name;
            this.vmodel.items[index].people = this.vmodel.people;
            this.vmodel.items[index].remarks = "";
            this.vmodel.items[index].checked = false;
          });
      }
    },
    async checkRecipeItem() {
      if (this.isSelectedRecipe) {
        this.showRecipePopup = true;
      } else {
        this.showSubMenuPopup = true;
      }
    },
    openVendorPopup(index) {
      this.showVendorPopup = true;
      this.openIndex = index;
    },
    closeVendorPopup() {
      this.showVendorPopup = false;
    },
    async checkVendor(data) {
      this.showVendorPopup = false;
      await this.getVendor();
      if (this.vmodel.items) {
        this.vmodel.items[this.openIndex].vendorid = data;
      }
      this.openIndex = "";
    },
    async vendorChecked(index) {
      if (!this.vmodel.items[index].checked) {
        this.vmodel.items[index].vendorid = "";
      }
    },
    async emptyItemList() {
      // if (this.recipeitems) {
      //   this.tempSubmenuCart = this.vmodel.items;
      // } else {
      //   this.tempSubmenuCart = this.vmodel.items;
      // }
      this.recipeitems = [];
      this.vmodel.items = [];
      if (this.tempCheck == this.isSelectedRecipe) {
        this.vmodel.items = this.tempData;
      }
      this.search = "";
    },
    closeModal() {
      this.showPopup = false;
    },
    async getTemplatePdfsOptions() {
      this.$root.$globalState.loader = true;
      this.templatePdfs = {};
      await APIService.get(`/templatePdfs/dropdown`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.templatePdfs = response.data.data;
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    async genearteMenu() {
      this.$root.$globalState.loader = true;
      await APIService.getById(`/event/eventrecipelistmenu/${this.eid}`)
        .then((response) => {
          if (response && response.data) {
            this.pdf = response.data.data;
          }
          this.$refs.html2Pdf.generatePdf();
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.showPopup = false;
        });
    },
    async downloadfile() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      this.showPopup = false;
      await APIService.get(`/event/eventrecipelistmenu/${this.eid}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    async downloadOnlyIngfile() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      this.showPopup = false;
      await APIService.get(`/event/eventrecipelistingtypes/${this.eid}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    async downloadIngIngQtyfile() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      this.showPopup = false;
      await APIService.get(`/event/eventrecipelistingcolvalue/${this.eid}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    async generateReportMenuArihant() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      this.showPopup = false;
      await APIService.get(`/event/eventrecipelistmenuarihant/${this.eid}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    async generateReportIngArihant() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      this.showPopup = false;
      await APIService.get(`/event/eventrecipelistingarihant/${this.eid}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    async generateReportIngCatgArihant() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      this.showPopup = false;
      await APIService.get(`/event/eventrecipelistingcatgarihant/${this.eid}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    async downloadIngIngTotalQtyfile() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      this.showPopup = false;
      await APIService.get(`/event/eventrecipelistingtotalvalue/${this.eid}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    async downloadIngIngTotalQtyCategoryfile() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      this.showPopup = false;
      await APIService.get(`/event/eventrecipelistingcategorytotal/${this.eid}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    async downloadIngfile() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      this.showPopup = false;
      await APIService.get(`/event/eventrecipelisting/${this.eid}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    async downloadIngVendorfile() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      this.showPopup = false;
      await APIService.get(`/event/eventrecipelistingvendor/${this.eid}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    async downloadVendorfile() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      this.showPopup = false;
      await APIService.get(`/event/eventrecipelistmenuvendor/${this.eid}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            window.open(response.data.data);
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    async getVendor() {
      this.$root.$globalState.loader = true;
      await APIService.get("/vendor/vender")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.voption = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    async getItemList() {
      if (this.isSelectedRecipe) {
        this.$root.$globalState.loader = true;
        await APIService.get(`/recipe/getrecipelist/${this.search}`)
          .then((response) => {
            if (response && response.data) {
              if (response.data.data.length > 0) {
                this.recipeitems = response.data.data;
              } else {
                this.recipeitems = null;
              }
            }
          })
          .finally(() => {
            this.$root.$globalState.loader = false;
          });
      } else {
        this.$root.$globalState.loader = true;
        await APIService.get(`/submenu/getsubmenu/${this.search}`)
          .then((response) => {
            if (response && response.data) {
              if (response.data.data.length > 0) {
                this.recipeitems = response.data.data;
              } else {
                this.recipeitems = null;
              }
            }
          })
          .finally(() => {
            this.$root.$globalState.loader = false;
          });
      }
    },
    removeItem(index) {
      this.vmodel.items.splice(index, 1);
    },
    addToCart(id) {
      if (this.isSelectedRecipe) {
        let checkItem = this.vmodel.items.filter((x) => x.recipeid == id)[0];

        if (!checkItem || checkItem == undefined) {
          let index = this.recipeitems.map((e) => e.id).indexOf(id);
          let obj = {
            recipename: this.recipeitems[index].name,
            recipeid: this.recipeitems[index].id,
            people: this.vmodel.people,
            vendorid: "",
            remarks: "",
            pic: this.recipeitems[index].pic,
          };

          this.vmodel.items.push(obj);
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "It is already in added.",
            life: 3000,
          });
        }
      } else {
        let checkItem = this.vmodel.items.filter((x) => x.submenuid == id)[0];

        if (!checkItem || checkItem == undefined) {
          let index = this.recipeitems.map((e) => e.id).indexOf(id);
          let obj = {
            name: this.recipeitems[index].name,
            submenuid: this.recipeitems[index].id,
            menuid: this.recipeitems[index].menuid,
            menuname: this.recipeitems[index].menuname,
            people: this.vmodel.people,
            vendorid: "",
            remarks: "",
          };

          this.vmodel.items.push(obj);
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "It is already added.",
            life: 3000,
          });
        }
      }
    },
    async getEventById() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      await APIService.getById("/event", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.customerid = response.data.data.customerid;
            this.vmodel.name = response.data.data.customername;
            this.vmodel.customercontactno =
              response.data.data.customercontactno;
            this.vmodel.eventname = response.data.data.eventname;
            this.vmodel.eventtype = response.data.data.eventtype;
            this.vmodel.venue = response.data.data.venue;
            this.vmodel.people = response.data.data.people;
            this.vmodel.status = response.data.data.status;
            this.vmodel.pic = response.data.data.pic;
            this.vmodel.time = response.data.data.time;
            this.vmodel.date = response.data.data.eventdate;
            this.isSelectedRecipe = response.data.data.hasRecipe;
            this.tempCheck = response.data.data.hasRecipe;
            this.vmodel.items = response.data.data.items;
            if (response.data.data.items) {
              for (let item of response.data.data.items) {
                if (item.vendorid) {
                  item.checked = true;
                } else {
                  item.checked = false;
                }
              }
            }
            this.tempData = response.data.data.items;
            this.date = DateFormat.formatDate(response.data.data.eventdate);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "Event" });
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    async createEvent() {
      this.validated = true;
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        this.$root.$globalState.loader = true;
        let payload = {
          customerid: this.vmodel.customerid,
          eventname: this.vmodel.eventname,
          eventtype: this.vmodel.eventtype,
          people: this.vmodel.people,
          status: this.vmodel.status,
          venue: this.vmodel.venue,
          date: this.vmodel.date,
          time: this.vmodel.time,
          items: this.vmodel.items,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          await APIService.put("/event", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.$root.$globalState.loader = false;
              this.submitted = false;
              this.showPopup = true;
            });
        }
      }
    },
    onSearch() {
      if (this.search.length >= 2) {
        this.getItemList();
      } else {
        this.recipeitems = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.order-form-button {
  width: auto;
  height: auto;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 31px;
  margin-top: 10px;
  top: -18px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

::v-deep(.p-checkbox.p-cart) {
  display: none;
}

.p-checkbox-checked + .slider {
  background-color: #ff9f43;
}

.p-checkbox:focus + .slider {
  box-shadow: 0 0 1px #ff9f43;
}

.p-checkbox-checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
::v-deep(.item-card.p-card) {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  cursor: pointer;
}
::v-deep(.item-card .p-card-content) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
::v-deep(.ordercard.p-card) {
  height: 35vh;
  min-height: 35vh;
  background: none;
  border: none;
}
::v-deep(.ordercard.p-card:has(.cart-list)) {
  background: #fff !important;
  border: 1px solid #e8e8ed !important;
}
::v-deep(.ordercard.p-card .p-card-content) {
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem !important;
}
.img-style {
  height: 70px;
  width: 100%;
}
.cart-list {
  height: 27vh;
  max-height: 27vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media (min-width: 767.98px) {
  .bin-button {
    position: absolute;
    top: 25%;
  }
}
.bin-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px !important;
  gap: 8px;
  height: 36px;
  width: 36px !important;

  background: #feeded;
  opacity: 0.4;
  border: 1px solid rgba(241, 71, 71, 0.4);
  border-radius: 4px;
}

.bin-button:enabled:hover {
  background: #feeded;
  border: 1px solid rgba(241, 71, 71, 0.4);
}
:deep(.p-inputnumber-input) {
  flex: 1 1 auto;
  width: 100%;
}
.box-border {
  border-radius: 8px;
  border: 1px solid #ccc9d6;
  padding: 20px;
  height: auto;
}
:deep(.search-right) {
  margin-right: 32px;
}
.list-items {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  // border: 1px solid #ccc9d6;
}
.order-form-title {
  margin-top: 10px;
}
.search-icon {
  margin-right: 0;
}
.search-input {
  margin-right: 0;
}
.pad-check {
  padding: 0px 0px 0px 10px !important;
}
</style>
