import DateFormat from "@/libs/DateFormat"

export default class Companay {
    constructor(data = {}) {
        return {
            id: data.id || '',
            companyname: data.companyname || '',
            email: data.email || '',
            password: data.password || '',
            contactperson: data.contactperson || '',
            contactno: data.contactno || '',
            domainname: data.domainname || '',
            startdate: DateFormat.formatDateTime(data.startdate) || '',
            enddate: DateFormat.formatDateTime(data.enddate) || '',
        }
    }
}