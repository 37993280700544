import DateFormat from "@/libs/DateFormat";

export default class Employee {
  constructor(data = {}) {
    return {
      id: data.id || "",
      firstname: data.firstname || "",
      lastname: data.lastname || "",
      doj: DateFormat.formatDate(data.doj) || "",
      dob: DateFormat.formatDate(data.dob) || "",
      role: data.role || "",
    };
  }
}
