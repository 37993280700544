<template>
  <div :class="sectionClass">
    <label :for="id" class="form-label">{{ label }}</label>
    <InputNumber
      :id="id"
      :modelValue="modelValue"
      :disabled="disabled"
      :placeholder="placeholder"
      :useGrouping="useGrouping"
      :min="min"
      :max="max"
      :class="{
        'p-invalid':
          (validated && customfield && validation) ||
          (validation && validation.$error),
      }"
      :aria-describedby="`${id}-help`"
      @input="$emit('update:modelValue', $event.value)"
    />
  </div>
  <span
    v-if="
      (validated && customfield && validation) ||
      (validation &&
        validation.$error &&
        validation.required.$invalid &&
        validation.required)
    "
    :id="`${id}-help`"
    class="p-error text-left mt-2"
  >
    {{
      validated && customfield && validation
        ? validation.$message.replace("Value", this.label)
        : validation.required.$message.replace("Value", this.label)
    }}
  </span>
  <span
    v-else-if="
      (validated && customfield && validation) ||
      (validation &&
        validation.$error &&
        validation.min.$invalid &&
        validation.min)
    "
    :id="`${id}-help`"
    class="p-error text-left mt-2"
  >
    {{ validation.min.$message }}
  </span>
  <span
    v-else-if="
      (validated && customfield && validation) ||
      (validation &&
        validation.$error &&
        validation.max.$invalid &&
        validation.max)
    "
    :id="`${id}-help`"
    class="p-error text-left mt-2"
  >
    {{ validation.max.$message }}
  </span>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    sectionClass: {
      type: String,
      default: "field",
    },
    id: {
      type: String,
      required: true,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      required: true,
      default: "",
    },
    customfield: {
      type: Boolean,
      default: false,
    },
    validated: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
      required: false,
    },
    minlength: {
      type: String,
      default: "",
    },
    maxlength: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    useGrouping: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
