import DateFormat from "@/libs/DateFormat";

export default class Payment {
  constructor(data = {}) {
    return {
      id: data.id || "",
      paymentid: data.paymentid || "",
      invoiceid: data.invoiceid || "",
      paymenttype: data.paymenttype || "",
      receiveby: data.receiveby || "",
      eventname: data.eventname || "",
      referenceno: data.referenceno || "",
      paymentdate: DateFormat.formatDate(data.paymentdate) || "",
      amount: data.amount || 0.0,
    };
  }
}
