<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="list-margin">
    <DataTable
      :value="employee"
      class="p-datatable-lg p-datatable-customers"
      dataKey="id"
      :rowHover="true"
      :resizableColumns="true"
      @sort="onSort($event)"
    >
      <template #header>
        <div class="grid m-0">
          <div class="col-12 lg:col-6 lg:text-left text-center list-header">
            Employees
          </div>
          <div class="col-12 lg:col-6 lg:text-right text-center">
            <PrimeButton
              label=" ADD "
              class="add-button mr-3"
              icon="plus-icon"
              @click="gotoCreateEmployee"
            ></PrimeButton>
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch" />
              <InputText
                v-model="search"
                placeholder="Search"
                class="search-input"
              />
            </div>
            <PrimeButton
              class="download-button ml-3"
              icon="pi pi-download"
              @click="downloadCSV"
              :disabled="true"
            ></PrimeButton>
            <!-- :disabled="employee.length == 0" -->
          </div>
        </div>
      </template>

      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <Column field="firstname" header="First Name" sortable></Column>
      <Column field="lastname" header="Last Name" sortable> </Column>
      <Column field="dob" header="DOB" sortable></Column>
      <Column field="doj" header="DOJ" sortable> </Column>
      <Column field="role" header="Role" sortable></Column>
      <Column field="actions" header="Actions">
        <template #body="data">
          <div class="flex">
            <i
              class="pi pi-pencil cursor-pointer edit-icon"
              style="color: gray"
              @click="gotoEmployeeEdit(data.data.id)"
            ></i>
            <i
              class="pi pi-trash cursor-pointer delete-icon ml-1"
              style="color: #dc3545"
              @click="showDeletePopup(data.data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <Paginator
      v-model:first="currentPage"
      :rows="50"
      :totalRecords="totalRecords"
      :rowsPerPageOptions="[50, 100, 150, 200]"
      template="RowsPerPageDropdown CurrentPageReport PageLinks"
      currentPageReportTemplate="{first} - {last} of {totalRecords} items"
      @page="onPage($event)"
      :class="{
        loading: this.$root.$globalState.loader,
      }"
    >
      <template #start> Shown per page : </template>
    </Paginator>
    <ConfirmPopup
      :show-popup="showPopup"
      @confirmYes="deleteEmployee"
      @confirmNo="showPopup = false"
    ></ConfirmPopup>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import EmployeeFactory from "@/factories/employee";
export default {
  data() {
    return {
      employee: [],
      currentPage: 0,
      itemsPerPage: 50,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      showPopup: false,
      employeeIdForDelete: "",
    };
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    this.getEmployeeList();
  },
  methods: {
    downloadCSV() {
      console.log("this is downloadcsv");
    },
    gotoCreateEmployee() {
      this.$router.push({ name: "EmployeeCreate" });
    },
    gotoEmployeeEdit(employeeId) {
      this.$router.push({
        name: "EmployeeEdit",
        params: { id: employeeId },
      });
    },
    showDeletePopup(employeeId) {
      this.employeeIdForDelete = employeeId;
      this.showPopup = true;
    },
    getEmployeeList() {
      this.$root.$globalState.loader = true;
      this.employee = [];
      APIService.get(
        `/employee/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${this.search}`
      )
        .then((response) => {
          if (response && response.data) {
            this.totalRecords = response.data.totalrecord;
            this.employee = EmployeeFactory.createFromJsonArray(
              response.data.data
            );
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
        });
    },
    deleteEmployee() {
      this.$root.$globalState.loader = true;
      APIService.delete(`/employee/delete/${this.employeeIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getEmployeeList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.showPopup = false;
        });
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getEmployeeList();
    },
    onPage(pageData) {
      this.itemsPerPage = pageData.rows;
      this.currentPage = pageData.first;
      this.getEmployeeList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getEmployeeList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
</style>
