import DateFormat from "@/libs/DateFormat";

export default class RemainingPayment {
  constructor(data = {}) {
    return {
      id: data.id || "",
      customerinvoice: data.customerinvoice || "",
      totalamount: data.totalamount || 0,
      remainingAmount: data.remainingAmount || 0,
      invoicedate: DateFormat.formatDate(data.invoicedate) || 0,
    };
  }
}
