<template>
  <Dialog
    header="Add Menu"
    modal
    v-model:visible="display"
    :breakpoints="{ '960px': '75vw', '640px': '80vw' }"
    :style="{ width: '50vw' }"
    class="popup"
    :class="{
      blur: this.$root.$globalState.loader,
    }"
    :draggable="false"
    :dismissableMask="true"
    @hide="closePopup"
  >
    <div
      class="grid mx-0 flex flex-column"
      v-if="$store.state.userInfo.add_images"
    >
      <label for="photo" class="form-label mb-2">Image</label>
      <div
        class="photo-section cursor-pointer"
        @click="openFileUpload"
        :class="{
          invalid: v$.vmodel.pic.$error,
        }"
      >
        <img
          v-if="pic !== ''"
          :src="pic"
          alt=""
          class="upload-img"
          height="110"
          width="120"
          label="Image"
        />

        <span class="upload-icon" v-if="pic == ''"></span>
        <div class="upload-text" v-if="pic == ''">Upload Image Here</div>
      </div>
      <FileUpload
        ref="photo"
        name="photo"
        accept=".png,.jpg,.jpeg"
        @select="handleFileUpload($event)"
        v-show="false"
        :aria-describedby="`photo-help`"
      />
      <div
        v-if="
          v$.vmodel.pic.$error &&
          v$.vmodel.pic.required &&
          v$.vmodel.pic.required.$invalid
        "
        class="p-error mt-2"
      >
        {{ v$.vmodel.pic.required.$message.replace("Value", "Image") }}
      </div>
    </div>
    <div class="grid mt-2">
      <div class="col-12">
        <InputTextRequire
          v-model="vmodel.name"
          :validation="v$.vmodel.name"
          id="name"
          type="text"
          class="w-full"
          label="Name"
          placeholder="Enter Name"
        />
      </div>
    </div>
    <div class="grid">
      <div class="col-12">
        <InputTextRequire
          v-model="vmodel.notes"
          :validation="v$.vmodel.notes"
          id="notes"
          type="text"
          class="w-full"
          label="Notes"
          placeholder="Enter Notes"
        />
      </div>
    </div>
    <div class="grid mx-0">
      <div class="col-12 flex set-button">
        <PrimeButton label="Add" class="order-form-button" @click="createMenu">
        </PrimeButton>
      </div>
    </div>
  </Dialog>
</template>
<script>
import Dialog from "primevue/dialog";
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";

export default {
  emits: ["displayNo","displayYes"],
  components: {
    Dialog,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      display: false,
      submitted: false,
      pic: "",
      vmodel: {
        name: "",
        pic: "",
        notes: "",
      },
    };
  },
  validations() {
    if (this.display) {
      if (this.$store.state.userInfo.add_images) {
        return {
          vmodel: {
            name: { required, max: maxLength(30) },
            pic: {
              required,
            },
            notes: { max: maxLength(30) },
          },
        };
      } else {
        return {
          vmodel: {
            name: { required, max: maxLength(30) },
            pic: {},
            notes: { max: maxLength(30) },
          },
        };
      }
    } else {
      return {
        vmodel: {
          name: {},
          pic: {},
          notes: {},
        },
      };
    }
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  watch: {
    showPopup: {
      async handler(newValue) {
        if (newValue === true) {
          await this.v$.$reset();
          await this.openPopup();
          document.querySelector("body").style.overflow = "hidden";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    openFileUpload() {
      this.$refs.photo.choose();
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    async createMenu() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        this.$root.$globalState.loader = true;
        let payload = {
          name: this.vmodel.name,
          pic: this.vmodel.pic,
          notes: this.vmodel.notes,
        };
        this.submitted = true;
        await APIService.post("/menu", payload)
          .then(async (response) => {
            if (response && response.data && response.data.success) {
              await this.$emit("displayYes", response.data.data.id);
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
              this.display = false;
              await this.v$.$reset();
              this.vmodel.name = "";
              this.vmodel.pic = "";
              this.vmodel.notes = "";
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
            this.$root.$globalState.loader = false;
          });
      }
    },
    handleFileUpload(fileObj) {
      var file = this.$refs.photo.files[0];
      // const file = event.target.files[0];
      if (
        !fileObj ||
        (fileObj && !fileObj.files) ||
        (fileObj && fileObj.files && fileObj.files.length === 0)
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select valid image",
          life: 3000,
        });
        this.$refs.photo.clear();
        // this.$refs.file.value = null;
      } else if (fileObj && fileObj.files && fileObj.files[0].size > 3145728) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Image size should be less then 3 MB",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.pic = reader.result;
          this.vmodel.pic = reader.result;
        };
        this.$refs.photo.clear();
      }
    },
    openPopup() {
      this.display = true;
    },
    async closePopup() {
      this.display = false;
      await this.$emit("displayNo");
      await this.v$.$reset();
      this.vmodel.name = "";
      this.vmodel.pic = "";
      this.vmodel.notes = "";
      document.querySelector("body").style.overflow = "auto";
    },
  },
};
</script>
