<template>
  <div class="ml-5 mr-5 set-margin-top mb-5">
    <div class="grid">
      <div class="grid">
        <div class="order-form-title w-full">Recipe / Item Management</div>
        <div class="order-form-sub-title">{{ subtitle }}</div>
      </div>
      <div class="order-card w-full">
        <div class="grid">
          <div class="md:col-3 sm:col-11 col-10">
            <label class="form-label"> Menu / Submenu </label>
            <CascadeSelect
              v-model="vmodel.submenuid"
              :options="
                category.length > 0
                  ? category
                  : [
                      {
                        id: 'No available options',
                        name: 'No available options',
                        disabled: true,
                      },
                    ]
              "
              optionLabel="name"
              optionDisabled="disabled"
              optionGroupLabel="name"
              optionValue="id"
              :optionGroupChildren="['submenu']"
              class="w-full mt-2 mb-2"
              placeholder="Select a Menu / Submenu"
              :class="{ 'p-invalid': v$.vmodel.submenuid.$error }"
            />
            <span
              v-if="
                v$.vmodel.submenuid.$error &&
                v$.vmodel.submenuid.required.$invalid
              "
              id="submenuid-help"
              class="p-error text-left"
            >
              Menu / Submenu is required
            </span>
          </div>
          <div class="sm:col-1 col-2 p-0 mt-5 flex justify-content-center">
            <PrimeButton
              class="download-button"
              icon="pi pi-plus"
              @click="showMenuPopup = true"
            ></PrimeButton>
          </div>
          <div class="md:col-4 col-12">
            <InputTextRequire
              v-model="vmodel.name"
              :validation="v$.vmodel.name"
              id="name"
              type="text"
              class="w-full"
              label="Name"
              placeholder="Enter Name"
            />
          </div>
          <div class="md:col-4 col-12">
            <InputTextRequire
              v-model="vmodel.noofperson"
              :validation="v$.vmodel.noofperson"
              class="w-full"
              type="number"
              id="noofperson"
              label="Number Of Person"
              placeholder="Enter Person"
              :max="999999"
              @keydown="onlyDigit"
            />
          </div>
        </div>
        <div
          class="grid mx-0 flex flex-column"
          v-if="$store.state.userInfo.add_images"
        >
          <label for="photo" class="form-label mb-2 ml-2">Image</label>
          <div
            class="photo-section cursor-pointer mx-1"
            @click="openFileUpload"
            :class="{
              invalid: v$.vmodel.pic.$error,
            }"
          >
            <img
              v-if="pic !== ''"
              :src="pic"
              alt=""
              class="upload-img"
              height="110"
              width="120"
              label="Image"
            />

            <span class="upload-icon" v-if="pic == ''"></span>
            <div class="upload-text" v-if="pic == ''">Upload Image Here</div>
          </div>

          <FileUpload
            ref="photo"
            name="photo"
            accept=".png,.jpg,.jpeg"
            @select="handleFileUpload($event)"
            v-show="false"
            :aria-describedby="`photo-help`"
          />
          <div
            v-if="
              v$.vmodel.pic.$error &&
              v$.vmodel.pic.required &&
              v$.vmodel.pic.required.$invalid
            "
            class="p-error mt-2 ml-2"
          >
            {{ v$.vmodel.pic.required.$message.replace("Value", "Image") }}
          </div>
        </div>
        <div class="grid">
          <div class="col-12">
            <div class="col-12 m-0 p-0">
              <div class="grid align-items-center m-0 p-0">
                <div class="col-10 p-0 m-0">
                  <label for="category" class="form-label"
                    >Raw Material / Ingredient</label
                  >
                </div>
                <div class="col-2 p-0 m-0 flex justify-content-end">
                  <PrimeButton
                    class="download-button"
                    icon="pi pi-plus"
                    @click="showRawMaterialPopup = true"
                  ></PrimeButton>
                </div>
              </div>
            </div>
            <div id="box" class="box mt-3">
              <div
                class="grid"
                v-for="(materials, index) in vmodel.materials"
                :id="index"
                :key="index"
              >
                <div class="md:col-4 col-12">
                  <div class="grid m-0">
                    <div class="md:col-2 col-1 p-0 flex align-items-center">
                      <i
                        class="draggable-icon cursor-pointer mt-1"
                        :draggable="true"
                        @dragstart="dragStart(index)"
                        @dragover="dragOver(index)"
                        @drop="drop"
                      ></i>
                    </div>
                    <div class="md:col-10 col-10 p-0">
                      <DropdownCustom
                        v-model="vmodel.materials[index].rowmaterialid"
                        :validation="
                          v$.vmodel.materials.$each.$response.$errors[index]
                            .rowmaterialid[0]
                        "
                        :options="itemList"
                        :filter="true"
                        optionLabel="name"
                        optionValue="id"
                        id="materials"
                        label="Raw Material / Ingredient"
                        :showlabel="false"
                        :maxSelectedLabels="3"
                        placeholder="Select Raw Material / Ingredient"
                        class="w-full"
                        :customfield="true"
                        :validated="validated"
                        @update:modelValue="getItemListDetails(index)"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="md:col-2 col-12 flex justify-content-center align-items-center"
                >
                  <label v-if="vmodel.materials[index].rowmaterialid">{{
                    getRowCategory(vmodel.materials[index].rowmaterialid)
                  }}</label>
                  <label v-else>-</label>
                </div>
                <div class="md:col-3 col-6 flex md:justify-content-center">
                  <div class="grid justify-content-center">
                    <div class="col-12 flex justify-content-center p-0">
                      <label for="" class="form-label"></label>
                      <div class="quantity-field">
                        <PrimeButton
                          class="value-button decrease-button"
                          @click="decrement(index)"
                          :disabled="vmodel.materials[index].qty == 0 || vmodel.materials[index].qty == 0.00"
                          :class="{
                            invalid:
                              validated &&
                              v$.vmodel.materials.$each.$response.$errors[index]
                                .qty[0],
                          }"
                        >
                          <span class="decrement-icon"></span>
                        </PrimeButton>
                        <InputNumber
                          class="number"
                          v-model="vmodel.materials[index].qty"
                          :minFractionDigits="2"
                          :maxFractionDigits="2"
                          :useGrouping="false"
                          labelClass="hidden"
                          :class="{
                            'p-invalid':
                              validated &&
                              v$.vmodel.materials.$each.$response.$errors[index]
                                .qty[0],
                          }"
                        />
                        <PrimeButton
                          class="value-button increase-button"
                          :class="{
                            invalid:
                              validated &&
                              v$.vmodel.materials.$each.$response.$errors[index]
                                .qty[0],
                          }"
                          @click="increment(index)"
                        >
                          <span class="increment-icon"></span>
                        </PrimeButton>
                      </div>
                    </div>
                    <div class="col-12 flex justify-content-center p-0">
                      <span
                        v-if="
                          validated &&
                          v$.vmodel.materials.$each.$response.$errors[index]
                            .qty[0]
                        "
                        class="p-error text-left mt-2"
                      >
                        Minimum qty allowed is 0.01
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  class="md:col-1 col-6 flex justify-content-center align-items-center"
                >
                  <label v-if="vmodel.materials[index].rowmaterialid">{{
                    getUnitOptions(vmodel.materials[index].rowmaterialid)
                  }}</label>
                  <label v-else>-</label>
                </div>
                <div
                  class="md:col-1 col-6 field flex md:justify-content-center"
                >
                  <PrimeButton
                    icon="bin-icon"
                    v-if="vmodel.materials.length > 1"
                    class="bin-button"
                    @click="removeItem(index)"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-4 field">
                  <PrimeButton
                    icon="pi pi-plus"
                    class="add-item-button"
                    @click="repeateAgain()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid mx-0 mt-4">
          <div class="col-12 flex set-button">
            <PrimeButton
              label="Add"
              class="order-form-button"
              v-if="!isEditMode"
              @click="createRecipe"
            >
            </PrimeButton>
            <PrimeButton
              label="Edit"
              class="order-form-button"
              v-else
              @click="createRecipe"
            >
            </PrimeButton>
            <PrimeButton
              label="Cancel"
              class="form-cancel-button sm:ml-2"
              @click="onCancel"
            ></PrimeButton>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MenuAddPopup
    :show-popup="showMenuPopup"
    @displayNo="closeMenuPopup()"
    @displayYes="checkMenu"
  ></MenuAddPopup>
  <RawMaterialAddPopup
    :show-popup="showRawMaterialPopup"
    @displayNo="closeRawMaterialPopup()"
    @displayYes="checkRawMaterial"
  ></RawMaterialAddPopup>
</template>

<script>
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  minValue,
  numeric,
  helpers,
  maxLength,
  maxValue,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      category: [],
      units: [],
      subtitle: "Add Recipe / Item",
      itemList: [],
      addedItemList: [],
      isEditMode: false,
      loading: false,
      showMenuPopup: false,
      showRawMaterialPopup: false,
      dragIndex: null,
      pic: "",
      vmodel: {
        name: "",
        noofperson: 1,
        pic: "",
        submenuid: "",
        materials: [
          {
            rowmaterialid: "",
            rowmaterialname: "",
            qty: 1,
            qtytype: "",
          },
        ],
      },
      validated: false,
      submitted: false,
    };
  },
  async mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (this.$route.params.id) {
      this.subtitle = "Update Recipe / Item";
      this.isEditMode = true;
      await this.getRecipeById();
    }
    this.$root.$globalState.loader = false;
    await this.getItem();
    await this.getCategoryDrop();
    await this.getunitDrop();
  },
  validations() {
    if (this.$store.state.userInfo.add_images) {
      return {
        vmodel: {
          name: { required, max: maxLength(30) },
          noofperson: {
            required,
            numeric,
            minValue: minValue(1),
            maxValue: maxValue(99999),
          },
          pic: {
            required,
          },
          submenuid: { required },
          materials: {
            $each: helpers.forEach({
              rowmaterialid: { required },
              rowmaterialname: { required },
              qty: { required, minValue: minValue(0.01) },
              qtytype: { required },
            }),
          },
        },
      };
    } else {
      return {
        vmodel: {
          name: { required, max: maxLength(30) },
          noofperson: {
            required,
            numeric,
            minValue: minValue(1),
            maxValue: maxValue(99999),
          },
          pic: {},
          submenuid: { required },
          materials: {
            $each: helpers.forEach({
              rowmaterialid: { required },
              rowmaterialname: { required },
              qty: { required, minValue: minValue(0.01) },
            }),
          },
        },
      };
    }
  },
  methods: {
    dragStart(index) {
      this.dragIndex = index;
    },
    dragOver(index) {
      if (this.dragIndex !== null) {
        const draggedItem = this.vmodel.materials.splice(this.dragIndex, 1)[0];
        this.vmodel.materials.splice(index, 0, draggedItem);
        this.dragIndex = index;
      }
    },
    drop() {
      this.dragIndex = null;
    },
    closeMenuPopup() {
      this.showMenuPopup = false;
    },
    async checkMenu(data) {
      this.showMenuPopup = false;
      this.vmodel.submenuid = data;
      await this.getCategoryDrop();
    },
    closeRawMaterialPopup() {
      this.showRawMaterialPopup = false;
    },
    async checkRawMaterial(data) {
      this.showRawMaterialPopup = false;
      await this.getItem();
      await this.setRawMaterial(data);
    },
    async setRawMaterial(data) {
      if (this.vmodel.materials) {
        let index = this.vmodel.materials.length - 1;
        if (this.vmodel.materials[index].rowmaterialid == "") {
          this.vmodel.materials[index].rowmaterialid = data;
          await this.itemList
            .filter((x) => x.id == data)
            .map((data) => {
              this.vmodel.materials[index].rowmaterialname = data.name;
              this.vmodel.materials[index].qty = 1;
              this.vmodel.materials[index].qtytype = data.qtytype;
            });
        } else {
          const newItem = {
            rowmaterialid: "",
            rowmaterialname: "",
            qty: 1,
          };
          this.vmodel.materials = [...this.vmodel.materials, newItem];
          index = this.vmodel.materials.length - 1;
          this.vmodel.materials[index].rowmaterialid = data;
          await this.itemList
            .filter((x) => x.id == data)
            .map((data) => {
              this.vmodel.materials[index].rowmaterialname = data.name;
              this.vmodel.materials[index].qty = 1;
              this.vmodel.materials[index].qtytype = data.qtytype;
            });
        }
        await this.getItemListDetails(index);
      }
    },
    onlyDigit($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        (this.vmodel.noofperson >= 999999 &&
          keyCode > 47 &&
          keyCode < 58 &&
          keyCode !== 8 &&
          keyCode !== 32) ||
        (this.vmodel.noofperson >= 999999 &&
          keyCode !== 229 &&
          keyCode !== 8 &&
          keyCode !== 32)
      ) {
        $event.preventDefault();
      }
    },
    getCategoryDrop() {
      this.$root.$globalState.loader = true;
      APIService.get("/menu/dropdown/submenu")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.category = response.data.data;
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    getunitDrop() {
      this.$root.$globalState.loader = true;
      APIService.get("/unit/dropdown")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.units = response.data.data;
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    openFileUpload() {
      this.$refs.photo.choose();
    },
    onCancel() {
      this.$router.push({ name: "RecipeItem" });
    },
    getRowCategory(index) {
      return this.itemList
        .filter((x) => x.id === index)
        .map((data) => {
          return data.category;
        })[0];
    },
    getUnitOptions(index) {
      return this.itemList
        .filter((x) => x.id === index)
        .map((data) => {
          return data.qtytype;
        })[0];
    },
    getItemListDetails(index) {
      let check1 = this.addedItemList.indexOf(
        this.vmodel.materials[index].rowmaterialid
      );
      if (check1 !== -1 && index !== check1) {
        this.vmodel.materials[index].rowmaterialid = "";
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Raw Material / Ingrediants already used.",
          life: 3000,
        });
        return;
      }
      if (this.addedItemList.length > 0) {
        if (this.addedItemList[index]) {
          this.addedItemList[index] =
            this.vmodel.materials[index].rowmaterialid;
        } else {
          this.addedItemList.push(this.vmodel.materials[index].rowmaterialid);
        }
      } else {
        this.addedItemList.push(this.vmodel.materials[index].rowmaterialid);
      }
      this.itemList
        .filter((x) => x.id === this.vmodel.materials[index].rowmaterialid)
        .map((data) => {
          this.vmodel.materials[index].rowmaterialname = data.name;
          this.vmodel.materials[index].qty = 1;
          this.vmodel.materials[index].qtytype = data.qtytype;
        });
    },
    async getItem() {
      this.$root.$globalState.loader = true;
      await APIService.get("/rowmaterial/row")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.itemList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    increment(index) {
      if (typeof this.vmodel.materials[index].qty !== "number") {
        this.vmodel.materials[index].qty = 0;
      }
      this.vmodel.materials[index].qty =
        parseFloat(this.vmodel.materials[index].qty) + 1;
    },
    decrement(index) {
      if (typeof this.vmodel.materials[index].qty !== "number") {
        this.vmodel.materials[index].qty = 0;
      }
      this.vmodel.materials[index].qty =
        parseFloat(this.vmodel.materials[index].qty) - 1;
    },
    repeateAgain() {
      const newItem = {
        rowmaterialid: "",
        rowmaterialname: "",
        qty: 1,
      };
      this.vmodel.materials = [...this.vmodel.materials, newItem];
    },
    removeItem(index) {
      this.addedItemList.splice(index, 1);
      this.vmodel.materials.splice(index, 1);
    },

    async createRecipe() {
      this.validated = true;
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        this.$root.$globalState.loader = true;
        let payload = {
          name: this.vmodel.name,
          noofperson: this.vmodel.noofperson,
          submenuid: this.vmodel.submenuid,
          pic: this.vmodel.pic,
          materials: this.vmodel.materials,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          await APIService.put("/recipe", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "RecipeItem" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
              this.$root.$globalState.loader = false;
            });
        } else {
          await APIService.post("/recipe", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });

                setTimeout(() => {
                  this.$router.push({ name: "RecipeItem" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
              this.$root.$globalState.loader = false;
            });
        }
      }
    },

    getRecipeById() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      APIService.getById("/recipe", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.name = response.data.data.name;
            this.vmodel.noofperson = response.data.data.noofperson;
            this.vmodel.pic = response.data.data.pic;
            this.pic = response.data.data.picpath;
            this.vmodel.submenuid = response.data.data.submenuid;
            this.vmodel.materials = response.data.data.materials;
            this.addedItemList = response.data.data.materials.map((x) => {
              return x.rowmaterialid;
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "RecipeItem" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
          this.$root.$globalState.loader = false;
        });
    },

    handleFileUpload(fileObj) {
      var file = this.$refs.photo.files[0];
      // const file = event.target.files[0];
      if (
        !fileObj ||
        (fileObj && !fileObj.files) ||
        (fileObj && fileObj.files && fileObj.files.length === 0)
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select valid image",
          life: 3000,
        });
        this.$refs.photo.clear();
        // this.$refs.file.value = null;
      } else if (fileObj && fileObj.files && fileObj.files[0].size > 3145728) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Image size should be less then 3 MB",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.pic = reader.result;
          this.vmodel.pic = reader.result;
        };
        this.$refs.photo.clear();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px;
  gap: 8px;
  height: 36px;
  background: #ff9f43 !important;
  border-radius: 8px;
  border: none;
}
.add-button :hover {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px;
  gap: 8px;
  height: 36px;
  background: #ff9f43 !important;
  border-radius: 8px;
  border: none;
}
:deep(.pi) {
  margin-left: 10px;
}

.box {
  border-radius: 8px;
  border: 1px solid #ccc9d6;
  padding: 20px;
  height: auto;
}
.quantity-field {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 170px;
  border-radius: 4px;
}

.quantity-field .value-button {
  border: 1px solid #ddd;
  margin: 0px;
  width: 40px;
  height: 52px;
  padding: 13px;
  background: #ffffff;
  outline: none;
  cursor: pointer;
}

.quantity-field .value-button:hover {
  background: #ffffff;
}

.quantity-field .value-button:active {
  background: #ffffff;
}

.quantity-field .decrease-button {
  margin-right: -4px;
  border-width: 1px 1px 1px 1px;
  border-radius: 4px 0 0 4px;
  // border-right: 1px solid #e6e3e1 !important;
  width: 50px;
  height: 56px;
}

.quantity-field .increase-button {
  margin-left: -4px;
  border-width: 1px 1px 1px 1px;
  border-radius: 0 4px 4px 0;
  // border-left: 1px solid #e6e3e1 !important;
  // border-left: none;
  width: 50px;
  height: 56px;
}

::v-deep(.quantity-field .number) {
  .p-inputtext {
    display: flex;
    text-align: center;
    border: 1px solid #ddd;
    border-right: none;
    border-left: none;
    // margin: 0px;
    width: 70px;
    height: 56px;
    box-sizing: border-box;
    background: white;
    font-family: Inter;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    // padding-top: 15px;
    // padding-right: 5px;
    // padding-left: 1px;
    color: #141414;
    border-radius: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;
    margin-right: 4px;
    margin-left: 4px;
  }
}

.qty-style {
  width: 36px;
  margin-top: -21px;
  border-radius: unset;
  border-left: none;
  border-right: none;
  padding-left: 11px;
}

.quantity-field .number::selection {
  background: none;
}

.bin-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px !important;
  gap: 8px;
  height: 36px;
  width: 36px !important;
  margin-top: 1px;
  background: #feeded;
  opacity: 0.4;
  border: 1px solid rgba(241, 71, 71, 0.4);
  border-radius: 4px;
}

.bin-button:enabled:hover {
  background: #feeded;
  border: 1px solid rgba(241, 71, 71, 0.4);
}
.add-item-button:enabled:hover {
  background: #06253c;
  color: #fff;
  border-color: #06253c;
}
.add-item-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  height: 37px !important;
  width: 37px !important;
  background: #06253c;
  border-radius: 4px;
  border: #06253c;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: #fff;
}

:deep(.p-multiselect-label) {
  height: 48px !important;
}
</style>
